import React, { useEffect } from 'react'
import './UnprocessedPage.css'
import { InquiryList } from '@Component/board/List/InquiryList'
import { ReviewList } from '@Component/board/List/ReviewList'

export const AdminUnprocessedPage = () => {
    useEffect(() => {
        document.title = 'BROSPO 미답변'
    }, [])
    return (
        <div className={'unprocessed-page'}>
            <h2 className="page-title">미답변 게시글</h2>
            <h3>상품 리뷰</h3>
            <ReviewList productId={null} isAdmin={true} />
            <h3>상품 문의</h3>
            <InquiryList productId={null} isAdmin={true} />
        </div>
    )
}
