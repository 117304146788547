import { useEffect } from 'react'
import { convertToJpg, isJpeg } from '@Util/imageUtils'
import { postRequest } from '@Service/Api'
import { HttpAlert, HttpSuccess } from '@Interface/HttpCallBack'
import * as process from 'process'
import { getModalInstance } from '../Modal/ModalProvider'
import './Editor.css'

export const Editor: React.FC<{
    content: string
    height?: number
    userSet?: boolean
}> = ({ content, height = 600, userSet = false }) => {
    const modal = getModalInstance()

    async function uploadSummernoteImageFile(files: FileList) {
        const data = new FormData()
        for (let i = files.length - 1; i >= 0; i--) {
            const file = files[i]

            if (isJpeg(file)) {
                data.append('file', file)
            } else {
                data.append('file', await convertToJpg(file))
            }

            const httpSuccess = {}
            const httpAlert = {
                401: () => {
                    modal?.alert(
                        () => {},
                        '알림',
                        '게시글 작성은 로그인 후 가능합니다.'
                    )
                },
            }
            try {
                const src = await postRequest<string>(
                    '/uploadSummernoteImageFile',
                    httpSuccess,
                    httpAlert,
                    data,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                )
                const fileName = file.name

                const index = fileName.lastIndexOf('.')
                const fileNameWithoutExtension =
                    index !== -1 ? fileName.substring(0, index) : fileName


                // Create img element with jQuery
                const $img = $('<img>')
                    .attr('src', process.env.BASE_URL + src)
                    .attr('alt', fileNameWithoutExtension)
                    .css('width', '100%') // Set width to 100%

                // Append img to .note-editable element
                $('.note-editable').append($img)
            } catch (e) {
                console.log(e)
                break
            }
        }
    }

    async function deleteSummernoteImageFile(fileName: string) {
        const data = new FormData()
        data.append('file', fileName)
        try {
            const httpSuccess: HttpSuccess = {}
            const httpAlert: HttpAlert = {}
            await postRequest(
                '/deleteSummernoteImageFile',
                httpSuccess,
                httpAlert,
                data,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            )
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        // summernote옵션 설정
        const config: Summernote.Options = {
            styleWithSpan: false,
            height: height, // 에디터 높이
            lang: 'ko-KR',

            toolbar: userSet
                ? [
                      ['style', ['style']],
                      ['fontsize', ['fontsize']],
                      ['font', ['bold', 'underline', 'clear']],
                      ['color', ['color']],
                      ['height', ['height']],
                  ]
                : [
                      ['style', ['style']],
                      ['fontsize', ['fontsize']],
                      ['font', ['bold', 'underline', 'clear']],
                      ['color', ['color']],
                      ['height', ['height']],
                      ['table', ['table']],
                      ['para', ['paragraph']],
                      ['insert', ['picture', 'link', 'hr']],
                      ['view', ['codeview', 'fullscreen', 'help']],
                  ],

            fontSizes: [
                '8',
                '9',
                '10',
                '11',
                '12',
                '14',
                '16',
                '18',
                '20',
                '22',
                '24',
                '28',
                '30',
                '36',
                '50',
                '72',
            ],
            styleTags
                    : ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
            callbacks: {
                onInit: () => {
                    $('.note-editable').css('font-size', 'var(--font-size-1)') // 기본 글씨 크기 설정
                    $('.note-editable').css('line-height', '1.8') // 행간 설정
                    $('.note-toolbar .note-btn').css(
                        'font-size',
                        'var(--font-size-0-9)'
                    )
                },
                onChange: (contents: string, $editable: JQuery) => {},

                onImageUpload: (files: FileList) => {
                    // 일반유저는 edit에 이미지 업로드 불가
                    if (userSet) {
                        return false
                    }
                    // 파일 업로드(다중 업로드를 위해 반복문 사용)
                    uploadSummernoteImageFile(files)
                },
                onMediaDelete: (target: any) => {
                    const imageUrl: string = target[0].src
                    const parts = imageUrl.split('/')
                    deleteSummernoteImageFile(parts[parts.length - 1])
                },
                onKeyup: (ev: KeyboardEvent) => {},
                onKeydown: (ev: KeyboardEvent) => {},
                onPaste: (e: Event & { originalEvent: ClipboardEvent }) => {},
            },
        }

        $('#summernote').summernote(config)
        if (content) {
            $('#summernote').summernote(
                'code',
                content
                    ? content.replaceAll(
                          '/temp/',
                          process.env.TEMP_IMAGE_BASE_URL!
                      )
                    : ''
            )
        }
    }, [content])

    return (
        <>
            <div style={{ width: '100%' }}>
                <textarea id="summernote"></textarea>
            </div>
        </>
    )
}
