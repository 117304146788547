import { UserCheck, UserInfo, UserJoin, UserLogin } from '@Interface/User'

import { HttpAlert, HttpSuccess } from '@Interface/HttpCallBack'
import {
    CheckDuplicateService,
    UserInfoService,
    UserJoinService,
    UserLoginService,
    UserLogoutService,
} from '@Service/user/account/UserService'
import { getModalInstance } from '@Component/common/Widgets/Modal/ModalProvider'
import { createAuthTokenService } from '@Service/user/cmm/CmmService'
import { formatTime } from './StringUtils'

export const checkId = async (): Promise<boolean> => {
    const userCheck: UserCheck = {
        loginId: (document.getElementById('id-form') as HTMLInputElement).value,
        email: null,
        userId: null,
    }

    const showResultElement = document.getElementById('id-message')
    if (showResultElement) {
        if (userCheck.loginId == '') {
            return false
        }
        if (userCheck.loginId != null && userCheck.loginId.includes(' ')) {
            showResultElement.innerHTML = '공백이 포함되어 있습니다.'
            showResultElement.style.color = 'Red'
            return false
        }

        const inputUserId = (
            document.getElementById('id-form') as HTMLInputElement
        ).value

        const regex = /^[A-Za-z0-9]+$/

        if (!regex.test(inputUserId)) {
            showResultElement.innerHTML = '영문, 숫자로만 구성되야 합니다.'
            showResultElement.style.color = 'Red'
            return false
        }

        if (inputUserId.length > 15 || inputUserId.length < 6) {
            showResultElement.innerHTML = '6자 ~ 15자 이내로 구성되어야 합니다.'
            showResultElement.style.color = 'Red'
            return false
        }

        const httpSuccess: HttpSuccess = {
            200: () => {
                showResultElement.innerHTML = '사용 가능한 아이디입니다.'
                showResultElement.style.color = 'Blue'
            },
        }
        const httpAlert: HttpAlert = {
            409: () => {
                showResultElement.innerHTML = '중복된 아이디입니다.'
                showResultElement.style.color = 'Red'
            },
            500: () => {
                showResultElement.innerHTML =
                    '서버 오류, 관리자에게 문의하세요.'
                showResultElement.style.color = 'Red'
            },
        }
        return await CheckDuplicateService(
            'ID',
            userCheck,
            httpSuccess,
            httpAlert
        )
    }
    return false
}

export const checkPwd = (): boolean => {
    const showResultElement = document.getElementById('pwd-message')
    if (showResultElement != null) {
        const inputPwd = (
            document.getElementById('pwd-form') as HTMLInputElement
        ).value
        const inputPwdReEnter = (
            document.getElementById('pwd-re-enter-form') as HTMLInputElement
        ).value

        if (inputPwd.length < 6) {
            showResultElement.innerHTML = '6자 이상이어야 합니다.'
            showResultElement.style.color = 'Red'
            return false
        }

        if (inputPwd.includes(' ')) {
            showResultElement.innerHTML = '공백이 포함되어 있습니다.'
            showResultElement.style.color = 'Red'

            return false
        }

        const regex = /^(?=.*[!@#$%^&*()-+=])([a-zA-Z0-9!@#$%^&*()-+=]+)$/
        if (!regex.test(inputPwd)) {
            showResultElement.innerHTML = '영문, 숫자, 특수문자(필수)'
            showResultElement.style.color = 'Red'

            return false
        }

        if (inputPwd != inputPwdReEnter) {
            showResultElement.innerHTML = '재입력 값이 틀립니다.'
            showResultElement.style.color = 'Red'
            return false
        }

        showResultElement.innerHTML = '사용가능한 비밀번호입니다.'
        showResultElement.style.color = 'Blue'
        return true
    }
    return false
}

export const checkEmail = async (): Promise<boolean> => {
    const showResultElement = document.getElementById('email-message')
    if (showResultElement != null) {
        const userCheck: UserCheck = {
            loginId: null,
            email: (document.getElementById('email-form') as HTMLInputElement)
                .value,
            userId: null,
        }

        const inputEmail = (
            document.getElementById('email-form') as HTMLInputElement
        ).value

        if (inputEmail.includes(' ')) {
            showResultElement.innerHTML = '공백이 포함되어 있습니다.'
            showResultElement.style.color = 'Red'
            return false
        }

        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        if (!regex.test(inputEmail)) {
            showResultElement.innerHTML = '이메일 형식이 알맞지 않습니다.'
            showResultElement.style.color = 'Red'
            return false
        }

        const httpSuccess: HttpSuccess = {
            200: () => {
                showResultElement.innerHTML = '사용 가능한 이메일입니다.'
                showResultElement.style.color = 'Blue'
            },
        }
        const httpAlert: HttpAlert = {
            409: () => {
                showResultElement.innerHTML = '중복된 이메일입니다.'
                showResultElement.style.color = 'Red'
            },
            500: () => {
                showResultElement.innerHTML =
                    '서버 오류, 관리자에게 문의하세요.'
                showResultElement.style.color = 'Red'
            },
        }

        return await CheckDuplicateService(
            'EMAIL',
            userCheck,
            httpSuccess,
            httpAlert
        )
    }
    return false
}

export const checkPN = (): boolean => {
    const showResultElement = document.getElementById('phone-number-message')

    if (showResultElement != null) {
        const inputPhoneNumber = (
            document.getElementById('phone-number-form') as HTMLInputElement
        ).value

        const elementPhoneNumber = document.getElementById(
            'phone-number-form'
        ) as HTMLInputElement

        elementPhoneNumber.value = formatPhoneNumber(inputPhoneNumber)

        if (inputPhoneNumber.includes(' ')) {
            showResultElement.innerHTML = '공백이 포함되어 있습니다.'
            showResultElement.style.color = 'Red'
            return false
        }

        if (inputPhoneNumber == '') {
            showResultElement.innerHTML = ''
            return false
        }

        const regex = /^\d{3}-\d{3,4}-\d{4}$/
        if (!regex.test(elementPhoneNumber.value)) {
            showResultElement.innerHTML = '전화번호 형식이 알맞지 않습니다.'
            showResultElement.style.color = 'Red'
            return false
        }
        showResultElement.innerHTML = '올바른 형식입니다.'
        showResultElement.style.color = 'Blue'
        return true
    }

    return false
}

export const formatPhoneNumber = (input: string) => {
    // 입력된 텍스트에서 숫자만 추출
    const numbersOnly = input.replace(/\D/g, '')

    // 숫자가 없는 경우
    if (!numbersOnly) {
        return input // 입력 내용을 그대로 반환
    }

    // 숫자의 길이에 따라 다양한 형식을 적용
    let formattedNumber = numbersOnly

    // 11자리 (휴대전화 번호)
    if (numbersOnly.length === 11) {
        formattedNumber = numbersOnly.replace(
            /(\d{3})(\d{4})(\d{4})/,
            '$1-$2-$3'
        )
    }
    // 10자리 (일반 전화번호)
    else if (numbersOnly.length === 10) {
        formattedNumber = numbersOnly.replace(
            /(\d{2,3})(\d{3,4})(\d{4})/,
            '$1-$2-$3'
        )
    }
    // 7~9자리 (짧은 전화번호)
    else if (numbersOnly.length >= 7 && numbersOnly.length <= 9) {
        formattedNumber = numbersOnly.replace(/(\d{3})(\d{3,4})/, '$1-$2')
    }

    return formattedNumber
}

export const userJoin = async () => {
    const modal = getModalInstance()
    try {
        if (!(await checkUserJoinForm())) {
            return
        }

        const userForm: UserJoin = {
            loginId:
                (document.getElementById('id-form') as HTMLInputElement)
                    .value || '',
            password:
                (document.getElementById('pwd-form') as HTMLInputElement)
                    .value || '',
            email:
                (document.getElementById('email-form') as HTMLInputElement)
                    .value || '',
            phoneNumber:
                (
                    document.getElementById(
                        'phone-number-form'
                    ) as HTMLInputElement
                ).value || '',
            userName:
                (document.getElementById('user-name-form') as HTMLInputElement)
                    .value || '',
            agreement: (
                document.getElementById('agreement-yn') as HTMLInputElement
            ).checked
                ? 'Y'
                : 'N',

            privacy: (document.getElementById('privacy-yn') as HTMLInputElement)
                .checked
                ? 'Y'
                : 'N',
        }

        await UserJoinService(userForm)
    } catch (error) {
        console.error('가입 중 오류가 발생했습니다:', error)
    }
}

export const userLogin = async () => {
    const userLogin: UserLogin = {
        loginId: (
            document.getElementById('user-id') as HTMLInputElement
        ).value.trim(),
        password: (
            document.getElementById('user-pwd') as HTMLInputElement
        ).value.trim(),
    }

    await UserLoginService(userLogin)
}

export const userLogout = () => {
    UserLogoutService()
}

export const kakaoLogin = () => {
    const modal = getModalInstance()

    modal!.alert(() => {}, '알림', '준비중 입니다.')
}

export const checkUserJoinForm = async () => {
    const modal = getModalInstance()

    if (!(await checkId())) {
        modal!.alert(
            () => {
                ;(
                    document.getElementById('id-form') as HTMLInputElement
                ).focus()
            },
            '알림',
            'ID 값을 확인하세요.'
        )

        return false
    } else if (!(await checkPwd())) {
        modal!.alert(
            () => {
                ;(
                    document.getElementById('pwd-form') as HTMLInputElement
                ).focus()
            },
            '알림',
            'PWD 값을 확인하세요.'
        )

        return false
    } else if (!(await checkEmail())) {
        modal!.alert(
            () => {
                ;(
                    document.getElementById('email-form') as HTMLInputElement
                ).focus()
            },
            '알림',
            'EMAIL 값을 확인하세요.'
        )
        return false
    } else if (!(await checkPN())) {
        modal!.alert(
            () => {
                ;(
                    document.getElementById(
                        'phone-number-form'
                    ) as HTMLInputElement
                ).focus()
            },
            '알림',
            '전화번호 값을 확인하세요.'
        )

        return false
    }

    const agreement = (
        document.getElementById('agreement-yn') as HTMLInputElement
    ).checked
        ? 'Y'
        : 'N'

    if (agreement != 'Y') {
        modal!.alert(
            () => {
                ;(
                    document.getElementById('agreement-yn') as HTMLInputElement
                ).focus()
            },
            '알림',
            '이용약관에 동의해주세요.'
        )

        return false
    }

    const privacy = (document.getElementById('privacy-yn') as HTMLInputElement)
        .checked
        ? 'Y'
        : 'N'
    if (privacy != 'Y') {
        modal!.alert(
            () => {
                ;(
                    document.getElementById('privacy-yn') as HTMLInputElement
                ).focus()
            },
            '알림',
            '개인정보처리방침에 동의해주세요.'
        )

        return false
    }

    return true
}
export const createAuthToken = async () => {
    const modal = getModalInstance()

    // if (!(await checkUserJoinForm())) {
    //     return
    // }

    const formData = new FormData()

    // userLoginId 값을 FormData에 추가
    const userLoginId = (
        document.getElementById('id-form') as HTMLInputElement
    )?.value.trim()
    formData.append('userLoginId', userLoginId)

    // phoneNumber 값을 FormData에 추가
    const phoneNumber = (
        document.getElementById('phone-number-form') as HTMLInputElement
    )?.value.trim()
    formData.append('phoneNumber', phoneNumber)

    // ;(
    //     document.getElementById('auth-token-button') as HTMLElement
    // ).style.display = 'none'

    console.log(formData)
    if ((await createAuthTokenService(formData)) == '') {
        const showResultElement = document.getElementById('auth-token-message')
        if (showResultElement) {
            let countDown = 300
            showResultElement.style.color = 'blue'
            const timer = setInterval(() => {
                showResultElement.innerText = formatTime(countDown--)
                if (countDown == -1) {
                    clearInterval(timer)
                    showResultElement.innerText = ''
                    modal?.alert(
                        () => {
                            ;(
                                document.getElementById(
                                    'auth-token-button'
                                ) as HTMLElement
                            ).style.display = 'block'
                        },
                        '알림',
                        '인증시간이 초과되었습니다.',
                        '번호를 다시 발급해주세요'
                    )
                }
            }, 1000)
        }
    }
}

export const checkUserInfo = async (
    setUserInfo?: React.Dispatch<React.SetStateAction<UserInfo | undefined>>,
    alert?: boolean
) => {
    const userInfo = await UserInfoService()

    if (userInfo.userName == '') {
        const modal = getModalInstance()
        if (alert) {
            modal?.alert(
                () => {
                    window.location.href = '/user/login'
                },
                '알림',
                '잘못된 접근입니다.',
                '이용을 위해 로그인이 필요합니다.'
            )
        }
        return false
    }

    if (setUserInfo) {
        setUserInfo(userInfo)
    } else {
        return true
    }
}
