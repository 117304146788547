import axios from 'axios'
import {
    Product,
    ProductSearchDTO,
    ProductListResponse,
    ProductDetail,
} from '@Interface/Product'
import { getRequest } from '@Service/Api'
import { HttpAlert, HttpSuccess } from '@Interface/HttpCallBack'
import { useState } from 'react'
import { getModalInstance } from '@Component/common/Widgets/Modal/ModalProvider'

export const ProductListService = async (searchQuery: ProductSearchDTO) => {
    const modal = getModalInstance()

    try {
        const httpAlert: HttpAlert = {}
        const httpSuccess: HttpSuccess = {
            204: () => {
                modal!.alert(() => {}, '알림', '조회된 상품이 없습니다.')
            },
        }

        const config = {
            params: searchQuery,
        }
        return await getRequest<ProductListResponse>(
            '/product/list',
            httpSuccess,
            httpAlert,
            config
        )
    } catch (e) {
        console.log(e)
    }
}

export const ProductService = async (productId: string) => {
    const modal = getModalInstance()

    try {
        const httpAlert: HttpAlert = {}
        const httpSuccess: HttpSuccess = {}

        return await getRequest<ProductDetail>(
            '/product/' + productId,
            httpSuccess,
            httpAlert,
            {}
        )
    } catch (e) {
        console.log(e)
    }
}
