import { getModalInstance } from '@Component/common/Widgets/Modal/ModalProvider'
import { CartDTO } from '@Interface/Cart'
import { insertCartService } from '@Service/user/cart/CartService'

export const insertCart = (productId: string) => {
    const insertCartArray: CartDTO[] = []

    const elements = document.querySelectorAll(
        `#size-count-place-${productId} [id^="size-count"]`
    )

    elements.forEach((element) => {
        const size = element.id.replace('size-count-', '')
        const count = (element as HTMLInputElement).value
        insertCartArray.push({
            productId: productId!,
            size: size,
            count: count,
        })
    })

    if (insertCartArray.length == 0) {
        const modal = getModalInstance()
        modal?.alert(() => {}, '알림', '사이즈를 선택해주세요')
    } else {
        insertCartService(insertCartArray)
    }
}
