import './BoardEditor.css'

import ImageDrop from '@Component/common/Widgets/Image/ImageDrop'
import { Editor } from '@Component/common/Widgets/Editor/Editor'
import { useEffect, useState } from 'react'
import { insertBoard, updateBoard } from '@Util/BoardUtils'
import { getModalInstance } from '@Component/common/Widgets/Modal/ModalProvider'
import { Board } from '@Interface/Board'
import { stripHTMLTags } from '@Util/StringUtils'
import { getFileMap } from '@Util/imageUtils'

export const ReviewEditor = (props: {
    productId: string
    board?: Board
    imageUrl?: string[]
}) => {
    const [fileMap, setFileMap] = useState<Map<number, File>>(new Map())

    const [tempFileMap, setTempFileMap] = useState<Map<number, File>>(new Map())
    const [reviewRating, setReviewRating] = useState(0)
    const board = props.board

    const handleRatingClick = (index: number) => {
        setReviewRating(index + 1)
        for (let i = 0; i < 5; i++) {
            const ratingEl = document.getElementById('review-rating-' + i)!

            if (i <= index) {
                ratingEl.classList.remove('icon-star-empty')
                ratingEl.classList.add('icon-star')
            } else {
                ratingEl.classList.remove('icon-start')
                ratingEl.classList.add('icon-star-empty')
            }
        }
    }

    const handleRatingHover = (index: number) => {
        for (let i = 0; i < 5; i++) {
            const ratingEl = document.getElementById('review-rating-' + i)!

            if (i <= index) {
                ratingEl.classList.remove('icon-star-empty')
                ratingEl.classList.add('icon-star')
            } else {
                ratingEl.classList.remove('icon-star')
                ratingEl.classList.add('icon-star-empty')
            }
        }
    }

    const handleRatingOut = (index: number) => {
        for (let i = 0; i < 5; i++) {
            const ratingEl = document.getElementById('review-rating-' + i)!

            if (i <= index) {
                ratingEl.classList.remove('icon-star-empty')
                ratingEl.classList.add('icon-star')
            } else {
                ratingEl.classList.remove('icon-star')
                ratingEl.classList.add('icon-star-empty')
            }
        }
    }
    const setUpdateForm = async () => {
        setReviewRating(board?.reviewRating || 0)

        for (let i = 0; i < 5; i++) {
            const ratingEl = document.getElementById('review-rating-' + i)!
            if (i < (board?.reviewRating || 0)) {
                ratingEl.classList.remove('icon-star-empty')
                ratingEl.classList.add('icon-star')
            } else {
                ratingEl.classList.remove('icon-start')
                ratingEl.classList.add('icon-star-empty')
            }
        }

        if (props.imageUrl) {
            setTempFileMap(await getFileMap(props.imageUrl))
        }
    }

    useEffect(() => {
        setUpdateForm()
    }, [])

    return (
        <form className={'board-editor'} id="board-form">
            <h2>
                리뷰 작성
                <div>
                    {board ? (
                        <input
                            type={'button'}
                            value={'수정'}
                            onClick={() => updateBoard(fileMap)}
                        />
                    ) : (
                        <input
                            type={'button'}
                            value={'저장'}
                            onClick={() => insertBoard(fileMap)}
                        />
                    )}
                </div>
            </h2>

            <label>
                한줄평
                <div className={'review-rating'}>
                    <label>리뷰 평점</label>
                    {[...Array(5)].map((_, index) => (
                        <i
                            key={index}
                            id={'review-rating-' + index}
                            className={'icon-star-empty'}
                            onClick={() => handleRatingClick(index)}
                            onMouseOver={() => handleRatingHover(index)}
                            onMouseOut={() => handleRatingOut(reviewRating - 1)}
                        ></i>
                    ))}
                    <input
                        type="hidden"
                        id="review-rating"
                        value={reviewRating || 0}
                    />
                    <input type="hidden" id="board-category" value={1} />
                    <input
                        type="hidden"
                        id="product-id"
                        value={props.productId}
                    />
                    <input
                        type="hidden"
                        id="board-id"
                        value={board?.boardId || ''}
                    />
                </div>
            </label>
            <input
                type="text"
                placeholder="REVIEW TITLE"
                id="board-title"
                defaultValue={board?.title || ''}
            />
            <ImageDrop
                tempFileMap={tempFileMap}
                fileMap={fileMap}
                setFileMap={setFileMap}
            />

            <Editor
                content={stripHTMLTags(board?.content || '')}
                userSet={true}
                height={300}
            />
        </form>
    )
}
