import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import './ProductSortSelector.css'

export const ProductSortSelector = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const [sortMode, setSortMode] = useState('1')

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search)
        const sortModeParam = searchParams.get('sortMode')
        if (sortModeParam) {
            setSortMode(sortModeParam)
        } else {
            // 다른 카테고리로 이동할 때는 정렬 모드를 1로 초기화
            setSortMode('1')
        }
    }, [location])

    const handleSortChange = (event: { target: { value: any } }) => {
        const selectedValue = event.target.value
        setSortMode(selectedValue)
        const searchParams = new URLSearchParams(location.search)
        searchParams.set('sortMode', selectedValue)
        const newUrl = `${location.pathname}?${searchParams.toString()}`
        navigate(newUrl)
    }

    return (
        <select id="sort-mode" value={sortMode} onChange={handleSortChange}>
            <option value="1">-정렬순서-</option>
            <option value="2">상품명</option>
            <option value="3">낮은가격</option>
            <option value="4">높은가격</option>
            {/* <option value="5" selected={sortMode === "5"}>상품평순</option> */}
        </select>
    )
}
