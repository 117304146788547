import { getModalInstance } from '@Component/common/Widgets/Modal/ModalProvider'
import { HttpAlert, HttpSuccess } from '@Interface/HttpCallBack'
import { getRequest, postRequest, putRequest } from '@Service/Api'
import {
    Order,
    OrderCountDTO,
    OrderDTO, OrderExcelDTO,
    OrderItem,
    OrderListDTO,
    OrderSearchDTO,
    responsePaymentCustom,
    UpdateOrderDTO,
} from '@Interface/Order'
import { PaymentResponse } from '@portone/browser-sdk/dist/v2/requestPayment'
import { Address } from '@portone/browser-sdk/dist/v2/entity'

export const insertOrderService = async (orderList: OrderItem[]) => {
    const modal = getModalInstance()

    try {
        const httpSuccess: HttpSuccess = {
            200: () => {
                window.location.href = '/order/checkout'
            },
        }
        const httpAlert: HttpAlert = {}

        return await postRequest(
            '/order',
            httpSuccess,
            httpAlert,
            orderList,
            {}
        )
    } catch (e) {
        console.log(e)
    }
}

export const getOrderService = async (orderCode?: string) => {
    const modal = getModalInstance()
    try {
        const httpSuccess: HttpSuccess = {}
        const httpAlert: HttpAlert = {}

        return await getRequest<OrderDTO>(
            `/order/${orderCode || ''}`,
            httpSuccess,
            httpAlert,
            {}
        )
    } catch (e) {
        console.log(e)
    }
}

export const getOrderCountService = async () => {
    const modal = getModalInstance()
    try {
        const httpSuccess: HttpSuccess = {}
        const httpAlert: HttpAlert = {}

        return await getRequest<OrderCountDTO>(
            `/order/count`,
            httpSuccess,
            httpAlert,
            {}
        )
    } catch (e) {
        console.log(e)
    }
}

export const getOrderListService = async (searchQuery: OrderSearchDTO) => {
    const modal = getModalInstance()
    try {
        const httpSuccess: HttpSuccess = {}
        const httpAlert: HttpAlert = {}
        const config = {
            params: searchQuery,
        }

        return await getRequest<OrderListDTO>(
            searchQuery.admin ? '/admin/order/list' : '/order/list',
            httpSuccess,
            httpAlert,
            config
        )
    } catch (e) {
        console.log(e)
    }
}

export const getOrderExcelListService = async (searchQuery: OrderSearchDTO) => {
    const modal = getModalInstance()
    try {
        const httpSuccess: HttpSuccess = {}
        const httpAlert: HttpAlert = {}
        const config = {
            params: searchQuery,
        }

        return await getRequest<OrderExcelDTO>(
            '/admin/order/excel',
            httpSuccess,
            httpAlert,
            config
        )
    } catch (e) {
        console.log(e)
    }
}


export const orderWebHookService = async (
    data: PaymentResponse | responsePaymentCustom
) => {
    const modal = getModalInstance()

    try {
        const httpSuccess: HttpSuccess = {
            200: (data) => {
                window.location.href = `/order/complete?orderCode=${data}`
            },
        }
        const httpAlert: HttpAlert = {
            400: () => {
                modal?.alert(
                    () => {
                        window.location.href = '/'
                    },
                    '경고',
                    '잘못된 요청',
                    '결제금액이 다릅니다.'
                )
            },
        }

        return await postRequest(
            '/order/web-hook',
            httpSuccess,
            httpAlert,
            data,
            {}
        )
    } catch (e) {
        console.log(e)
    }
}

export const updateOrderService = async (order: UpdateOrderDTO) => {
    const modal = getModalInstance()

    try {
        const httpSuccess: HttpSuccess = {}
        const httpAlert: HttpAlert = {}
        await putRequest('/order', httpSuccess, httpAlert, order, {})

        return true
    } catch (e) {
        console.log(e)
        return false
    }
}
