import { useEffect, useRef } from 'react'
import { defaultSizeList } from './SizeSelector'
import { getModalInstance } from '@Component/common/Widgets/Modal/ModalProvider'
import {OrderItem} from "@Interface/Order";
import {insertOrderService} from "@Service/user/order/OrderService";

export const SizeCounter = (props: { productId?: string | undefined }) => {
    const predefinedOrder = defaultSizeList
    const totalPrice = useRef<number>(0)

    const changeItemCount = (event: Event) => {
        const price = parseInt(document.getElementById('price')!.getAttribute('data-price')!);
        const inputEl = event.target as HTMLInputElement;
        const size = inputEl.id.replace('size-count-', '');

        // 문자열을 제거하고 숫자만 남김
        const filteredValue = inputEl.value.replace(/[^0-9]/g, '');

        // 만약 문자열을 제거한 결과가 숫자가 아니면 1로 설정
        const count = filteredValue === '' ? 1 : parseInt(filteredValue);

        // 입력 필드에 숫자만 남기도록 설정
        inputEl.value = count.toString();

        const tempPrice = price * count;
        document.getElementById('item-price-' + size)!.setAttribute('data-price', tempPrice.toString());
        document.getElementById('item-price-' + size)!.innerText = `${tempPrice.toLocaleString()} 원`;

        updateTotalPrice();
    }


    const addItemCount = (size: string) => {
        const price = parseInt(
            document.getElementById('price')!.getAttribute('data-price')!
        )
        const inputEl = document.getElementById(
            'size-count-' + size
        ) as HTMLInputElement
        const count = parseInt(inputEl!.value)
        inputEl.value = (count + 1).toString()
        const tempPrice = price * (count + 1)

        document
            .getElementById('item-price-' + size)!
            .setAttribute('data-price', tempPrice.toString())
        document.getElementById(
            'item-price-' + size
        )!.innerText = `${tempPrice.toLocaleString()} 원`
        updateTotalPrice()
    }

    const subItemCount = (size: string) => {
        const price = parseInt(
            document.getElementById('price')!.getAttribute('data-price')!
        )
        const inputEl = document.getElementById(
            'size-count-' + size
        ) as HTMLInputElement
        const count = parseInt(inputEl!.value)
        if (count > 1) {
            inputEl.value = (count - 1).toString()
            const tempPrice = price * (count - 1)
            document
                .getElementById('item-price-' + size)!
                .setAttribute('data-price', tempPrice.toString())
            document.getElementById(
                'item-price-' + size
            )!.innerText = `${tempPrice.toLocaleString()} 원`
            updateTotalPrice()
        } else {
            const modal = getModalInstance()
            modal?.confirm(
                () => {
                    subSize(`size-item-${size}`)
                },
                () => {},
                '알림',
                '해당 사이즈를 삭제하시겠습니까?'
            )
        }
    }

    const addSize = (event: Event) => {
        const price = parseInt(
            document.getElementById('price')!.getAttribute('data-price')!
        )
        const target = event.target as HTMLElement
        const size = target.id
        if (document.getElementById('size-item-' + size)) {
            document.getElementById('size-item-' + size)!.remove()
            updateTotalPrice()
            return
        }

        const newSizeItem = document.createElement('div')
        newSizeItem.className = 'size-item'
        newSizeItem.id = 'size-item-' + size
        newSizeItem.innerHTML = `
            <div class="size-name">${size.toUpperCase()}</div>
            <div class="size-count">
                <div>
                    <button id="sub-item-count-${size}">
                        <i class="icon-minus"></i>
                    </button>
                    <input id="size-count-${size}" type="number" value="1" />
                    <button id="add-item-count-${size}">
                        <i class="icon-plus"></i>
                    </button>
                </div>
                <span id="item-price-${size}" data-price="${price}">${price.toLocaleString()}원</span>
                <button id="sub-item-${size}">
                    <i class="icon-trash"></i>
                </button>
            </div>
        `

        const sizeCountPlace = document
            .getElementsByClassName('size-count-place')
            .item(0)
        const targetIndex = sizeCountPlace!.children.length - 1
        sizeCountPlace!.insertBefore(
            newSizeItem,
            sizeCountPlace!.children[targetIndex]
        )

        document
            .getElementById('sub-item-' + size)!
            .addEventListener('click', () => subSize(newSizeItem.id))
        document
            .getElementById('sub-item-count-' + size)!
            .addEventListener('click', () => subItemCount(size))
        document
            .getElementById('add-item-count-' + size)!
            .addEventListener('click', () => addItemCount(size))
        document
            .getElementById('size-count-' + size)!
            .addEventListener('change', changeItemCount)
        updateTotalPrice()
    }

    const subSize = (sizeItemId: string) => {
        document.getElementById(sizeItemId)!.remove()

        const element = document.getElementById(
            sizeItemId.replace('size-item-', '')
        )

        element!.style.backgroundColor = 'white'
        element!.style.color = 'var(--color)'
        updateTotalPrice()
    }

    const updateTotalPrice = () => {
        totalPrice.current = 0
        const elements = document.querySelectorAll('[id^="item-price-"]')
        elements.forEach((element) => {
            const price = parseInt(element.getAttribute('data-price')!)
            totalPrice.current = totalPrice.current! + price
        })

        if (totalPrice.current !== 0) {
            document.getElementById(
                'total-price'
            )!.innerText = `총 ${totalPrice.current?.toLocaleString()}원`
        } else {
            document.getElementById('total-price')!.innerText = ''
        }
    }

    useEffect(() => {
        for (const size of predefinedOrder) {
            const sizeSelector = document.getElementById(size)
            if (sizeSelector) {
                sizeSelector.addEventListener('click', addSize)
            }
        }
        updateTotalPrice()
    }, [])

    return (
        <div
            className={'size-count-place'}
            id={props.productId ? 'size-count-place-' + props.productId : ''}
        >
            <div id={'total-price'}></div>
        </div>
    )
}


