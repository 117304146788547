import { Category } from '@Interface/Category'
import { useEffect, useState } from 'react'
import {
    checkProductForm,
    getAdminProduct,
    getProductFormValue,
    insertProduct,
} from '@Util/ProductUtils'
import { Product, UpdateProduct } from '@Interface/Product'
import { getImageList } from '@Util/imageUtils'
import { UpdateProductService } from '@Service/admin/product/AdminProductService'
import { ProductInfoForm } from '@Component/product/ProductInfo/ProductInfoForm'
import ImageDrop from '@Component/common/Widgets/Image/ImageDrop'
import { Editor } from '@Component/common/Widgets/Editor/Editor'
import { useParams } from 'react-router-dom'
import './AdminProductPage.css'
import { getModalInstance } from '@Component/common/Widgets/Modal/ModalProvider'

export const AdminProductPage = (props: {
    categoryList: { [p: string]: Category[] }
}) => {
    const [fileMap, setFileMap] = useState<Map<number, File>>(new Map())

    const [tempFileMap, setTempFileMap] = useState<Map<number, File>>(new Map())

    const categoryList = props.categoryList

    const { productId } = useParams()

    const [product, setProduct] = useState<Product | null>()

    const modal = getModalInstance()

    const freeView = async () => {
        const checkForm = await checkProductForm(categoryList, productId || '')

        if ((await checkForm) == 0) {
            return
        }
        if (fileMap.size == 0) {
            modal?.alert(() => {}, '확인', '메인 이미지를 등록해주세요.')
            return
        }
        const tempProduct: UpdateProduct = getProductFormValue(checkForm)

        tempProduct.productId = '1'
        tempProduct.productCode = 'SAMPLE'

        const formData = getImageList(fileMap)

        formData.append('updateProduct', JSON.stringify(tempProduct))

        await UpdateProductService(formData)

        window.open(
            '/admin/product/freeView/1',
            '_blank',
            'width=1000,height=800'
        )
    }
    const updateProduct = async () => {
        const checkForm = await checkProductForm(categoryList, productId || '')

        if ((await checkForm) == 0) {
            return
        }
        if (fileMap.size == 0) {
            modal?.alert(() => {}, '확인', '메인 이미지를 등록해주세요.')
            return
        }
        const tempProduct: UpdateProduct = getProductFormValue(checkForm)

        if (product?.productId) {
            tempProduct.productId = product.productId
        }

        const formData = getImageList(fileMap)

        formData.append('updateProduct', JSON.stringify(tempProduct))

        await UpdateProductService(formData)
    }
    useEffect(() => {
        if (!productId && localStorage.getItem('size') != '') {
            localStorage.setItem('size', '')
            window.location.reload()
        }
    }, [productId])
    useEffect(() => {
        if (productId) {
            getAdminProduct(productId, setProduct, setFileMap, setTempFileMap)
        }
    }, [])
    return (
        <div className={'insert-product-form'}>
            <h2 className={'page-title'}>상품 {productId ? '수정' : '등록'}</h2>
            <ProductInfoForm product={product!} categoryList={categoryList} />

            <div className="product-main-image">
                <h2>메인 이미지</h2>
                <ImageDrop
                    tempFileMap={tempFileMap}
                    fileMap={fileMap}
                    setFileMap={setFileMap}
                />
            </div>

            <div className="product-description">
                <h2>상품 설명</h2>
                <Editor content={product?.productDescription!} />
            </div>

            <div className="product-action">
                <input type="button" value={'미리보기'} onClick={freeView} />
                {productId ? (
                    <input
                        type="button"
                        value={'수정하기'}
                        onClick={updateProduct}
                    />
                ) : (
                    <input
                        type="button"
                        value={'등록하기'}
                        onClick={() => insertProduct(categoryList, fileMap)}
                    />
                )}
            </div>
        </div>
    )
}
