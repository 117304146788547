import ReactDOM from 'react-dom/client'
import './cmm.css'
import App from './App'
import '@Css/fontello.css'
import { ModalProvider } from '@Component/common/Widgets/Modal/ModalProvider'
import ReactModal from 'react-modal'
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
// Set the app element for react-modal
ReactModal.setAppElement('#root')

root.render(
    // <React.StrictMode>

    <App />

    // </React.StrictMode>
)
