import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import './PageBar.css'
import { Board } from '@Interface/Board'

export const ProductPageBar = (props: {
    productPage: number
    totalPage: number
}): JSX.Element => {
    const productPage = props.productPage
    const totalPage = props.totalPage
    const totalPages = totalPage < 1 ? 1 : totalPage
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    searchParams.delete('page')
    const endpoint = `${location.pathname}?${searchParams.toString()}`
    const startPage = Math.floor((productPage - 1) / 5) * 5 + 1

    const endPage = Math.min(startPage + 4, totalPages) || 1

    const firstPageUrl = `${endpoint}&page=1`
    const lastPageUrl = `${endpoint}&page=${totalPages}`

    return (
        <div className="product-page-bar">
            <ul>
                <li>
                    <Link to={firstPageUrl}>
                        <i className="icon-angle-double-left" />
                    </Link>
                </li>
                <li>
                    <Link
                        to={
                            productPage > 1
                                ? `${endpoint}&page=${productPage - 1}`
                                : `${endpoint}&page=1`
                        }
                    >
                        <i className="icon-angle-left" />
                    </Link>
                </li>
                {[...Array(endPage - startPage + 1)].map((_, index) => {
                    const page = startPage + index
                    searchParams.set('page', page.toString())
                    const urlTemp = `${endpoint}&page=${page}`
                    return (
                        <li key={index}>
                            {/* 현재 페이지와 링크된 페이지가 같으면 'current' 클래스 추가 */}
                            <Link
                                to={`${urlTemp}`}
                                id={page === productPage ? 'current' : ''}
                            >
                                {page}
                            </Link>
                        </li>
                    )
                })}
                <li>
                    <Link
                        to={
                            productPage < totalPages
                                ? `${endpoint}&page=${productPage + 1}`
                                : `${endpoint}&page=${totalPages}`
                        }
                    >
                        <i className="icon-angle-right" />
                    </Link>
                </li>
                <li>
                    <Link to={lastPageUrl}>
                        <i className="icon-angle-double-right" />
                    </Link>
                </li>
            </ul>
        </div>
    )
}

export const BoardPageBar = (props: {
    page: number
    totalPage: number
    setPage: React.Dispatch<React.SetStateAction<number>>
    setBoardList: React.Dispatch<React.SetStateAction<Board[]>>
}): JSX.Element => {
    const boardPage = props.page
    const totalPage = props.totalPage
    const totalPages = totalPage < 1 ? 1 : totalPage
    const startPage = Math.floor((boardPage - 1) / 5) * 5 + 1

    const endPage = Math.min(startPage + 4, totalPages)

    return (
        <div className="product-page-bar">
            <ul>
                <li>
                    <a
                        onClick={() => {
                            props.setPage(1)
                        }}
                    >
                        <i className="icon-angle-double-left" />
                    </a>
                </li>
                <li>
                    <a
                        onClick={() => {
                            if (props.page > 1) {
                                props.setPage(props.page - 1)
                            } else {
                                props.setPage(1)
                            }
                        }}
                    >
                        <i className="icon-angle-left" />
                    </a>
                </li>

                {[...Array(endPage - startPage + 1)].map((_, index) => {
                    const page = startPage + index
                    return (
                        <li key={index}>
                            {/* 현재 페이지와 링크된 페이지가 같으면 'current' 클래스 추가 */}
                            <a
                                id={page === boardPage ? 'current' : ''}
                                onClick={() => {
                                    props.setPage(page)
                                }}
                            >
                                {page}
                            </a>
                        </li>
                    )
                })}

                <li>
                    <a
                        onClick={() => {
                            if (props.page < totalPages) {
                                props.setPage(props.page + 1)
                            }
                        }}
                    >
                        <i className="icon-angle-right" />
                    </a>
                </li>
                <li>
                    <a
                        onClick={() => {
                            props.setPage(totalPages)
                        }}
                    >
                        <i className="icon-angle-double-right" />
                    </a>
                </li>
            </ul>
        </div>
    )
}

export const AddressPageBar = (props: {
    page: number
    totalCount: number
    setPage: React.Dispatch<React.SetStateAction<number>>
}): JSX.Element => {
    const boardPage = props.page
    const totalCount = props.totalCount
    const totalPage = Math.ceil(totalCount / 5)
    const totalPages = totalPage < 1 ? 1 : totalPage
    const startPage = Math.floor((boardPage - 1) / 5) * 5 + 1

    const endPage = Math.min(startPage + 4, totalPages)

    return (
        <div className="product-page-bar">
            <ul>
                <li>
                    <a
                        onClick={() => {
                            if (props.page > 1) {
                                props.setPage(props.page - 1)
                            } else {
                                props.setPage(1)
                            }
                        }}
                    >
                        <i className="icon-angle-left" />
                    </a>
                </li>

                {[...Array(endPage - startPage + 1)].map((_, index) => {
                    const page = startPage + index
                    return (
                        <li key={index}>
                            {/* 현재 페이지와 링크된 페이지가 같으면 'current' 클래스 추가 */}
                            <a
                                id={page === boardPage ? 'current' : ''}
                                onClick={() => {
                                    props.setPage(page)
                                }}
                            >
                                {page}
                            </a>
                        </li>
                    )
                })}

                <li>
                    <a
                        onClick={() => {
                            if (props.page < totalPages) {
                                props.setPage(props.page + 1)
                            }
                        }}
                    >
                        <i className="icon-angle-right" />
                    </a>
                </li>
            </ul>
        </div>
    )
}

export const OrderPageBar = (props: {
    page: number
    totalCount: number
    perPage: number
    setPage: React.Dispatch<React.SetStateAction<number>>
}): JSX.Element => {
    const totalPages = Math.ceil(props.totalCount / props.perPage)

    const currentPage = Math.max(props.page, 1)

    const startPage =
        Math.floor((currentPage - 1) / props.perPage) * props.perPage + 1

    const endPage = Math.min(startPage + props.perPage - 1, totalPages)

    return (
        <div className="product-page-bar">
            <ul>
                <li>
                    <a
                        onClick={() => {
                            props.setPage(1)
                        }}
                    >
                        <i className="icon-angle-double-left" />
                    </a>
                </li>
                <li>
                    <a
                        onClick={() => {
                            if (props.page > 1) {
                                props.setPage(props.page - 1)
                            } else {
                                props.setPage(1)
                            }
                        }}
                    >
                        <i className="icon-angle-left" />
                    </a>
                </li>

                {[...Array(endPage - startPage + 1)].map((_, index) => {
                    const page = startPage + index
                    return (
                        <li key={index}>
                            {/* 현재 페이지와 링크된 페이지가 같으면 'current' 클래스 추가 */}
                            <a
                                id={page === props.page ? 'current' : ''}
                                onClick={() => {
                                    props.setPage(page)
                                }}
                            >
                                {page}
                            </a>
                        </li>
                    )
                })}

                <li>
                    <a
                        onClick={() => {
                            if (props.page < totalPages) {
                                props.setPage(props.page + 1)
                            }
                        }}
                    >
                        <i className="icon-angle-right" />
                    </a>
                </li>
                <li>
                    <a
                        onClick={() => {
                            props.setPage(totalPages)
                        }}
                    >
                        <i className="icon-angle-double-right" />
                    </a>
                </li>
            </ul>
        </div>
    )
}
