import React from 'react'
import './ProductPeriod.css'

export const ProductPeriod = () => {
    return (
        <div className={'product-period'}>
            <h5>배송정보</h5>
            <table className="info-table">
                <colgroup>
                    <col width="20%" />
                    <col width="35%" />
                    <col width="15%" />
                    <col width="35%" />
                </colgroup>
                <tbody>
                    <tr>
                        <th>배송방법</th>
                        <td>순차배송</td>
                        <th rowSpan={2} className="close-left">
                            배송비
                        </th>
                        <td rowSpan={2}>
                            무료배송
                            <br />- 상품 중 30,000원 이상 구매 시 무료배송
                            <br />- 도서산간 지역 추가비용 없음
                        </td>
                    </tr>
                    <tr>
                        <th>묶음배송 여부</th>
                        <td>가능</td>
                    </tr>
                    <tr>
                        <th>배송기간</th>
                        <td colSpan={3}>
                            <ul>
                                <li>
                                    주문 및 결제 완료 후, 2-3일 이내 도착
                                    <p>
                                        - 도서 산간 지역 등은 하루가 더 소요될
                                        수 있습니다.
                                    </p>
                                </li>
                                <li>
                                    <span>
                                        천재지변, 물량 수급 변동 등 예외적인
                                        사유 발생 시, 다소 지연될 수 있는 점
                                        양해 부탁드립니다.
                                    </span>
                                </li>
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>

            <h5>교환/반품 안내</h5>
            <table className="info-table">
                <colgroup>
                    <col width="20%" />
                    <col />
                </colgroup>
                <tbody>
                    <tr>
                        <th>교환/반품 비용</th>
                        <td>
                            1) [총 주문금액] - [반품 상품금액] = 19,800원 미만인
                            경우 반품비 5,000원
                            <br />
                            2) [총 주문금액] - [반품 상품금액] = 19,800원 이상인
                            경우 반품비 2,500원
                        </td>
                    </tr>
                    <tr>
                        <th>교환/반품 신청 기준일</th>
                        <td>
                            <p>
                                ㆍ단순변심에 의한 상품의 교환/반품은 제품 수령
                                후 30일 이내까지, 교환/반품 제한사항에 해당하지
                                않는 경우에만 가능 (교환/반품 비용 고객부담)
                            </p>
                            <p>
                                ㆍ상품의 내용이 표시·광고의 내용과 다른 경우에는
                                상품을 수령한 날부터 3개월 이내, 그 사실을 안 날
                                또는 알 수 있었던 날부터
                                <span>30일 이내에 청약철회 가능</span>
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <h5>교환/반품 제한사항</h5>
            <div>
                <table className="info-table">
                    <colgroup>
                        <col width="20%" />
                        <col />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>판매자</th>
                            <td>
                                <a
                                    href="tel:+8201022564628"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    010-2256-4628
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}
