import { Category, CategoryDTO } from '@Interface/Category'
import React from 'react'
import axios from 'axios'
import { HttpAlert, HttpSuccess } from '@Interface/HttpCallBack'
import {
    deleteRequest,
    getRequest,
    postRequest,
    putRequest,
} from '@Service/Api'
import { getModalInstance } from '@Component/common/Widgets/Modal/ModalProvider'

const httpAlert: HttpAlert = {}

export const PrimaryCategoryEditService = (props: { category: string }) => {
    const modal = getModalInstance()

    const editCategory = async () => {
        modal!.prompt(
            async (afterPrimaryNameTemp: string) => {
                if (afterPrimaryNameTemp === '') {
                    modal!.alert(
                        () => {},
                        '알림',
                        '카테고리 이름을 입력해주세요'
                    )
                } else if (afterPrimaryNameTemp != null) {
                    const categoryDTO: CategoryDTO = {
                        beforePrimaryName: props.category,
                        afterPrimaryName: afterPrimaryNameTemp,
                    }

                    const httpSuccess: HttpSuccess = {
                        200: () => {
                            modal!.alert(
                                () => {},
                                '알림',
                                '카테고리가 성공적으로 수정되었습니다.'
                            )
                            const element = document.getElementById(
                                'primary' + props.category
                            )
                            if (element) {
                                element.innerHTML = afterPrimaryNameTemp
                            }
                        },
                    }
                    await putRequest(
                        '/admin/product/category/primary',
                        httpSuccess,
                        httpAlert,
                        categoryDTO,
                        {}
                    )
                }
            },
            () => {},
            '입력',
            '변경할 이름을 입력해주세요.'
        )
    }

    return <i className={'icon-pencil'} onClick={editCategory} />
}


export const PrimaryCategoryInsertService = () => {
    const modal = getModalInstance()

    const insertCategory = () => {
        modal!.prompt(
            (primaryName: string) => {
                if (primaryName === '') {
                    modal!.alert(
                        () => {},
                        '알림',
                        '카테고리 이름을 입력해주세요'
                    )
                    return false
                }
                modal!.prompt(
                    (secondName: string) => {
                        if (secondName === '') {
                            modal!.alert(
                                () => {},
                                '알림',
                                '카테고리 이름을 입력해주세요'
                            )
                        } else if (primaryName != null && secondName != null) {
                            let category: Category = {
                                categoryId: null,
                                primaryName: primaryName,
                                secondaryName: secondName,
                            }

                            const httpSuccess: HttpSuccess = {
                                200: () => {
                                    modal!.alert(
                                        () => {
                                            window.location.reload()
                                        },
                                        '알림',
                                        '카테고리가 성공적으로 추가되었습니다.'
                                    )
                                },
                            }

                            postRequest(
                                '/admin/product/category',
                                httpSuccess,
                                httpAlert,
                                category,
                                {}
                            )
                        }
                    },
                    () => {},
                    '입력',
                    '2차 카테고리 이름을 입력해주세요'
                )
            },
            () => {},
            '입력',
            '1차 카테고리 이름을 입력해주세요.'
        )
    }
    return (
        <i className={'icon-plus'} onClick={insertCategory}>
            카테고리 추가
        </i>
    )
}

export const PrimaryCategoryRemoveService = (props: { category: string }) => {
    const modal = getModalInstance()
    const removeCategory = async () => {
        modal!.confirm(
            async () => {
                const httpSuccess: HttpSuccess = {
                    200: () => {
                        modal!.alert(
                            () => {
                                window.location.reload()
                            },
                            '알림',
                            '카테고리가 성공적으로 삭제되었습니다.'
                        )
                    },
                }
                await deleteRequest(
                    '/admin/product/category/primary/' + props.category,
                    httpSuccess,
                    httpAlert,
                    {}
                )
            },
            () => {
                return false
            },
            '알림',
            '해당 카테고리 상품이 모두 비공개 됩니다.\n삭제하시겠습니까?'
        )
    }
    return <i className={'icon-trash'} onClick={removeCategory} />
}

export const SecondCategoryEditService = (props: { category: Category }) => {
    const { category } = props
    const modal = getModalInstance()

    const editCategory = async () => {
        modal!.prompt(
            (secondaryNameTemp: string) => {
                if (secondaryNameTemp === '') {
                    modal!.alert(
                        () => {},
                        '알림',
                        '카테고리 이름을 입력해주세요.'
                    )
                } else if (secondaryNameTemp != null) {
                    category.secondaryName = secondaryNameTemp
                    const httpSuccess: HttpSuccess = {
                        200: () => {
                            modal!.alert(
                                () => {},
                                '알림',
                                '카테고리가 성공적으로 수정되었습니다.'
                            )
                            const categoryId =
                                category.categoryId != null
                                    ? category.categoryId
                                    : ''
                            const element = document.getElementById(
                                'second' + categoryId
                            )
                            if (element) {
                                element.innerHTML = category.secondaryName
                            }
                        },
                    }
                    putRequest(
                        '/admin/product/category/second',
                        httpSuccess,
                        httpAlert,
                        category,
                        {}
                    )
                }
            },
            () => {},
            '입력',
            '변경할 이름을 입력해주세요.'
        )
    }

    return <i className={'icon-pencil'} onClick={editCategory} />
}

export const SecondCategoryOrderEditService = (props: { category: Category }) => {
    const { category } = props
    const modal = getModalInstance()

    const editCategory = async () => {
        modal!.prompt(
            (orderTemp: string) => {
                if (isNaN(parseInt(orderTemp))) {
                    modal!.alert(
                        () => {},
                        '알림',
                        '변경값을 입력해주세요'
                    )
                } else {
                    category.menuOrderSd = parseInt(orderTemp)
                    const httpSuccess: HttpSuccess = {
                        200: () => {
                            modal!.alert(
                                () => {window.location.reload()},
                                '알림',
                                '카테고리가 성공적으로 수정되었습니다.'
                            )
                            const categoryId =
                                category.categoryId != null
                                    ? category.categoryId
                                    : ''
                            const element = document.getElementById(
                                'second' + categoryId
                            )
                            if (element) {
                                element.innerHTML = category.secondaryName
                            }
                        },
                    }
                    putRequest(
                        '/admin/product/category/second',
                        httpSuccess,
                        httpAlert,
                        category,
                        {}
                    )
                }
            },
            () => {},
            '입력',
            '변경할 순서를입력해주세요.','현재순서 : '+ category.menuOrderSd
        )
    }

    return <i className={'icon-menu'} onClick={editCategory} />
}
export const SecondCategoryInsertService = (props: { category: string }) => {
    const modal = getModalInstance()

    let category: Category = {
        categoryId: null,
        primaryName: props.category,
        secondaryName: '',
    }

    const insertCategory = async () => {
        modal!.prompt(
            (secondaryNameTemp: string) => {
                if (secondaryNameTemp != null) {
                    category.secondaryName = secondaryNameTemp

                    const httpSuccess: HttpSuccess = {
                        200: () => {
                            modal!.alert(
                                () => {
                                    window.location.reload()
                                },
                                '알림',
                                '카테고리가 성공적으로 추가되었습니다.'
                            )
                        },
                    }
                    postRequest(
                        '/admin/product/category',
                        httpSuccess,
                        httpAlert,
                        category,
                        {}
                    )
                }
            },
            () => {},
            '입력',
            '추가할 이름을 입력해주세요.'
        )
    }
    return <i className={'icon-plus'} onClick={insertCategory} />
}

export const SecondCategoryRemoveService = (props: { category: Category }) => {
    const modal = getModalInstance()

    const removeCategory = async () => {
        modal!.confirm(
            async () => {
                const httpSuccess: HttpSuccess = {
                    200: () => {
                        modal!.alert(
                            () => {
                                const element = document.getElementById(
                                    props.category.secondaryName + 'group'
                                )
                                if (element) {
                                    element.style.display = 'none'
                                }
                            },
                            '알림',
                            '카테고리가 성공적으로 삭제되었습니다.'
                        )
                    },
                }
                deleteRequest(
                    '/admin/product/category/second/' +
                        props.category.categoryId,
                    httpSuccess,
                    httpAlert,
                    {}
                )
            },
            () => {
                return false
            },
            '알림',
            '해당 카테고리 상품이 모두 비공개 됩니다.\n삭제하시겠습니까?'
        )
    }

    return (
        <>
            <i className={'icon-trash'} onClick={removeCategory} />
        </>
    )
}
