import {Link, useLocation, useParams, useSearchParams} from 'react-router-dom'
import './OrderTabBar.css'
import * as queryString from "querystring";

export const OrderTabBar = () => {
    const [searchParams] = useSearchParams()
    const orderType = searchParams.get('orderType')
    return (
        <div className={'tab'}>
            <Link
                className={
                    orderType == '주문' ? 'active' : ''
                }
                to="/admin/order?orderType=주문"
            >
                주문
            </Link>
            <Link
                className={ orderType == '교환' ? 'active' : ''}
                to="/admin/order?orderType=교환"
            >
                교환
            </Link>
            <Link
                className={
                    orderType == '반품' ? 'active' : ''
                }
                to="/admin/order?orderType=반품"
            >
                반품
            </Link>
            <Link
                className={
                    orderType == '취소' ? 'active' : ''? 'active' : ''
                }
                to="/admin/order?orderType=취소"
            >
                취소
            </Link>
        </div>
    )
}
