import { useEffect, useState } from 'react'
import './WishView.css'
import { Product } from '@Interface/Product'
import { getWishListService } from '@Service/user/wish/WishService'
import { ProductListItem } from '../ProductList/ProductList'
import { getModalInstance } from '@Component/common/Widgets/Modal/ModalProvider'
import { setWishicon } from '@Util/WishUtils'

export const WishView = () => {
    const [productList, setProductList] = useState<Product[]>()

    const fetchData = async () => {
        setProductList((await getWishListService()) || [])
        await setWishicon()
    }
    useEffect(() => {
        fetchData()
    }, [])

    return (
        <div className="wish-view">
            <h2>위시리스트</h2>
            {productList?.map((product) => (
                <ProductListItem key={product.productId} product={product} />
            ))}

            {productList?.length == 0 && (
                <div className="empty-wish">위시리스트가 비어있습니다.</div>
            )}
        </div>
    )
}
