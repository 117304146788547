import { getModalInstance } from '@Component/common/Widgets/Modal/ModalProvider'
import { HttpAlert, HttpSuccess } from '@Interface/HttpCallBack'
import { deleteRequest, getRequest, postRequest } from '@Service/Api'
import { Address } from '@Interface/Address'
import React from 'react'

export const saveAddressService = async (address: Address) => {
    const modal = getModalInstance()

    try {
        const httpSuccess: HttpSuccess = {
            200: () => {
                modal!.alert(() => {}, '알림', '저장되었습니다.')
            },
        }
        const httpAlert: HttpAlert = {}

        return await postRequest(
            '/address',
            httpSuccess,
            httpAlert,
            address,
            {}
        )
    } catch (e) {
        console.log(e)
    }
}

export const getAddressListService = async () => {
    const modal = getModalInstance()

    try {
        const httpSuccess: HttpSuccess = {

        }
        const httpAlert: HttpAlert = {
            401: ()=>{
                modal?.alert(()=>{
                    window.location.href='/user/login'
                },'알림','이용을위해 로그인이 필요합니다.')
            }
        }

        return await getRequest<Address[]>(
            '/address/list',
            httpSuccess,
            httpAlert,
            {}
        )
    } catch (e) {
        console.log(e)
    }
}

export const deleteAddressService = async (
    addressId: string,
    setAddressList: React.Dispatch<React.SetStateAction<Address[]>>
) => {
    const modal = getModalInstance()

    try {
        const httpSuccess: HttpSuccess = {
            200: () => {
                modal?.alert(
                    async () => {
                        setAddressList((await getAddressListService()) || [])
                    },
                    '알림',
                    '삭제되었습니다.'
                )
            },
        }
        const httpAlert: HttpAlert = {}

        return await deleteRequest<Address[]>(
            '/address/' + addressId,
            httpSuccess,
            httpAlert,
            {}
        )
    } catch (e) {
        console.log(e)
    }
}
