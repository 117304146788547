import { Link, useLocation } from 'react-router-dom'
import './MypageAccount.css'
import { ViewProdcut } from '@Component/product/ViewProduct/ViewProduct'
import { WishView } from '@Component/product/Wish/WishView'
import { UserInfo } from '@Interface/User'
import { useEffect, useState } from 'react'
import { OrderCountDTO } from '@Interface/Order'
import { getOrderCountService } from '@Service/user/order/OrderService'

export const MypageAccount = (props: { userInfo: UserInfo }) => {
    const [orderCount, setOrderCount] = useState<OrderCountDTO>()

    const fetchData = async () => {
        setOrderCount(await getOrderCountService())
    }

    useEffect(() => {
        fetchData()
    }, [])
    return (
        <div className={'mypage-account'}>
            <h3>유저 정보</h3>
            <UserInfoView userInfo={props.userInfo} />
            <h3>진행 현황</h3>
            <div className="order-dashboard">
                <div>
                    <a>
                        <p>주문완료</p>
                        <p>({orderCount?.completeOrderCount || 0})</p>
                    </a>
                </div>
                <div>
                    <i className="icon-right-open"></i>
                </div>
                <div>
                    <a>
                        <p>준비중</p>
                        <p>({orderCount?.preparationCount || 0})</p>
                    </a>
                </div>
                <div>
                    <i className="icon-right-open"></i>
                </div>
                <div>
                    <a>
                        <p>배송중</p>
                        <p>({orderCount?.inDeliveryCount || 0})</p>
                    </a>
                </div>
                <div>
                    <i className="icon-right-open"></i>
                </div>
                <div>
                    <a>
                        <p>배송완료</p>
                        <p>({orderCount?.deliveredCount || 0})</p>
                    </a>
                </div>
                <div>
                    <p style={{ fontWeight: '1000' }}>/</p>
                </div>
                <div>
                    <a>
                        <p>교환/반품</p>
                        <p id="exchange-refund">
                            ({orderCount?.exchangeOrRefundCount || 0})
                        </p>
                    </a>
                </div>
            </div>
            <WishView />
            <ViewProdcut />
        </div>
    )
}

export const UserInfoView = (props: {
    userInfo: UserInfo
    isMypage?: boolean
}) => {
    return (
        <div className="user-info">
            <div>
                <div id="user-name">
                    <span>이름 :</span> {props.userInfo?.userName}
                </div>
                <div id="user-pn">
                    <span>전화번호 :</span> {props.userInfo?.phoneNumber}
                </div>
                <div id="user-email ">
                    <span>이메일 :</span> {props.userInfo?.email}
                </div>
            </div>

            {props.isMypage && (
                <div>
                    <a className="bg-black-button">회원정보수정</a>
                    <a className="bg-white-button">회원탈퇴</a>
                </div>
            )}
        </div>
    )
}
