import { ReactElement } from 'react'

const preventScroll = (e: Event) => {
    e.preventDefault()
}

export const disableScroll = () => {
    document.body.classList.add('scrollDisable')
    window.addEventListener('scroll', preventScroll, { passive: false })
    window.addEventListener('touchmove', preventScroll, { passive: false })
    window.addEventListener('wheel', preventScroll, { passive: false })
}

export const enableScroll = () => {
    document.body.classList.remove('scrollDisable')
    window.removeEventListener('scroll', preventScroll)
    window.removeEventListener('touchmove', preventScroll)
    window.removeEventListener('wheel', preventScroll)
}

export const scrollToTop = () => {
    const mainElement = document.querySelector('body')
    if (mainElement) {
        mainElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        })
    }
}

export const scrollToBottom = () => {
    const mainElement = document.querySelector('body')
    if (mainElement) {
        mainElement.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
            inline: 'nearest',
        })
    }
}

export const shareLink = (productId : string) => {


    if (navigator.share) {

        navigator
            .share({
                url: 'https://brospo.co.kr/product/' + productId,
            })
            .then(() => console.log('링크 공유 성공'))
            .catch((error) => console.error('링크 공유 실패', error))
    } else {

        var tempInput = document.createElement('input')
        tempInput.setAttribute('value', 'https://brospo.co.kr/product/' + productId,)
        document.body.appendChild(tempInput)
        tempInput.select()
        document.execCommand('copy')
        document.body.removeChild(tempInput)
        alert('링크가 복사되었습니다.')
    }
}
