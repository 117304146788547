import React, { ChangeEvent, useEffect, useState } from 'react'
import { Address } from '@Interface/Address'
import { getModalInstance } from '@Component/common/Widgets/Modal/ModalProvider'
import './AddressForm.css'
import { SearchAddress } from '@Component/common/Widgets/Address/SearchAddress'
import { UserInfo } from '@Interface/User'
import { saveAddress } from '@Util/AddressUtils'
import { formatPhoneNumber } from '@Util/UserUtils'
import {
    deleteAddressService,
    getAddressListService,
} from '@Service/user/Address/AddressService'
import { decodeHTML } from '@Util/StringUtils'
import DatePicker from 'react-datepicker'

export const AddressForm = (props: {
    userInfo: UserInfo | undefined
    setAddress: React.Dispatch<React.SetStateAction<Address | undefined>>
}) => {
    const [addressList, setAddressList] = useState<Address[]>([])

    const modal = getModalInstance()

    const fetchData = async () => {
        setAddressList((await getAddressListService()) || [])
    }
    useEffect(() => {
        props.setAddress(undefined)
        fetchData()
    }, [])

    return (
        <div className={'address-form'}>
            <h3>
                배송지 선택
                {addressList.length < 3 && (
                    <input
                        type={'button'}
                        value={'추가'}
                        className={'bg-white-button'}
                        onClick={() => {
                            modal?.popup(
                                <AddressEditor
                                    userInfo={props.userInfo}
                                    address={null}
                                />,
                                '96vw',
                                'auto',
                                async () => {
                                    await fetchData()
                                }
                            )
                        }}
                    />
                )}
            </h3>

            <div>
                {addressList?.length != 0 ? (
                    addressList?.map((address, index) => (
                        <div className="address-item" key={index}>
                            <h3>
                                <p>{address.addressNickName}</p>
                                <div>
                                    <input
                                        className="bg-white-button"
                                        type="button"
                                        value={'선택'}
                                        onClick={() => {
                                            props.setAddress(address)
                                            modal?.closeModal()
                                        }}
                                    />
                                    <input
                                        className="bg-white-button"
                                        type="button"
                                        value={'수정'}
                                        onClick={() => {
                                            modal?.popup(
                                                <AddressEditor
                                                    userInfo={props.userInfo}
                                                    address={address}
                                                />,
                                                '96vw',
                                                'auto',
                                                async () => {
                                                    await fetchData()
                                                }
                                            )
                                        }}
                                    />
                                    <input
                                        className="bg-white-button"
                                        type="button"
                                        value={'삭제'}
                                        onClick={async () => {
                                            await deleteAddressService(
                                                address.addressId,
                                                setAddressList
                                            )
                                        }}
                                    />
                                </div>
                            </h3>
                            <div>
                                <p>수령인</p>{' '}
                                <span>{address.recipientName}</span>
                            </div>
                            <div>
                                <p> 전화번호</p>
                                <span>{address.recipientContact}</span>
                            </div>
                            <div>
                                <p>주소</p>
                                <span>
                                    {decodeHTML(address.address || '').replace(
                                        '///',
                                        ' '
                                    )}
                                </span>
                            </div>

                            <div>
                                <p>배송요청사항</p>{' '}
                                <span>{address.deliveryRequest}</span>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className={'empty-view'}>주소 정보가 없습니다.</div>
                )}
            </div>
        </div>
    )
}

const validOptions = [
    '부재 시 경비실에 맡겨 주세요',
    '부재 시 문 앞에 두고 가세요',
    '부재 시 전화 주세요',
    '택배함에 넣어 주세요',
    '도착 전에 미리 전화 주세요',
    '직접입력',
]
const AddressEditor = (props: {
    userInfo: UserInfo | undefined
    address: Address | null
}) => {
    const modal = getModalInstance()
    const [address, setAddress] = useState<string>()

    useEffect(() => {
        setAddress(props.address?.address?.split('///')[0])

        if (
            props.address?.deliveryRequest &&
            !validOptions.includes(props.address?.deliveryRequest)
        ) {
            const inputEl = document.getElementById(
                'delivery-request'
            ) as HTMLInputElement
            inputEl.type = 'text'
        }
    }, [])

    return (
        <div className={'address-editor'}>
            <h2>
                배송지 작성
                <div>
                    {props.address ? (
                        <input
                            type={'button'}
                            value={'수정'}
                            onClick={async () => {
                                if (await saveAddress()) {
                                    modal?.closeModal()
                                }
                            }}
                        />
                    ) : (
                        <input
                            type={'button'}
                            value={'저장'}
                            onClick={async () => {
                                if (await saveAddress()) {
                                    modal?.closeModal()
                                }
                            }}
                        />
                    )}
                </div>
            </h2>
            <input
                type={'hidden'}
                id={'address-id'}
                value={props.address?.addressId || ''}
            />
            <label htmlFor="address-nicname">
                배송지 별칭
                <input
                    type="checkbox"
                    id="main-address"
                    className="checkbox"
                    defaultChecked={props.address?.mainAddress == 'Y'}
                />
                <label htmlFor="main-address" className="checkbox-label">
                    기본배송지
                </label>
            </label>
            <input
                type="text"
                placeholder="ADDRESS NICNAME"
                id="address-nicname"
                defaultValue={props.address?.addressNickName || '집'}
            />
            <label htmlFor="address">
                배송지 주소
                <input
                    className={'bg-white-button'}
                    type={'button'}
                    value={'주소검색'}
                    onClick={() => {
                        modal?.popup(
                            <SearchAddress setAddress={setAddress} />,
                            '96vw',
                            'auto'
                        )
                    }}
                />
            </label>
            <input
                type="text"
                id="address"
                placeholder="ADDRESS"
                value={decodeHTML(address || '').split('///')[0]}
                readOnly={true}
            />
            <input
                type="text"
                id="address-detail"
                placeholder="ADDRESS DETAIL"
                defaultValue={props.address?.address?.split('///')[1] || ''}
                readOnly={!address}
            />
            <label htmlFor="recipient-name">수령인 이름</label>
            <input
                type="text"
                id="recipient-name"
                placeholder={'RECIPIENT NAME'}
                defaultValue={
                    props.address?.recipientName ||
                    props.userInfo?.userName ||
                    ''
                }
            />
            <label htmlFor="recipient-contact">수령인 전화번호</label>
            <input
                type="text"
                id="recipient-contact"
                placeholder={'RECIPIENT CONTACT'}
                defaultValue={
                    props.address?.recipientContact ||
                    props.userInfo?.phoneNumber ||
                    ''
                }
                onInput={(event) => {
                    const target = event.target as HTMLInputElement
                    target.value = formatPhoneNumber(target.value)
                }}
            />
            <label htmlFor="delivery-request">배송 요청사항</label>

            <select
                id={'request-selector'}
                onChange={(e: ChangeEvent) => {
                    const targetValue = (e.target as HTMLInputElement).value
                    const inputEl = document.getElementById(
                        'delivery-request'
                    ) as HTMLInputElement
                    inputEl.value = targetValue
                    if (targetValue == '직접입력') {
                        inputEl.type = 'text'
                        inputEl.value = ''
                        inputEl.focus()
                    } else {
                        inputEl.type = 'hidden'
                    }
                }}
                defaultValue={
                    props.address?.deliveryRequest
                        ? validOptions.includes(props.address?.deliveryRequest)
                            ? props.address?.deliveryRequest
                            : '직접입력'
                        : ''
                }
            >
                <option value="">배송 요청사항을 선택하세요</option>
                {validOptions.map((option) => (
                    <option value={option}>{option}</option>
                ))}
            </select>

            <input
                type="hidden"
                id="delivery-request"
                placeholder={'DELIVERY REQUEST'}
                defaultValue={props.address?.deliveryRequest || ''}
            />
        </div>
    )
}
