import { Board } from '@Interface/Board'
import React, { useEffect, useState } from 'react'
import { Search } from 'react-router-dom'
import { AddressDTO, AddressItem, SearchAddressDTO } from '@Interface/Address'
import { searchAddressService } from '@Service/user/cmm/CmmService'
import { add } from 'lodash'
import { AddressPageBar } from '@Component/common/Widgets/PageBar/PageBar'
import './SearchAddress.css'
import { getModalInstance } from '@Component/common/Widgets/Modal/ModalProvider'
export const SearchAddress = (props: {
    setAddress: React.Dispatch<React.SetStateAction<string | undefined>>
}) => {
    const modal = getModalInstance()
    const [addressList, setAddressList] = useState<AddressItem[]>([])
    const [page, setPage] = useState<number>(1)
    const [totalCount, setTotalCount] = useState<number>(1)
    const searchAddress = async (schPage?: number) => {
        const keyword = (
            document.getElementById('address-keyword') as HTMLInputElement
        ).value
        if (keyword == '') {
            modal?.alert(() => {}, '알림', '검색어를 입력해주세요')
            return
        }
        const search: SearchAddressDTO = {
            currentPage: schPage || page,
            keyword: (
                document.getElementById('address-keyword') as HTMLInputElement
            ).value,
        }
        const addressDTO = await searchAddressService(search)
        if (addressDTO?.addressList.length == 0) {
            modal?.alert(
                () => {},
                '알림',
                '검색결과가 없습니다.',
                '검색값을 확인해주세요.'
            )
            return
        }
        setAddressList(addressDTO?.addressList || [])
        setTotalCount(addressDTO?.totalCount || 1)
    }
    useEffect(() => {
        if (addressList.length != 0) {
            searchAddress()
        }
    }, [page])

    return (
        <div className={'address-search-view'}>
            <h3>주소 검색</h3>
            <div className={'search-area'}>
                <input
                    id={'address-keyword'}
                    type={'text'}
                    placeholder={'ADDRESS KEYWORD'}
                />
                <i
                    className={'icon-search'}
                    onClick={() => {
                        searchAddress(1)
                    }}
                ></i>
            </div>
            <table className={'info-table'}>
                <colgroup>
                    <col width="*" />
                    <col width="12%" />
                </colgroup>
                <thead>
                    <th className={'center'}>도로명 주소</th>
                    <th className={'center'}>선택</th>
                </thead>
                <tbody>
                    {addressList.length != 0 ? (
                        addressList?.map((address, index) => (
                            <tr key={index}>
                                <td>
                                    [{address.zipNo}] {address.roadAddr}
                                    <br />
                                    <p>{address.jibunAddr}</p>
                                </td>
                                <td className={'center'}>
                                    <input
                                        className={'bg-white-button'}
                                        type={'button'}
                                        value={'선택'}
                                        onClick={() => {
                                            props.setAddress(
                                                `[${address.zipNo}] ` +
                                                    address.roadAddr
                                            )
                                            modal?.closeModal()
                                        }}
                                    />
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td
                                className="open-left open-right center"
                                colSpan={3}
                                height={150}
                            >
                                주소를 검색해주세요.
                            </td>
                        </tr>
                    )}
                </tbody>
                <tfoot>
                    <tr>
                        <td
                            className={'center'}
                            colSpan={3}
                            style={{ border: 'none' }}
                        >
                            <AddressPageBar
                                page={page}
                                totalCount={totalCount}
                                setPage={setPage}
                            />
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    )
}
