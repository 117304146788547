import './DeliveryTracker.css'
import {useEffect, useState} from "react";
import {getTracking} from "@Service/cmm/DeliveryService";
import {carrierNameMapping, DeliveryState} from "@Interface/Order";
import {formatDateToString} from "@Util/StringUtils";

export const DeliveryTracker = (props: { deliveryCode: string }) => {
    const [deliveryList, setDeliveryList] = useState<DeliveryState[]>()

    const fetchData = async () => {
        const carrier = props.deliveryCode.split('///')[0]
        const deliveryNum = props.deliveryCode.split('///')[1]
        setDeliveryList(await getTracking(carrier, deliveryNum) || undefined)
    }
    useEffect(() => {
        fetchData()
    }, [props.deliveryCode]);
    return (
        <div className={'delivery-tracker'}>
            <h2>{carrierNameMapping[props.deliveryCode.split('///')[0]]} 배송조회</h2>
            <h3>{props.deliveryCode.split('///')[1]}</h3>
            {deliveryList?.map((delivery)=> (
                <div>
                    <h4>
                        {delivery.name}<span>{formatDateToString(delivery.time)}</span><a>●</a>
                    </h4>
                    <p>{delivery.description}</p>
                </div>
            ))}
            {deliveryList?.length==0 && (
                <div>
                    <h4>
                        조회불가<span>{formatDateToString(new Date())}</span><a>●</a>
                    </h4>
                    <p>송장번호가 잘못 입력되었거나, 아직 전산에 등록되지 않았을 수 있습니다. 문제가 지속되면 고객센터에 문의해 주세요.</p>
                </div>
            )}
        </div>
    )
}
