import loadingImage from '@Image/view/loading.gif'
import './Loading.css'

export const Loading = () => {
    return (
        <div id="loading">
            <img src={loadingImage} alt="Loading..." />
        </div>
    )
}

export const openLoading = () => {
    const el = document.getElementById('loading');
    if(el){
        el.style.display = 'flex'
    }
}

export const closeLoading = () => {
    const el = document.getElementById('loading');
    if(el){
        el.style.display = 'none'
    }
}
