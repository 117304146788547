import { Product, ProductFormProps } from '@Interface/Product'
import { SizeSelector } from '@Component/product/ProductInfo/SizeSelector'
import { Category } from '@Interface/Category'
import {
    checkDeliveryFee,
    checkProductCode,
    checkProductName,
    checkProductPrice,
} from '@Util/ProductUtils'
import { CategorySelector } from './CategorySelector'
import {checkId} from "@Util/UserUtils";

export const ProductInfoForm = (props: {
    product: Product | null
    categoryList: { [p: string]: Category[] }
}) => {
    const product = props.product

    const categoryList = props.categoryList

    let typingTimer: ReturnType<typeof setTimeout> | null
    const doneTypingInterval = 150 // 타이핑이 멈춘 후 요청을 보내기까지의 대기 시간 (밀리초)

    return (
        <div className={'product-info'}>
            <h2>기본정보</h2>
            <label>
                상품 코드<span id="product-code-message"></span>
            </label>
            <input
                type="text"
                placeholder="PRODUCT CODE"
                id="product-code"
                defaultValue={product?.productCode || ''}
                onInput={() => {
                    if (typingTimer) {
                        clearTimeout(typingTimer);
                    }
                    typingTimer = setTimeout(()=>checkProductCode(product?.productId || ''), doneTypingInterval);
                }}
            />

            <label>
                상품 이름<span id="product-name-message"></span>
            </label>
            <input
                type="text"
                placeholder="PRODUCT NAME"
                id="product-name"
                defaultValue={product?.name || ''}
            />

            <label>
                상품 가격<span id="product-price-message"></span>
            </label>
            <input
                type="text"
                placeholder="PRODUCT PRICE"
                id="product-price"
                defaultValue={product?.price?.toLocaleString() || ''}
                onChange={checkProductPrice}
            />

            <label>
                배송비
                <span id="product-delivery-fee-message"></span>
            </label>
            <input
                type="text"
                placeholder="PRODUCT DELIVERY FEE"
                id="product-delivery-fee"
                defaultValue={product?.deliveryFee?.toLocaleString() || ''}
                onChange={checkDeliveryFee}
            />

            <CategorySelector categoryList={categoryList} product={product} />

            <label>사이즈</label>
            <SizeSelector
                selectSize={product?.size?.replace(',', '').split(',')!}
                sizeList={''}
            />
        </div>
    )
}
