import React, { useCallback, useEffect } from 'react'
import ReactModal from 'react-modal'
import './Modal.css'

interface ModalProps {
    isOpen: boolean
    title: string
    message1: string
    message2: string
    onClose: () => void
    onConfirm: (input: string) => void
    modalMode?: number
    width?: string
    height?: string
    component?: React.ReactNode
    inputValue?: string
    setInputValue?: React.Dispatch<React.SetStateAction<string>>
}

const Modal: React.FC<ModalProps> = ({
    isOpen,
    title,
    message1,
    message2,
    onClose,
    onConfirm,
    modalMode,
    width = '400px',
    height = '200px',
    component,
    inputValue,
    setInputValue,
}) => {
    const modalStyle = {
        content: {
            width: width,
            height: height,
            top: modalMode === 2 ? '55%' : '50%',
            maxWidth: modalMode === 2 ? '700px' : '380px',
        },
    }

    const handleKeyPress = useCallback(
        (e: KeyboardEvent) => {
            if (e.key === 'Enter' && isOpen) {
                if (modalMode === 1 || modalMode === 3) {
                    handleConfirm()
                } else if (modalMode === 0 ) {
                    onClose()
                }
            }
        },
        [isOpen, modalMode, inputValue]
    )

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (setInputValue) {
            setInputValue(e.target.value)
        }
    }

    const handleConfirm = () => {
        onConfirm(inputValue || '')
    }

    React.useEffect(() => {
        // 각 요소에 포커스 이벤트 리스너 추가
        const handleFocus = (event: FocusEvent) => {
            if (event.target instanceof HTMLElement) {
                event.target.blur()
            }
        }

        // Select all focusable elements
        const focusableElements = document.querySelectorAll<HTMLElement>(
            'a, button, textarea, input, select'
        )

        // Attach focus event listener to each focusable element
        focusableElements.forEach((element) => {
            element.addEventListener('focus', handleFocus)
        })

        document.getElementById('prompt-text')?.focus()

        document.body.style.overflow = 'hidden'

        return () => {
            focusableElements.forEach((element) => {
                element.removeEventListener('focus', handleFocus)
            })
        }
    }, [isOpen])

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('keydown', handleKeyPress)

            return () => {
                document.removeEventListener('keydown', handleKeyPress)
            }
        }
    }, [isOpen, handleKeyPress])

    return (
        <ReactModal
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel="Modal"
            className="modal"
            overlayClassName="overlay"
            style={modalStyle}
            shouldCloseOnOverlayClick={modalMode !== 2}
        >
            {modalMode === 2 && (
                <div className="modal-close-button">
                    <i className="icon-cancel" onClick={onClose}></i>
                </div>
            )}
            <div className="modal-content">
                {component ? (
                    <div className="modal-custom-component" >{component}</div>
                ) : (
                    <>
                        <h2>{title}</h2>
                        <p id="main-message" dangerouslySetInnerHTML={{__html :message1}}></p>
                        <p id="sub-message" dangerouslySetInnerHTML={{__html :message2}}></p>
                        {modalMode === 3 && (
                            <input
                                id="prompt-text"
                                type="text"
                                value={inputValue}
                                onChange={handleInputChange}
                            />
                        )}
                    </>
                )}
            </div>
            <div className="modal-button">
                {(modalMode === 1 || modalMode === 3) && (
                    <>
                        <button onClick={handleConfirm}>확인</button>
                        <button onClick={onClose}>취소</button>
                    </>
                )}
                {modalMode === 0 && <button onClick={onClose}>닫기</button>}
            </div>
        </ReactModal>
    )
}

export default Modal
