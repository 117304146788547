import React, { useState, useEffect } from 'react'

import { Board } from '@Interface/Board'

import {
    deleteBoardService,
    getBoardService,
    saveAnswerService,
} from '@Service/user/board/BoardService'
import { ProductListItem } from '@Component/product/ProductList/ProductList'
import { insertBoard } from '@Util/BoardUtils'
import { formatDate, privacyName, stripHTMLTags } from '@Util/StringUtils'
import { SizeSelector } from '@Component/product/ProductInfo/SizeSelector'
import { ImageViewList } from '../../common/Widgets/Image/ImageView'
import './BoardView.css'
import { getModalInstance } from '@Component/common/Widgets/Modal/ModalProvider'
import { ReviewEditor } from '../Edit/ReviewEditor'
import { setWishicon } from '@Util/WishUtils'
import { Editor } from '@Component/common/Widgets/Editor/Editor'
import { UserInfoView } from '@Component/mypage/account/MypageAccount'
import { AnswerDTO } from '@Interface/Answer'

export const ReviewView = (props: { boardId: string }) => {
    const modal = getModalInstance()

    const [fileMap, setFileMap] = useState<Map<number, File>>(new Map())
    const [tempFileMap, setTempFileMap] = useState<Map<number, File>>(new Map())
    const [reviewRating, setReviewRating] = useState(0)
    const [board, setBoard] = useState<Board | undefined>(undefined)
    const [imageUrl, setImageUrl] = useState<string[]>([])
    const fetchBoard = async () => {
        if (props.boardId) {
            const boardDetail = await getBoardService(props.boardId)
            if (boardDetail) {
                setBoard(boardDetail.board)
                setImageUrl(boardDetail.imageUrl)
                setWishicon()
            }
        }
    }

    useEffect(() => {
        fetchBoard()
    }, [props.boardId])

    return (
        <div className="board-view">
            <ProductListItem product={board?.product} />

            <h2>
                상품 리뷰
                <div>
                    {board?.state == -1 && (
                        <>
                            <input
                                type="button"
                                value="수정"
                                onClick={() =>
                                    modal?.popup(
                                        <ReviewEditor
                                            productId={
                                                board.product?.productId!
                                            }
                                            board={board}
                                            imageUrl={imageUrl}
                                        />,
                                        '96vw',
                                        '80vh'
                                    )
                                }
                            />
                            <input
                                type="button"
                                value="삭제"
                                onClick={() =>
                                    modal?.confirm(
                                        () => {
                                            deleteBoardService(board.boardId!)
                                        },
                                        () => {},
                                        '확인',
                                        '삭제 하시겠습니까?'
                                    )
                                }
                            />
                        </>
                    )}
                </div>
            </h2>

            <label>
                한줄평
                <div className="board-info">
                    <span>작성자 : {privacyName(board?.user.userName!)}</span>
                    <span>작성일 : {formatDate(board?.createDate!)}</span>
                </div>
            </label>
            <div className="textarea">{board?.title}</div>

            <label>
                리뷰 내용
                <div>
                    {[...Array(5)].map((_, index) => (
                        <i
                            key={index}
                            className={
                                board?.reviewRating! > index
                                    ? 'icon-star'
                                    : 'icon-star-empty'
                            }
                        ></i>
                    ))}
                </div>
            </label>
            <div
                dangerouslySetInnerHTML={{
                    __html: stripHTMLTags(board?.content || ''),
                }}
                className="textarea content"
            ></div>
            <ImageViewList parentClass="board-image-list" imageUrl={imageUrl} />
            <label>
                답변
                {board?.answer && (
                    <div className="board-info">
                        <span>작성자 : BROSPO</span>
                        <span>
                            작성일 : {formatDate(board?.answer?.createDate)}
                        </span>
                    </div>
                )}
            </label>
            <div
                dangerouslySetInnerHTML={{
                    __html: stripHTMLTags(
                        board?.answer?.content || '답변이 등록되지 않았습니다.'
                    ),
                }}
                className="textarea content"
            ></div>
        </div>
    )
}

export const AdminReviewView = (props: { boardId: string }) => {
    const modal = getModalInstance()

    const [fileMap, setFileMap] = useState<Map<number, File>>(new Map())
    const [tempFileMap, setTempFileMap] = useState<Map<number, File>>(new Map())
    const [reviewRating, setReviewRating] = useState(0)
    const [board, setBoard] = useState<Board | undefined>(undefined)
    const [imageUrl, setImageUrl] = useState<string[]>([])
    const fetchBoard = async () => {
        if (props.boardId) {
            const boardDetail = await getBoardService(props.boardId, true)
            if (boardDetail) {
                setBoard(boardDetail.board)
                setImageUrl(boardDetail.imageUrl)
                setWishicon()
            }
        }
    }

    useEffect(() => {
        fetchBoard()
    }, [props.boardId])

    return (
        <div className="board-view">
            <h2>
                상품 리뷰
                <div>
                    <input
                        type="button"
                        value="답변 저장"
                        onClick={() => {
                            const answerDTO: AnswerDTO = {
                                boardId: board?.boardId!,
                                answerId: board?.answer?.answerId || null,
                                content: $('#summernote').summernote('code'),
                            }
                            saveAnswerService(answerDTO)
                        }}
                    />
                </div>
            </h2>
            <label>유저정보</label>
            <UserInfoView userInfo={board?.user!}/>
            <label>리뷰 상품</label>
            <ProductListItem product={board?.product}/>

            <label>
                한줄평
                <div className="board-info">
                    <span>작성일 : {formatDate(board?.createDate!)}</span>
                </div>
            </label>
            <div className="textarea">{board?.title}</div>

            <label>
                리뷰 내용
                <div>
                    {[...Array(5)].map((_, index) => (
                        <i
                            key={index}
                            className={
                                board?.reviewRating! > index
                                    ? 'icon-star'
                                    : 'icon-star-empty'
                            }
                        ></i>
                    ))}
                </div>
            </label>
            <div
                dangerouslySetInnerHTML={{
                    __html: stripHTMLTags(board?.content || ''),
                }}
                className="textarea content"
            ></div>
            <ImageViewList parentClass="board-image-list" imageUrl={imageUrl}/>
            <label>
                답변 내용
            </label>
            <Editor
                content={stripHTMLTags(board?.answer?.content || '')}
                userSet={true}
                height={300}
            />
        </div>
    )
}
