import axios from 'axios'

import { UserCheck, UserInfo, UserJoin, UserLogin } from '@Interface/User'
import { HttpAlert, HttpSuccess } from '@Interface/HttpCallBack'
import { deleteRequest, getRequest, postRequest } from '@Service/Api'
import { useEffect, useState } from 'react'
import { getModalInstance } from '@Component/common/Widgets/Modal/ModalProvider'

export const CheckDuplicateService = async (
    type: string,
    userForm: UserCheck,
    httpSuccess: HttpSuccess,
    httpAlert: HttpAlert
): Promise<boolean> => {
    try {
        await postRequest(
            `/user/check/${type}`,
            httpSuccess,
            httpAlert,
            userForm,
            {}
        )
        return true
    } catch (e) {
        console.log(e)
        return false
    }
}

export const UserJoinService = async (user: UserJoin) => {
    const modal = getModalInstance()

    const httpSuccess: HttpSuccess = {
        200: () => {
            modal!.alert(
                () => {
                    window.location.href = '/user/login'
                },
                '알림',
                'BROSP 가입을 환영합니다.'
            )
        },
    }
    const httpAlert: HttpAlert = {}
    try {
        await postRequest('/user', httpSuccess, httpAlert, user, {})
    } catch (e) {
        console.log(e)
    }
}

export const UserLoginService = async (userLogin: UserLogin) => {
    const modal = getModalInstance()

    const httpSuccess: HttpSuccess = {
        200: () => {
            modal!.alert(
                () => {
                    localStorage.setItem('userLogin', 'Y')
                    const referrer = localStorage.getItem('referrer')
                    window.location.href = referrer != null ? referrer : '/'
                },
                '알림',
                '환영합니다.'
            )
        },
    }
    const httpAlert: HttpAlert = {
        401: () => {
            modal!.alert(() => {}, '알림', '아이디 또는 비밀번호가 틀립니다.')
        },
    }
    try {
        await postRequest('/token', httpSuccess, httpAlert, userLogin, {})
    } catch (e) {
        console.log(e)
    }
}

let userInfo: UserInfo
export const UserInfoService = async () => {
    const httpSuccess: HttpSuccess = {}
    const httpAlert: HttpAlert = {}
    try {
        if (userInfo && userInfo.userName != '') {
            return userInfo
        }
        userInfo = await getRequest<UserInfo>(
            '/user',
            {},
            httpSuccess,
            httpAlert
        )
        if (userInfo?.userName != '') {
            localStorage.setItem('userLogin', 'Y')
        } else {
            localStorage.setItem('userLogin', 'N')
        }

        return (
            userInfo || {
                userName: '',
                email: '',
                phoneNumber: '',
            }
        )
    } catch (e) {
        localStorage.setItem('userLogin', 'N')
        console.log(e)

        return {
            userName: '',
            email: '',
            phoneNumber: '',
        }
    }
}

export const UserLogoutService = async () => {
    const modal = getModalInstance()

    const httpSuccess: HttpSuccess = {
        200: () => {
            modal!.alert(
                () => {
                    localStorage.setItem('userLogin', 'N')
                    const referrer = localStorage.getItem('referrer')
                    if (
                        referrer?.includes('mypage') ||
                        referrer?.includes('order')
                    ) {
                        window.location.href = '/'
                    } else {
                        window.location.href = referrer != null ? referrer : '/'
                    }
                    userInfo.userName = ''
                },
                '알림',
                '감사합니다.',
                '다음에 더 좋은 상품으로 찾아뵙겠습니다.'
            )
        },
    }
    const httpAlert: HttpAlert = {}
    try {
        await deleteRequest('/token', httpSuccess, httpAlert, {})
    } catch (e) {
        console.log(e)
    }
}
