import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

export const ScrollToTop = () => {
    const location = useLocation()

    const refer = useRef<string>('/')
    useEffect(() => {
        const scrollPosition = localStorage.getItem('scrollPosition')

        if (refer.current == '/' && location.pathname == '/') {
            if (scrollPosition && location.search != '') {
                window.scrollTo(0, JSON.parse(scrollPosition))
            } else {
                window.scrollTo(0, 0)
            }
        } else {
            window.scrollTo(0, 0)
            localStorage.setItem('scrollPosition', '')
        }

        const handleScroll = () => {
            localStorage.setItem(
                'scrollPosition',
                JSON.stringify(window.scrollY)
            )
        }

        window.addEventListener('scroll', handleScroll)
        refer.current = location.pathname

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [location.search, location.pathname])

    return null
}
