import {SizeSelector} from '@Component/product/ProductInfo/SizeSelector'
import React from 'react'
import {ImageView} from '@Component/common/Widgets/Image/ImageView'
import {Product} from '@Interface/Product'

import {SizeCounter} from './SizeCounter'
import {getModalInstance} from '@Component/common/Widgets/Modal/ModalProvider'
import {insertCart} from '@Util/CartUtils'
import {shareLink} from '@Util/ViewUtils'
import {addWish} from '@Util/WishUtils'
import {OrderItem} from "@Interface/Order";
import {insertOrderService} from "@Service/user/order/OrderService";
import {checkUserInfo} from "@Util/UserUtils";

export const ProductInfo = (props: {
    product: Product | undefined
    imageUrl: string[]
}) => {
    const modal = getModalInstance()
    const imageUrl = props.imageUrl
    const product = props.product

    return (
        <div className={'product-header'}>
            <ImageView imageUrl={imageUrl} parentClass={'product-image'}/>
            <div className={'product-info'}>
                <h2 id={'title'}>{product?.name}</h2>
                <p id={'category'}>
                    <div>
                        {product?.category?.primaryName}
                        <i className="icon-right-open"></i>
                        {product?.category?.secondaryName}
                    </div>
                    <div className="product-detail-icon">
                        <i
                            className={` product-wish-check-${product?.productId} icon-heart-empty`}
                            onClick={(e) => {
                                addWish(e, product?.productId!)
                            }}
                        ></i>
                        <i className="icon-upload" onClick={() => shareLink(product?.productId!)}></i>
                    </div>
                </p>
                <hr/>
                <table>
                    <tbody>
                    <tr>
                        <th>상품코드</th>
                        <td id={'code'}>{product?.productCode}</td>
                    </tr>
                    <tr>
                        <th>상품가격</th>
                        <td id={'price'} data-price={product?.price}>
                            {(product?.price || '').toLocaleString()} 원
                        </td>
                    </tr>
                    <tr>
                        <th>배송방법</th>
                        <td>택배</td>
                    </tr>
                    <tr>
                        <th>배송사</th>
                        <td>우체국 택배</td>
                    </tr>
                    <tr>
                        <th>배송가격</th>
                        <td id={'delivery-fee'}>
                            {product?.deliveryFee?.toLocaleString()} 원
                        </td>
                    </tr>
                    </tbody>
                </table>

                <hr/>
                <h4>사이즈 선택</h4>
                <SizeSelector
                    selectSize={[]}
                    sizeList={(product?.size || '').replace(',', '')}
                />
                <hr/>
                <SizeCounter productId={product?.productId!}/>

                <div className={'product-action'}>
                    <input
                        type={'button'}
                        value={'장바구니'}
                        id={'cart-button'}
                        onClick={() => {
                            insertCart(product?.productId!)
                        }}
                    />
                    <input
                        type={'button'}
                        value={'바로구매'}
                        id={'order-button'}
                        onClick={async () => {
                            if (!(await checkUserInfo(undefined,false))) {
                                modal?.confirm(
                                    () => {
                                        window.location.href = '/user/login'
                                    },
                                    () => {},
                                    '알림',
                                    '주문을 위해 로그인이 필요합니다.',
                                    '로그인 하시겠습니까?'
                                )

                            }else {
                                modal?.confirm(() => {
                                    checkOut(product?.productId!)
                                }, () => {
                                }, '확인', '상품을 구매하시겠습니까?')
                            }
                        }}
                    />
                </div>
            </div>
        </div>
    )
}


export const checkOut = async (productId: string) => {
    const modal = getModalInstance()

    const orderList: OrderItem[] = []


    const sizeCountList = document.querySelectorAll(
        `[id^="size-item-"]`
    )

    sizeCountList.forEach((sizeCount) => {
        const size = sizeCount.id.replace(
            `size-item-`,
            ''
        )
        const count = (
            document.getElementById(
                `size-count-${size}`
            ) as HTMLInputElement
        ).value
        const orderItem: OrderItem = {
            product: {
                productId: productId!,
                category: null,
                name: null,
                price: null,
                deliveryFee: null,
                productCode: null,
                productDescription: null,
                size: null,
            },
            size: size,
            count: count,
        }

        orderList.push(orderItem)
    })


    if (sizeCountList.length == 0) {
        modal?.alert(() => {
        }, '알림', '옵션을 선택해주세요')
        return
    }

    await insertOrderService(orderList)
}