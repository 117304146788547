import { Category } from '@Interface/Category'
import React, { useEffect, useState } from 'react'
import { Product } from '@Interface/Product'
import {Editor} from "@Component/common/Widgets/Editor/Editor";

export const CategorySelector = (props: {
    categoryList: { [p: string]: Category[] }
    product: Product | null
}) => {
    const categoryList = props.categoryList
    const [primaryName, setPrimaryName] = useState<string>('')
    const [secondaryName, setSecondaryName] = useState<string>('')

    const PrimaryNameChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectPrimaryName = event.target.value
        setPrimaryName(selectPrimaryName)
        updateSecondaryOptions(selectPrimaryName)
    }

    const SecondaryNameChange = (
        event: React.ChangeEvent<HTMLSelectElement>
    ) => {
        setSecondaryName(event.target.value)
    }

    const updateSecondaryOptions = (primaryName: string) => {

        if(!primaryName){
            return
        }

        let optionsHTML = '<option value="">-2차 카테고리-</option>'

        if (primaryName === '비공개') {
            optionsHTML = '<option value="비공개">비공개</option>'
        } else if (primaryName !== '') {
            const secondaryCategoryList = categoryList[primaryName]
            optionsHTML += secondaryCategoryList
                .map(
                    (category, index) =>
                        `<option key="${index}" value="${category.secondaryName}">
                            ${category.secondaryName}
                        </option>`
                )
                .join('')
        }

        const secondarySelector = document.getElementById(
            'select-secondary-category'
        )
        if (secondarySelector != null) {
            secondarySelector.innerHTML = optionsHTML
        }
    }

    useEffect(() => {
        const product = props.product
        const category = product?.category
        if (category) {
            setPrimaryName(category?.primaryName)
            setSecondaryName(category?.secondaryName)
            updateSecondaryOptions(category?.primaryName || '') // 2차 카테고리 갱신
        }
    }, [props.product])

    return (
        <>
            <label>카테고리</label>

            <select
                id="select-primary-category"
                value={primaryName}
                onChange={PrimaryNameChange}
            >
                <option value={''}>-1차 카테고리-</option>
                {Object.keys(categoryList).map((category, index) => (
                    <option key={index} value={category}>
                        {category}
                    </option>
                ))}
                <option value={'비공개'}>비공개</option>
            </select>

            <select
                id="select-secondary-category"
                value={secondaryName}
                onChange={SecondaryNameChange}
            >
                <option value={''}>-2차 카테고리-</option>
            </select>
        </>
    )
}
