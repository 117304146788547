import { HttpAlert, HttpSuccess } from '@Interface/HttpCallBack'
import { getRequest, postRequest, putRequest } from '@Service/Api'
import {
    Product,
    ProductCheck,
    ProductDetail,
    ProductListResponse,
    ProductSearchDTO,
} from '@Interface/Product'
import { getModalInstance } from '@Component/common/Widgets/Modal/ModalProvider'

export const InsertProductService = async (formData: FormData) => {
    const modal = getModalInstance()

    const httpSuccess: HttpSuccess = {
        200: () => {
            modal!.alert(
                () => {
                    window.location.href = '/admin/product/list'
                },
                '알림',
                '상품이 성공적으로 등록되었습니다.'
            )
        },
    }
    const httpAlert: HttpAlert = {}

    try {
        await postRequest('/admin/product', httpSuccess, httpAlert, formData, {
            headers: {
                'Content-Type': 'multipart/form-data', // 멀티파트 요청의 Content-Type 지정
            },
        })
    } catch (e) {
        console.log(e)
    }
}

export const UpdateProductService = async (formData: FormData) => {
    const modal = getModalInstance()
    const httpSuccess: HttpSuccess = {
        200: () => {
            const updateProductData = formData.get('updateProduct') as
                | string
                | null

            if (updateProductData) {
                const updateProduct: Product = JSON.parse(updateProductData)

                if (updateProduct.productId && updateProduct.productId != '1') {
                    modal!.alert(
                        () => {
                            window.location.reload()
                        },
                        '알림',
                        '상품이 성공적으로 수정되었습니다.'
                    )
                }
            }
        },
    }
    const httpAlert: HttpAlert = {}

    try {
        await putRequest('/admin/product', httpSuccess, httpAlert, formData, {
            headers: {
                'Content-Type': 'multipart/form-data', // 멀티파트 요청의 Content-Type 지정
            },
        })
    } catch (e) {
        console.log(e)
    }
}

export const AdminProductListService = async (
    searchQuery: ProductSearchDTO
) => {
    const modal = getModalInstance()
    try {
        const httpAlert: HttpAlert = {}
        const httpSuccess: HttpSuccess = {
            204: () => {
                modal!.alert(() => {}, '알림', '조회된 상품이 없습니다.')
            },
        }

        const config = {
            params: searchQuery,
        }
        return await getRequest<ProductListResponse>(
            '/admin/product/list',
            httpSuccess,
            httpAlert,
            config
        )
    } catch (e) {
        console.log(e)
    }
}

export const CheckDuplicateService = async (
    type: string,
    productForm: ProductCheck,
    httpSuccess: HttpSuccess,
    httpAlert: HttpAlert
): Promise<boolean> => {
    try {
        await postRequest(
            `/admin/product/check/${type}`,
            httpSuccess,
            httpAlert,
            productForm,
            {}
        )
        return true
    } catch (e) {
        console.log(e)
        return false
    }
}

export const AdminProductService = async (productId: string) => {
    try {
        const httpAlert: HttpAlert = {}
        const httpSuccess: HttpSuccess = {}

        return await getRequest<ProductDetail>(
            '/admin/product/' + productId,
            httpSuccess,
            httpAlert,
            {}
        )
    } catch (e) {
        console.log(e)
    }
}
