import React, { useEffect, useState } from 'react'
import './IndexPage.css'
import MainImage from '@Component/common/View/MainImage/MainImage'
import { ProductListPage } from '@Page/user/product/ProcutListPage/ProductListPage'
import { Category } from '@Interface/Category'
import { useLocation } from 'react-router-dom'

import { getFileMap } from '@Util/imageUtils'
import { MainImageService } from '@Service/user/cmm/CmmService'

import { ReviewList } from '@Component/board/List/ReviewList'

function IndexPage() {
    const categoryList: { [p: string]: Category[] } = {}

    const [fileMap, setFileMap] = useState<Map<number, File>>()

    const fetchFileMap = async () => {
        const imageList = await MainImageService()
        if (imageList) {
            setFileMap(await getFileMap(imageList))
        }
    }

    useEffect(() => {
        fetchFileMap()

        document.title = 'BROSPO'
    }, [])

    return (
        <>
            <MainImage fileMap={fileMap}/>
            <ProductListPage categoryList={categoryList}/>
            <div id={'main-review'}>
                <h3>상품 리뷰</h3>
                <h4>상품 사용 후기입니다.</h4>
                <ReviewList productId={null}/>
            </div>
        </>
    )
}

export default IndexPage
