import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Category } from '@Interface/Category'
import { decodeHTML, stripHTMLTags } from '@Util/StringUtils'

export const SecondaryCategoryBar = (props: {
    categoryList: { [p: string]: Category[] }
    isAdmin: boolean
}) => {
    const [adminUrl, setAdminUrl] = useState<string>('')

    const { primaryCategory, secondaryCategory } = useParams<{
        primaryCategory: string
        secondaryCategory: string
    }>()

    useEffect(() => {
        if (props.isAdmin) {
            setAdminUrl('/admin')
        }
    }, [props.isAdmin])

    if (secondaryCategory) {
        return (
            <React.Fragment>
                <h3>
                    {primaryCategory}
                    {secondaryCategory != 'all' && (
                        <i className="icon-right-open"></i>
                    )}
                    {secondaryCategory != 'all' ? secondaryCategory : ''}
                </h3>
                <div className="product-category-contain">
                    <Link to={adminUrl + '/product/list/' + primaryCategory}>
                        전체보기
                    </Link>
                    {Object.values(props.categoryList).map((categories) =>
                        categories
                            .filter(
                                (category) =>
                                    category.primaryName === primaryCategory
                            )
                            .map((category, primaryIndex) => (
                                <Link
                                    key={primaryIndex}
                                    to={
                                        adminUrl +
                                        '/product/list/' +
                                        category.primaryName +
                                        '/' +
                                        category.secondaryName
                                    }
                                    id={
                                        secondaryCategory ==
                                        category.secondaryName
                                            ? 'current'
                                            : ''
                                    }
                                >
                                    {category.secondaryName}
                                </Link>
                            ))
                    )}
                </div>
            </React.Fragment>
        )
    } else {
        return null
    }
}
