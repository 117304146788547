export const formatDate = (isoString: string): string => {
    const date = new Date(isoString)

    const pad = (num: number) => num.toString().padStart(2, '0')

    const year = date.getFullYear().toString().slice(2) // YY
    const month = pad(date.getMonth() + 1) // MM
    const day = pad(date.getDate()) // DD
    const hours = pad(date.getHours()) // HH
    const minutes = pad(date.getMinutes()) // MM

    return `${year}/${month}/${day} ${hours}:${minutes}`
}

export const privacyName = (userName: string | null): string => {
    if (userName) {
        return `${userName[0]}***`
    } else {
        return ''
    }
}

export const decodeHTML = (html: string) => {
    const txt = document.createElement('textarea')
    txt.innerHTML = html
    return txt.value
}

export const stripHTMLTags = (html: string) => {
    const div = document.createElement('div')
    div.innerHTML = html
    return div.textContent || div.innerText || ''
}

export const getPlainText = (html: string) => {
    const decodedHTML = decodeHTML(html)

    return stripHTMLTags(decodedHTML)
}

export const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = seconds % 60
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`
}

export const filterInputNumber = (
    event: React.KeyboardEvent<HTMLInputElement>
): void => {
    // 허용할 키 코드 (0-9, 백스페이스, 탭, 화살표, Delete, '.' 등)
    const allowedKeys = [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        'Backspace',
        'Tab',
        'ArrowLeft',
        'ArrowRight',
        'Delete',
        '.',
    ]

    if (!allowedKeys.includes(event.key)) {
        event.preventDefault()
    }
}

export const formatDateToString = (date: Date | undefined | null): string => {
    let temp = new Date()
    if (!date) {
        return '-'
    } else {
        temp = new Date(date)
    }
    const year = temp.getFullYear() // 4자리 연도
    const month = (temp.getMonth() + 1).toString().padStart(2, '0') // 월은 0부터 시작하므로 +1
    const day = temp.getDate().toString().padStart(2, '0') // 일
    const hours = temp.getHours().toString().padStart(2, '0') // 시간
    const minutes = temp.getMinutes().toString().padStart(2, '0') // 분
    const seconds = temp.getSeconds().toString().padStart(2, '0') // 초

    return `${year}/${month}/${day} ${hours}:${minutes}`
}
