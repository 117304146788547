import {OrderListItem} from '@Component/product/ProductList/ProductList'
import './OrderInfoForm.css'
import React, {useEffect, useState} from 'react'
import {carrierNameMapping, Order, OrderDTO, TransformedOrder, UpdateOrderDTO} from '@Interface/Order'
import {getOrderService, updateOrderService,} from '@Service/user/order/OrderService'
import {getModalInstance} from '@Component/common/Widgets/Modal/ModalProvider'
import {AddressForm} from '@Component/common/Widgets/Address/AddressForm'
import {Address} from '@Interface/Address'
import {UserInfo} from '@Interface/User'
import {decodeHTML, stripHTMLTags} from '@Util/StringUtils'
import {getAddressListService} from '@Service/user/Address/AddressService'
import {OrderPrice} from '@Component/order/OrderPrice/OrderPrice'
import {checkUserInfo} from '@Util/UserUtils'
import {generateExcel} from '@Util/ExcelUtil'

import {DeliveryTracker} from '@Component/common/Widgets/DeliveryTracker/DeliveryTracker'
import {AdminOrderStatus, OrderStatus} from '../OrderView/OrderView'
import {getAdminOrderService} from "@Service/admin/order/AdminOrderService";

export const OrderInfoForm = (props: { userInfo: UserInfo }) => {
    const modal = getModalInstance()
    const [address, setAddress] = useState<Address>()
    const [order, setOrder] = useState<OrderDTO>()
    const [orderItemList, setOrderItemList] = useState<TransformedOrder[]>()

    const fetchData = async () => {
        setOrder(await getOrderService())

        const addressList = (await getAddressListService()) || []

        addressList.map((address) => {
            if (address.mainAddress == 'Y') {
                setAddress(address)
            }
        })
    }
    useEffect(() => {
        fetchData()
    }, [])
    useEffect(() => {
        if (order?.order) {
            const date = new Date(order?.order.orderDate!)
            const now = new Date()
            const oneHourInMillis = 60 * 60 * 1000
            const timeDifference = now.getTime() - date.getTime()
            if (timeDifference > oneHourInMillis) {
                modal?.alert(
                    () => {
                        window.location.href = '/'
                    },
                    '알림',
                    '주문대기 시간을 초과했습니다.',
                    '다시 주문해 주시기 바랍니다.'
                )
            }

        }
    }, [order])

    useEffect(() => {
        if (address) {
            const updateOrder: UpdateOrderDTO = {
                orderCode: order?.order?.orderCode!,
                address: address?.address,
                recipientName: address?.recipientName,
                recipientContact: address?.recipientContact,
                deliveryRequest: address?.deliveryRequest,
            }

            updateOrderService(updateOrder)
        }
    }, [address])

    useEffect(() => {
        if (!order?.orderItemList) {
            return
        }

        const transformedCartList: TransformedOrder[] = []

        order?.orderItemList.forEach((orderItem) => {
            if (
                !transformedCartList.some(
                    (item) =>
                        item.product.productId === orderItem.product.productId
                )
            ) {
                transformedCartList.push({
                    product: {
                        productId: orderItem.product.productId,
                        category: orderItem.product.category,
                        name: orderItem.product.name,
                        price: orderItem.product.price,
                        deliveryFee: orderItem.product.deliveryFee,
                        productCode: orderItem.product.productCode,
                        productDescription: null,
                        size: null,
                    },
                    sizes: [],
                })
            }

            transformedCartList
                .find(
                    (item) =>
                        item.product.productId === orderItem.product.productId
                )
                ?.sizes.push({
                size: orderItem.size,
                count: orderItem.count,
            })
        })

        setOrderItemList(transformedCartList)
    }, [order])

    if(!order?.order){
        return null;
    }

    return (
        <>
            <div className="order-info-form">
                <div className="recipient-view">
                    <h3>
                        <p>
                            배송정보{' '}
                            {address && ` : ${address.addressNickName}`}
                        </p>
                        <input
                            className="bg-white-button"
                            type="button"
                            value={'배송 정보 입력'}
                            onClick={() => {
                                modal?.popup(
                                    <AddressForm
                                        userInfo={props.userInfo}
                                        setAddress={setAddress}
                                    />,
                                    '96vw',
                                    'auto'
                                )
                            }}
                        />
                    </h3>
                    {address ? (
                        <>
                            <div>
                                <p>수령인</p>{' '}
                                <span>{address?.recipientName}</span>
                            </div>
                            <div>
                                <p> 전화번호</p>
                                <span>{address?.recipientContact}</span>
                            </div>
                            <div>
                                <p>주소</p>
                                <span>
                                    {decodeHTML(address?.address || '').replace(
                                        '///',
                                        ' '
                                    )}
                                </span>
                            </div>

                            <div>
                                <p>배송요청사항</p>{' '}
                                <span>{address?.deliveryRequest}</span>
                            </div>
                        </>
                    ) : (
                        <div>배송지를 선택해주세요</div>
                    )}
                </div>

                <div className="order-item-info">
                    <h3>
                        <p>주문목록</p>
                    </h3>
                    {orderItemList?.map((orderItem, index) => (
                        <div className="order-product-item" key={index}>
                            <OrderListItem product={orderItem.product!}/>
                            <div className="order-product-count">
                                <div>수량 :</div>
                                {orderItem.sizes?.map((size, index) => (
                                    <div key={index}>
                                        {size.size.toUpperCase()} / {size.count}
                                        {index != orderItem.sizes.length - 1 &&
                                            ' ,'}
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <OrderPrice
                orderItemList={orderItemList || []}
                userInfo={props.userInfo}
                orderCode={order?.order.orderCode || ''}
                orderName={order?.order.orderName || ''}
                address={address}
            />
        </>
    )
}

export const OrderInfo = (props: { orderDTO: OrderDTO | undefined }) => {
    const modal = getModalInstance()
    const [orderItemList, setOrderItemList] = useState<TransformedOrder[]>()
    const [order, setOrder] = useState<Order>()

    const [userInfo, setUserInfo] = useState<UserInfo>()

    const [address, setAddress] = useState<Address>()

    useEffect(() => {
        if (!props.orderDTO?.orderItemList) {
            return
        }

        const transformedCartList: TransformedOrder[] = []

        props.orderDTO?.orderItemList.forEach((orderItem) => {
            if (
                !transformedCartList.some(
                    (item) =>
                        item.product.productId === orderItem.product.productId
                )
            ) {
                transformedCartList.push({
                    product: {
                        productId: orderItem.product.productId,
                        category: orderItem.product.category,
                        name: orderItem.product.name,
                        price: orderItem.product.price,
                        deliveryFee: orderItem.product.deliveryFee,
                        productCode: orderItem.product.productCode,
                        productDescription: null,
                        size: null,
                    },
                    sizes: [],
                })
            }

            transformedCartList
                .find(
                    (item) =>
                        item.product.productId === orderItem.product.productId
                )
                ?.sizes.push({
                size: orderItem.size,
                count: orderItem.count,
            })
        })
        setOrder(props.orderDTO.order)
        setOrderItemList(transformedCartList)
        checkUserInfo(setUserInfo)
    }, [props.orderDTO])

    const updateAddress = async () => {
        if (address) {
            const orderDTO: UpdateOrderDTO = {
                orderCode: order?.orderCode!,
                address: address?.address!,
                recipientName: address?.recipientName!,
                recipientContact: address?.recipientContact!,
                deliveryRequest: address?.deliveryRequest!,
            }

            if (await updateOrderService(orderDTO)) {
                modal?.alert(() => {
                }, '알림', '배송지가 수정되었습니다.')
            } else {
                modal?.alert(() => {
                    setAddress(undefined)
                }, '알림', '수정에 실패했습니다.')
            }
        }
    }
    useEffect(() => {
        updateAddress()
    }, [address]);
    return (
        <div className="order-info-form">
            <h2>
                {order?.orderState} <span>{order?.orderCode}</span>
            </h2>

            {(order?.orderState?.includes('취소') || order?.orderState?.includes('교환') || order?.orderState?.includes('반품')) && (
                <div className="request-info">
                    <h3>
                        <p>{order?.orderState?.slice(0, 2)}사유</p>
                        <div>
                            <input
                                className="bg-white-button"
                                type="button"
                                value={'요청수정'}
                                onClick={() => {
                                    if (order.orderState?.includes('요청')) {
                                    } else {
                                        modal?.alert(() => {
                                        }, '알림', '요청수정은 요청상태일때만 가능합니다.')
                                    }
                                }}
                            />
                            <input
                                className="bg-white-button"
                                type="button"
                                value={'요청취소'}
                                onClick={() => {
                                    if (order.orderState?.includes('요청')) {
                                    } else {
                                        modal?.alert(() => {
                                        }, '알림', '요청취소는 요청상태일때만 가능합니다.')
                                    }
                                }}
                            />
                        </div>
                    </h3>

                    <div className="order-product-item">
                        <label>요청 사유</label>
                        <p>{stripHTMLTags(order?.board?.content ||'')}</p>

                        <label>답변</label>
                        <p>{stripHTMLTags(order?.board?.answer ? order.board.answer.content : '아직 답변이 달리지 않았습니다.')}</p>
                    </div>
                </div>
            )}
            <div className="order-item-info">
                <h3>
                    <p>주문목록</p>

                    {!order?.board && (<input
                        className="bg-white-button"
                        type="button"
                        value={(order?.orderState == '입금대기' || order?.orderState == '결제완료') ? '주문 취소' : '교환/반품 신청'}
                        onClick={() => {
                            if ((order?.orderState == '입금대기' || order?.orderState == '결제완료')) {
                                modal?.popup(<OrderStatus orderDTO={props.orderDTO} cancelYn={true}/>,undefined,undefined,async () => {
                                    const orderDTO =   await getOrderService(order.orderCode)
                                    setOrder(orderDTO?.order)
                                })
                            } else if ((order?.orderState == '배송완료' || order?.orderState == '교환반려' || order?.orderState == '취소반려' || order?.orderState == '반품반려')) {
                                modal?.popup(<OrderStatus orderDTO={props.orderDTO}/>,undefined,undefined,async () => {
                                  const orderDTO =   await getOrderService(order.orderCode)
                                     setOrder(orderDTO?.order)
                                })
                            } else {
                                modal?.alert(() => {
                                }, '알림', '교환/반품 신청은 배송완료 또는 반려시 가능합니다.')
                            }
                        }}
                    />)}

                </h3>
                {orderItemList?.map((orderItem, index) => (
                    <div
                        className="order-product-item"
                        style={{cursor: 'pointer'}}
                        key={index}
                        onClick={() => {
                            modal?.confirm(
                                () => {
                                },
                                () => {
                                },
                                '확인',
                                '상품을 확인하시겠습니까?',
                                '페이지로 이동합니다.'
                            )
                        }}
                    >
                        <OrderListItem product={orderItem.product!}/>
                        <div className="order-product-count">
                            <div>수량 :</div>
                            {orderItem.sizes?.map((size, index) => (
                                <div key={index}>
                                    {size.size.toUpperCase()} / {size.count}
                                    {index != orderItem.sizes.length - 1 &&
                                        ' ,'}
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
            <div className="recipient-view">
                <h3>
                    <p>배송정보</p>
                    <input
                        className="bg-white-button"
                        type="button"
                        value={'배송 정보 수정'}
                        onClick={() => {
                            if (order?.orderState == '입금대기' || order?.orderState == '결제완료') {
                                modal?.popup(
                                    <AddressForm
                                        userInfo={userInfo}
                                        setAddress={setAddress}
                                    />)
                            } else {
                                modal?.alert(() => {
                                }, '알림', '준비중 전단계에서만 배송지 수정이 가능합니다.', '변경을 원하시면 문의 부탁드립니다.')
                            }
                        }}
                    />
                </h3>

                <div>
                    <p>수령인</p>{' '}
                    <span>{address?.recipientName || props.orderDTO?.order?.recipientName}</span>
                </div>
                <div>
                    <p> 전화번호</p>
                    <span>{address?.recipientContact || props.orderDTO?.order?.recipientContact}</span>
                </div>
                <div>
                    <p>주소</p>
                    <span>
                        {decodeHTML(
                            address?.address || props.orderDTO?.order?.address || ''
                        ).replace('///', ' ')}
                    </span>
                </div>
                <div>
                    <p>배송요청사항</p>{' '}
                    <span>{address?.deliveryRequest || props.orderDTO?.order?.deliveryRequest}</span>
                </div>
                <div>
                    <p>배송번호</p>
                    <span
                        className={order?.deliveryCode ? 'link' : ''}
                        onClick={() => {
                            if (order?.deliveryCode) {
                                modal?.popup(
                                    <DeliveryTracker
                                        deliveryCode={order.deliveryCode!}
                                    />
                                )
                            }
                        }}
                    >
                        {order?.deliveryCode ? `${carrierNameMapping[order.deliveryCode.split('///')[0]]} ${order.deliveryCode.split('///')[1]}` : '배송준비중'}
                    </span>
                </div>
            </div>
            <div className={'order-payment-info'}>
                <h3>결제정보</h3>
                <div>
                    <p>결제수단</p>
                    <span>{order?.paymentType}</span>
                </div>
                {order?.paymentType == '계좌이체' && (
                    <>
                        <div>
                            <p>입금계좌안내</p>
                            <span>신한 110-454-160504 손영욱</span>
                        </div>
                        <div>
                            <p>송금계좌</p>
                            <span>
                                {order?.accountHolder} {order?.accountNumber}
                            </span>
                        </div>
                    </>
                )}

                <div>
                    <p>결제금액</p>
                    <span>
                        {' '}
                        {parseInt(
                            order?.paymentAmount || ''
                        ).toLocaleString()}{' '}
                        원
                    </span>
                </div>
            </div>
        </div>
    )
}

export const AdminOrderInfo = (props: { orderDTO: OrderDTO | undefined }) => {
    const modal = getModalInstance()
    const [orderItemList, setOrderItemList] = useState<TransformedOrder[]>()
    const [order, setOrder] = useState<Order>()
    const [orderDTO, setOrderDTO] = useState<OrderDTO>()
    const [userInfo, setUserInfo] = useState<UserInfo>()

    useEffect(() => {
        if (!props.orderDTO?.orderItemList) {
            return
        }

        const transformedCartList: TransformedOrder[] = []

        props.orderDTO?.orderItemList.forEach((orderItem) => {
            if (
                !transformedCartList.some(
                    (item) =>
                        item.product.productId === orderItem.product.productId
                )
            ) {
                transformedCartList.push({
                    product: {
                        productId: orderItem.product.productId,
                        category: orderItem.product.category,
                        name: orderItem.product.name,
                        price: orderItem.product.price,
                        deliveryFee: orderItem.product.deliveryFee,
                        productCode: orderItem.product.productCode,
                        productDescription: null,
                        size: null,
                    },
                    sizes: [],
                })
            }

            transformedCartList
                .find(
                    (item) =>
                        item.product.productId === orderItem.product.productId
                )
                ?.sizes.push({
                size: orderItem.size,
                count: orderItem.count,
            })
        })
        setOrderDTO(props.orderDTO)
        setOrder(props.orderDTO.order)
        setOrderItemList(transformedCartList)
        checkUserInfo(setUserInfo)
        console.log(props.orderDTO)
    }, [props.orderDTO])

    function exportExcel(): void {
        let data = [
            [
                'h_주문번호',
                'h_수령인',
                'h_수령인 전화번호',
                'h_배송요청사항',
                'h_주소',
            ],
            [
                order?.orderCode,
                order?.recipientName,
                order?.recipientContact,
                order?.deliveryRequest,
                decodeHTML(order?.address || '').replace('///', ' '),
            ],
            ['', '', '', '', ''],
            [
                'h_상품명',
                'h_상품코드',
                'h_사이즈',
                'h_개수',
                'h_상품페이지 주소',
            ],
        ]

        orderItemList?.forEach((orderItem) => {
            orderItem.sizes.forEach((size, index) => {
                const temp: string[] = [
                    index == 0 ? orderItem.product.name! : '',
                    index == 0 ? orderItem.product.productCode! : '',
                    size.size,
                    size.count,
                    index == 0
                        ? 'https://brospo.co.kr/order/product/' +
                        orderItem.product.productId
                        : '',
                ]
                data.push(temp)
            })
        })

        generateExcel(data, '주문정보', order?.orderCode + ' 주문정보.xlsx')
    }

    return (
        <div className="order-info-form">
            <h2>
                주문 상태 : {order?.orderState} <span>{order?.orderCode}</span>
            </h2>


            <div className="order-item-info">
                <h3>
                    <p>주문목록</p>
                    <div>
                        <input
                            className="bg-white-button"
                            type="button"
                            value={'엑셀 다운'}
                            onClick={exportExcel}
                        />
                        <input
                            className="bg-white-button"
                            type="button"
                            value={'주문상태 변경'}
                            onClick={() => {
                                modal?.popup(
                                    <AdminOrderStatus
                                        orderDTO={orderDTO}
                                    />,undefined,undefined,async () => {
                                        const orderDTO = await getAdminOrderService(order?.orderCode)
                                        setOrderDTO(orderDTO)
                                        setOrder(orderDTO?.order)
                                    }
                                )
                            }}
                        />
                    </div>
                </h3>
                {orderItemList?.map((orderItem, index) => (
                    <div
                        className="order-product-item"
                        style={{cursor: 'pointer'}}
                        key={index}
                        onClick={() => {
                            modal?.confirm(
                                () => {
                                },
                                () => {
                                },
                                '확인',
                                '상품을 확인하시겠습니까?',
                                '페이지로 이동합니다.'
                            )
                        }}
                    >
                        <OrderListItem product={orderItem.product!}/>
                        <div className="order-product-count">
                            <div>수량 :</div>
                            {orderItem.sizes?.map((size, index) => (
                                <div key={index}>
                                    {size.size.toUpperCase()} / {size.count}
                                    {index != orderItem.sizes.length - 1 &&
                                        ' ,'}
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>

            <div className="recipient-view">
                <h3>
                    <p>배송정보</p>
                </h3>

                <div>
                    <p>수령인</p>{' '}
                    <span>{props.orderDTO?.order?.recipientName}</span>
                </div>
                <div>
                    <p> 전화번호</p>
                    <span>{props.orderDTO?.order?.recipientContact}</span>
                </div>
                <div>
                    <p>주소</p>
                    <span>
                        {decodeHTML(
                            props.orderDTO?.order?.address || ''
                        ).replace('///', ' ')}
                    </span>
                </div>
                <div>
                    <p>배송요청사항</p>{' '}
                    <span>{props.orderDTO?.order?.deliveryRequest}</span>
                </div>
                <div>
                    <p>배송번호</p>
                    <span
                        className={order?.deliveryCode ? 'link' : ''}
                        onClick={() => {
                            if (order?.deliveryCode) {
                                modal?.popup(
                                    <DeliveryTracker
                                        deliveryCode={order.deliveryCode}
                                    />
                                )
                            }
                        }}
                    >
                        {order?.deliveryCode ? `${carrierNameMapping[order.deliveryCode.split('///')[0]]} ${order.deliveryCode.split('///')[1]}` : '배송준비중'}

                    </span>
                </div>
            </div>
            <div className={'order-payment-info'}>
                <h3>결제정보</h3>
                <div>
                    <p>결제수단</p>
                    <span>{order?.paymentType}</span>
                </div>
                {order?.paymentType == '계좌이체' && (
                    <>
                        <div>
                            <p>입금계좌안내</p>
                            <span>신한 110-454-160504 손영욱</span>
                        </div>
                        <div>
                            <p>송금계좌</p>
                            <span>
                                {order?.accountHolder} {order?.accountNumber}
                            </span>
                        </div>
                    </>
                )}

                <div>
                    <p>결제금액</p>
                    <span>
                        {' '}
                        {parseInt(
                            order?.paymentAmount || ''
                        ).toLocaleString()}{' '}
                        원
                    </span>
                </div>
            </div>
            {(order?.orderState?.includes('취소') || order?.orderState?.includes('교환') || order?.orderState?.includes('반품')) && (
                <div className="request-info">
                    <h3>
                        <p>{order?.orderState?.slice(0, 2)}사유</p>
                    </h3>

                    <div className="order-product-item">
                        <label>요청 사유</label>
                        <p>{stripHTMLTags(order?.board?.content!)}</p>

                        <label>답변</label>
                        <p>{stripHTMLTags(order?.board?.answer ? order.board.answer.content : '아직 답변이 달리지 않았습니다.')}</p>
                    </div>
                </div>
            )}
        </div>
    )
}
