import { useSearchParams } from 'react-router-dom'

import '../UserForm.css'
import {
    checkEmail,
    checkId,
    checkPN,
    checkPwd,
    createAuthToken,
    userJoin,
} from '@Util/UserUtils'
import { Agreement } from '@Component/common/View/Document/Agreement/Agreement'
import { Privacy } from '@Component/common/View/Document/Privacy/Privacy'

export const UserJoinPage = (): JSX.Element => {
    let typingTimer: ReturnType<typeof setTimeout> | null
    const doneTypingInterval = 150 // 타이핑이 멈춘 후 요청을 보내기까지의 대기 시간 (밀리초)

    return (
        <div className="user-form">
            <h1>
                BROSPO<span>회원가입</span>
            </h1>
            <label htmlFor="id-form">
                <p>
                    아이디<span id="id-message"></span>
                </p>
            </label>
            <input
                type="text"
                id="id-form"
                placeholder="ID"
                onInput={() => {
                    if (typingTimer) {
                        clearTimeout(typingTimer)
                    }
                    typingTimer = setTimeout(checkId, doneTypingInterval)
                }}
            />
            <br />
            <label htmlFor="pwd-form">
                <p>
                    비밀번호<span id="pwd-message"></span>
                </p>
            </label>
            <input
                type="password"
                id="pwd-form"
                placeholder="PASSWORD"
                onInput={() => {
                    if (typingTimer) {
                        clearTimeout(typingTimer)
                    }
                    typingTimer = setTimeout(checkPwd, doneTypingInterval)
                }}
            />
            <label htmlFor="pwd-re-enter-form">비밀번호 재입력</label>
            <input
                type="password"
                id="pwd-re-enter-form"
                placeholder="PASSWORD RE ENTER"
                onInput={() => {
                    if (typingTimer) {
                        clearTimeout(typingTimer)
                    }
                    typingTimer = setTimeout(checkPwd, doneTypingInterval)
                }}
            />
            <br />
            <label htmlFor="user-name-form">이름</label>
            <input type="text" id="user-name-form" placeholder="NAME" />
            <br />
            <label htmlFor="email-form">
                <p>
                    이메일<span id="email-message"></span>
                </p>
            </label>
            <input
                type="text"
                id="email-form"
                placeholder="E-MAIL"
                onInput={() => {
                    if (typingTimer) {
                        clearTimeout(typingTimer)
                    }
                    typingTimer = setTimeout(checkEmail, doneTypingInterval)
                }}
            />
            <br />
            <label htmlFor="phone-number-form">
                <p>
                    전화번호<span id="phone-number-message"></span>
                </p>
            </label>
            <input
                type="text"
                id="phone-number-form"
                placeholder="PHONE NUMBER"
                onInput={() => {
                    if (typingTimer) {
                        clearTimeout(typingTimer)
                    }
                    typingTimer = setTimeout(checkPN, doneTypingInterval)
                }}
            />

            <input type="checkbox" id="agreement-yn" className="checkbox" />
            <label htmlFor="agreement-yn" className="checkbox-label">
                이용약관 동의
            </label>
            <input type="checkbox" id="privacy-yn" className="checkbox" />
            <label htmlFor="privacy-yn" className="checkbox-label">
                개인정보처리방침 동의
            </label>
            <br />
            <Agreement />
            <Privacy />
            <br />
            <label htmlFor="auth-token-form">
                <p>
                    인증번호 입력<span id="auth-token-message"></span>
                </p>
                <input
                    className="bg-white-button"
                    type="button"
                    id="auth-token-button"
                    value="인증번호받기"
                    onClick={createAuthToken}
                />
            </label>
            <input type="text" id="auth-token-form" placeholder="AUTH TOKEN" />

            <input type="button" value="회원가입" onClick={userJoin} />
        </div>
    )
}
