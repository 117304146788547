import React, { useEffect } from 'react'
import './AboutPage.css'
import { About } from '@Component/common/View/Document/About/About'
function AboutPage() {
    useEffect(() => {
        document.title = 'BROSPO 소개'
    }, [])
    return (
        <div className={'about-page'}>
            <h2 className="page-title">회사 소개</h2>
            <About />
        </div>
    )
}

export default AboutPage
