import React, { useEffect, useState } from 'react'
import './DashboardBuilder.css' // CSS 파일 import

export interface Item {
    id: number
    x: number // 칸의 크기를 small, medium, large로 정의
    y: number
    component: React.ReactNode
}

const DashboardBuilder = (props: { items: Item[] }) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)

    const [items, setItems] = useState<Item[]>([])
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth)
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    useEffect(() => {
        setItems(props.items)
    }, [props])

    const calculateXSize = (x: number): number => {
        if (windowWidth >= 1024) {
            return x // 1024px 이상일 때는 모두 1칸
        } else if (windowWidth >= 768) {
            return x === 4 ? 2 : 1 // 768px 이상 1024px 미만일 때는 large는 2칸, 나머지는 1칸
        } else {
            return 1 // 768px 미만일 때는 모두 1칸
        }
    }

    return (
        <div className="dashboard-builder">
            {items.map((item) => (
                <div
                    key={item.id}
                    className="dashboard-item"
                    style={{
                        gridColumn: `span ${calculateXSize(item.x)}`,
                        gridRow: `span ${item.y}`,
                        maxHeight :`${item.y * 100}px`
                    }}
                >
                    {item.component}
                </div>
            ))}
        </div>
    )
}

export default DashboardBuilder
