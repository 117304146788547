import React, { InputHTMLAttributes, useEffect, useState } from 'react'

import { checkProductCode, checkProductName } from '@Util/ProductUtils'
import ImageDrop from '@Component/common/Widgets/Image/ImageDrop'
import { Editor } from '@Component/common/Widgets/Editor/Editor'
import { insertBoard, updateBoard } from '@Util/BoardUtils'
import './BoardEditor.css'
import { getModalInstance } from '@Component/common/Widgets/Modal/ModalProvider'
import { Board } from '@Interface/Board'
import { getFileMap } from '@Util/imageUtils'
import { stripHTMLTags } from '@Util/StringUtils'
export const NoticeEditor = (props: { board?: Board }) => {
    const board = props.board

    const [fileMap, setFileMap] = useState<Map<number, File>>(new Map())

    const [tempFileMap, setTempFileMap] = useState<Map<number, File>>(new Map())

    const [content, setContent] = useState<string>('')

    const setUpdateForm = async () => {
        if (board?.state == -2) {
            ;(
                document.getElementById('hidden-board') as HTMLInputElement
            ).checked = true
        }
    }

    useEffect(() => {
        setUpdateForm()
    }, [])

    return (
        <form className={'board-editor'} id="board-form">
            <h2>
                공지 작성
                <div>
                    {board ? (
                        <input
                            type={'button'}
                            value={'수정'}
                            onClick={() => updateBoard(fileMap,true)}
                        />
                    ) : (
                        <input
                            type={'button'}
                            value={'저장'}
                            onClick={() => insertBoard(fileMap,true)}
                        />
                    )}
                </div>
            </h2>

            <label htmlFor="board-title">
                공지 제목
                <div>
                    <input
                        type="checkbox"
                        id="hidden-board"
                        className="checkbox"
                    />
                </div>
            </label>
            <input
                type="text"
                placeholder="INQUIRY TITLE"
                id="board-title"
                defaultValue={board?.title || ''}
            />
            <input type="hidden" id="board-category" value={0} />
            <input type="hidden" id="board-id" value={board?.boardId || ''} />
            <input type="hidden" id="product-id" value={''} />
            <Editor
                content={stripHTMLTags(board?.content || '')}
                userSet={true}
                height={300}
            />
        </form>
    )
}
