import { getModalInstance } from '@Component/common/Widgets/Modal/ModalProvider'
import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import * as PortOne from '@portone/browser-sdk/v2'
import { orderWebHookService } from '@Service/user/order/OrderService'

export const PaymentRedirectPage = () => {
    const navigate = useNavigate()
    const modal = getModalInstance()
    const location = useLocation()
    const query = new URLSearchParams(location.search)

    const paymentId = query.get('paymentId')
    const code = query.get('code')
    const message = query.get('message')
    const transactionType = query.get('transactionType')
    const txId = query.get('txId')

    useEffect(() => {
        if (code == 'FAILURE_TYPE_PG') {
            modal?.alert(
                () => {
                   window.location.href = '/order/checkout'
                },
                '알림',
                '결제가 취소되었습니다.',
                '주문을 다시 진행해주세요.'
            )
        }else{
            const response : PortOne.PaymentResponse = {
                transactionType: transactionType as typeof PortOne.Entity.TransactionType.PAYMENT,
                paymentId: paymentId as string,
                code: code as string,
                message: message as string,
                txId: txId as string
            };

            console.log(response)
            orderWebHookService(response)
        }
    }, [query])

    return (
        <div>
            <h1  id={"not-found"}>잠시 기다려주세요.</h1>
        </div>
    )
}
