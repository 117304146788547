import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import '../UserForm.css'
import errorImage from '@Image/view/black.png'
import { onErrorImg } from '@Util/imageUtils'
import kakaoApiImage from '@Image/Api/kakao_login_large_wide.png'
import { UserLoginService } from '@Service/user/account/UserService'
import { kakaoLogin, userLogin } from '@Util/UserUtils'

export const UserLoginPage = (): JSX.Element => {
    const userIdRef = useRef<HTMLInputElement>(null)
    const userPwdRef = useRef<HTMLInputElement>(null)

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            userLogin()
        }
    }

    useEffect(() => {
        const userId = userIdRef.current
        const userPwd = userPwdRef.current

        if (userId && userPwd) {
            userId.addEventListener('keydown', handleKeyPress as any)
            userPwd.addEventListener('keydown', handleKeyPress as any)

            return () => {
                userId.removeEventListener('keydown', handleKeyPress as any)
                userPwd.removeEventListener('keydown', handleKeyPress as any)
            }
        }
    }, [])

    return (
        <div className="user-form user-login">
            <h1>
                BROSPO<span>로그인</span>
            </h1>
            <input type="text" id="user-id" placeholder="ID" ref={userIdRef} />{' '}
            <input
                type="password"
                id="user-pwd"
                placeholder="PASSWORD"
                ref={userPwdRef}
            />{' '}
            <input type="button" value="로그인" onClick={userLogin} />
            <div className="kakao-login">
                <img
                    src={kakaoApiImage}
                    onError={(e) => onErrorImg(e)}
                    alt="kakao 로그인"
                    onClick={kakaoLogin}
                />
            </div>
            <div className="user-function">
                <Link to={'/user/join'}>회원가입</Link>
                <Link to={'/user/find'}>아이디/비밀번호 찾기</Link>
            </div>
        </div>
    )
}
