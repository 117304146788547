import { HttpAlert, HttpSuccess } from '@Interface/HttpCallBack'
import { getRequest, postRequest } from '@Service/Api'
import { Category } from '@Interface/Category'
import { useEffect, useState } from 'react'
import { getModalInstance } from '@Component/common/Widgets/Modal/ModalProvider'
import { AddressDTO, SearchAddressDTO } from '@Interface/Address'

export const MainImageService = async () => {
    try {
        const httpAlert: HttpAlert = {}
        const httpSuccess: HttpSuccess = {}

        return await getRequest<string[]>(
            '/main-image',
            httpSuccess,
            httpAlert,
            {}
        )
    } catch (e) {
        console.log(e)
    }
}

export const updateMainImage = async (formData: FormData) => {
    const modal = getModalInstance()
    const httpSuccess: HttpSuccess = {
        200: () => {
            modal!.alert(
                () => {
                    const referrer = localStorage.getItem('referrer')
                    window.location.href = referrer != null ? referrer : '/'
                },
                '알림',
                '메인 이미지가 성공적으로 수정되었습니다.'
            )
        },
    }
    const httpAlert: HttpAlert = {}

    try {
        await postRequest(
            '/admin/main-image',
            httpSuccess,
            httpAlert,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data', // 멀티파트 요청의 Content-Type 지정
                },
            }
        )
    } catch (e) {
        console.log(e)
    }
}

export const CategoryListService = (): { [key: string]: Category[] } => {
    const [categoryList, setCategoryList] = useState<Category[]>([])

    const httpAlert: HttpAlert = {
        200: () => {
            // 401 상태 코드에 대한 처리
        },
    }

    const httpSuccess: HttpSuccess = {}

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getRequest<Category[]>(
                    '/product/category',
                    httpAlert,
                    httpSuccess,
                    {}
                )
                if (data) {
                    const sortedCategoryList = data.sort((a, b) =>
                        a.primaryName.localeCompare(b.primaryName)
                    )
                    setCategoryList(sortedCategoryList)
                }
            } catch (error) {
                console.error('에러 발생:', error)
            }
        }
        fetchData()
    }, [])

    const groupedCategories: { [key: string]: Category[] } = {}

    categoryList.forEach((category) => {
        if (!groupedCategories[category.primaryName]) {
            groupedCategories[category.primaryName] = []
        }
        groupedCategories[category.primaryName].push(category)
    })

    // 카테고리별로 요소를 정렬합니다
    Object.keys(groupedCategories).forEach(key => {
        groupedCategories[key].sort((a, b) => a.menuOrderSd! - b.menuOrderSd!);
    });

    // JSX를 사용하지 않고 단순한 정보를 반환
    return groupedCategories
}

export const createAuthTokenService = async (formData: FormData) => {
    try {
        const modal = getModalInstance()
        const httpAlert: HttpAlert = {}
        const httpSuccess: HttpSuccess = {
            200: () => {
                modal!.alert(
                    () => {},
                    '알림',
                    '인증번호가 발급되었습니다.',
                    '5분내에 입력해주세요.'
                )
            },
        }

        return await postRequest(
            '/auth-token',
            httpSuccess,
            httpAlert,
            formData,
            {}
        )
    } catch (e) {
        console.log(e)
    }
}

export const searchAddressService = async (search: SearchAddressDTO) => {
    try {
        const modal = getModalInstance()
        const httpAlert: HttpAlert = {
        }
        const httpSuccess: HttpSuccess = {}

        return await getRequest<AddressDTO>(
            '/address',
            httpSuccess,
            httpAlert,
            {
                params: {
                    countPerPage: 10,
                    currentPage: search.currentPage,
                    keyword: search.keyword,
                },
            }
        )
    } catch (e) {
        console.log(e)
    }
}
