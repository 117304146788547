import { BoardPageBar } from '@Component/common/Widgets/PageBar/PageBar'
import React, { useEffect, useState } from 'react'
import './BoardList.css'
import { InquiryEditor } from '@Component/board/Edit/InquiryEditor'
import { Board, BoardSearchDTO } from '@Interface/Board'
import { getBoardListService } from '@Service/user/board/BoardService'
import { formatDate, privacyName } from '@Util/StringUtils'

import { fetchBoardList } from '@Util/BoardUtils'
import { AdminInquiryView, InquiryView } from '../View/InquiryView'
import { getModalInstance } from '@Component/common/Widgets/Modal/ModalProvider'

export const InquiryList = (props: {
    productId: string | null
    userSearch?:boolean
    isAdmin?: boolean
}) => {
    const modal = getModalInstance()
    const [page, setPage] = useState<number>(1)
    const [changePage, setChangePage] = useState<number>(1)
    const [totalPage, setTotalPage] = useState<number>(1)
    const [totalCount, setTotalCount] = useState<number>(0)

    const [boardList, setBoardList] = useState<Board[]>([])

    useEffect(() => {
        var searchQuery: BoardSearchDTO = {
            productId: props.productId,
            category: '2',
            page: page,
            answer: window.location.href.includes('unprocessed') ? 'Y' : 'N',
            isAdmin: props.isAdmin,
            userSearch: props.userSearch?'Y':'N'
        }
        fetchBoardList(searchQuery, setBoardList, setTotalPage, setTotalCount)
        if (changePage != page) {
            const reviewElement = document.querySelector('[id$="inquiry"]')
            setChangePage(page)
            if (reviewElement) {
                reviewElement.scrollIntoView({
                    block: 'start',
                    behavior: 'smooth',
                })
            }
        }
    }, [page])

    const viewDetail = (board: Board) => {
        if (board.state == 2 && !props.isAdmin) {
            modal?.alert(() => {}, '알림', '비공개글 입니다.')
            return
        }

        if (props.isAdmin) {
            modal?.popup(<AdminInquiryView boardId={board.boardId!} />)
        } else {
            modal?.popup(<InquiryView boardId={board.boardId!} />)
        }
    }

    return (
        <div>
            <div>
                <table className={'list-table'}>
                    <thead>
                        <tr>
                            <th className="open-left">번호</th>
                            <th>제목</th>
                            <th>작성자</th>
                            <th className="open-right hide">문의 날짜</th>
                        </tr>
                    </thead>
                    <tbody>
                        {boardList.length !== 0 ? (
                            boardList.map((board, index) => (
                                <tr
                                    key={index}
                                    onClick={() => viewDetail(board)}
                                >
                                    <td className="open-left">
                                        {totalCount - (page - 1) * 10 - index}
                                    </td>
                                    <td
                                        className={'title'}
                                        style={{ textAlign: 'left' }}
                                    >
                                        {board.state == 2 && !props.isAdmin ? (
                                            <>
                                                비공개글 입니다
                                                <i className="icon-lock"></i>
                                            </>
                                        ) : (
                                            <>{board.title}</>
                                        )}
                                    </td>
                                    <td>
                                        {props.isAdmin
                                            ? board.user.userName
                                            : privacyName(board.user.userName)}
                                    </td>
                                    <td className="open-right hide">
                                        {formatDate(board.createDate)}
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td
                                    className="open-left open-right"
                                    colSpan={4}
                                    height={150}
                                >
                                    문의가 존재하지 않습니다.
                                </td>
                            </tr>
                        )}
                    </tbody>
                    <tfoot>
                        <tr>
                            <th colSpan={4}>
                                {props.productId ? (
                                    <>
                                        <input
                                            onClick={() => {
                                                window.location.href =
                                                    '/board/inquiry'
                                            }}
                                            type={'button'}
                                            value={'모두보기'}
                                        />
                                        <input
                                            onClick={() => {
                                                if (
                                                    localStorage.getItem(
                                                        'userLogin'
                                                    ) == 'Y'
                                                ) {
                                                    modal?.popup(
                                                        <InquiryEditor
                                                            productId={
                                                                props.productId!
                                                            }
                                                        />
                                                    )
                                                } else {
                                                    modal?.confirm(
                                                        () => {
                                                            window.location.href =
                                                                '/user/login'
                                                        },
                                                        () => {},
                                                        '알림',
                                                        '작성을 위해 로그인이 필요합니다.',
                                                        '로그인 하시겠습니까?'
                                                    )
                                                }
                                            }}
                                            type={'button'}
                                            value={'작성하기'}
                                        />
                                    </>
                                ) : (
                                    !props.isAdmin && (
                                        <span className={'middle-font'}>
                                            상품 문의는 상품 페이지에서
                                            가능합니다.
                                        </span>
                                    )
                                )}
                            </th>
                        </tr>
                    </tfoot>
                </table>
            </div>
            <BoardPageBar
                page={page}
                totalPage={totalPage}
                setPage={setPage}
                setBoardList={setBoardList}
            />
        </div>
    )
}
