import React, { useEffect, useState } from 'react'
import logo from '@Image/logo/brospo-dark.png'
import kakaoInquriyWide from '@Image/Api/kakao_chat_wide.png'
import kakaoInquriy from '@Image/Api/kakao_chat.png'
import './Header.css'
import { Link, useNavigation } from 'react-router-dom'
import { Category } from '@Interface/Category'
import { UserInfo } from '@Interface/User'
import { userLogout } from '@Util/UserUtils'

import { openProductSearch } from '@Component/product/Search/ProductSearch'
import { getModalInstance } from '@Component/common/Widgets/Modal/ModalProvider'
import { UserInfoService } from '@Service/user/account/UserService'

const logoAlt: string = 'brospo Main logo'

export const Header = (props: {
    categoryList: { [p: string]: Category[] }
}) => {
    const categoryList = props.categoryList

    const [userInfo, setUserInfo] = useState<UserInfo>()
    const [isHovered, setIsHovered] = useState(false) // hover 상태를 저장하는 상태 변수
    const [userName, setUserName] = useState('') // hover 상태를 저장하는 상태 변수

    const currentHref = window.location.href
    const modal = getModalInstance()

    const checkUserInfo = async () => {
        const userInfo = await UserInfoService()
        if (
            userInfo.userName != '' &&
            (currentHref.includes('login') ||
                currentHref.includes('join') ||
                currentHref.includes('find'))
        ) {
            modal?.alert(
                () => {
                    window.location.href = '/'
                },
                '경고',
                '잘못된 접근입니다.',
                ''
            )
        }
        setUserInfo(userInfo)
    }

    useEffect(() => {
        checkUserInfo()
    }, [])

    return (
        <header>
            <div className="pc-header">
                <div className="header-logo">
                    <Link to={''} onClick={() => setIsHovered(false)}>
                        <img src={logo} alt={logoAlt} />
                    </Link>
                </div>

                <div
                    className="header-menu"
                    onMouseEnter={() => setIsHovered(true)}
                >
                    <Link
                        to={'product/list'}
                        onClick={() => setIsHovered(false)}
                    >
                        상품
                    </Link>
                    <Link
                        to={'board/notice'}
                        onClick={() => setIsHovered(false)}
                    >
                        커뮤니티
                    </Link>
                    <Link to={'shopinfo'} onClick={() => setIsHovered(false)}>
                        정보
                    </Link>
                </div>
                {isHovered && (
                    <div
                        className="sub-menu"
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                    >
                        <div className="product">
                            {Object.keys(categoryList).map(
                                (primaryCategory) => (
                                    <div key={primaryCategory}>
                                        <Link
                                            to={
                                                '/product/list/' +
                                                primaryCategory
                                            }
                                            id="primary"
                                            onClick={() => setIsHovered(false)}
                                        >
                                            {primaryCategory}
                                        </Link>
                                        {/* 각 그룹의 secondaryCategory를 반복하여 출력 */}
                                        {categoryList[primaryCategory].map(
                                            (category, index) => (
                                                <Link
                                                    key={index}
                                                    to={
                                                        '/product/list/' +
                                                        primaryCategory +
                                                        '/' +
                                                        category.secondaryName
                                                    }
                                                    onClick={() =>
                                                        setIsHovered(false)
                                                    }
                                                >
                                                    {category.secondaryName}
                                                </Link>
                                            )
                                        )}
                                    </div>
                                )
                            )}
                        </div>
                        <div className="shop">
                            <div className="board">
                                <p>커뮤니티</p>
                                <Link
                                    to={'/board/notice'}
                                    onClick={() => setIsHovered(false)}
                                >
                                    공지사항
                                </Link>
                                <Link
                                    to={'/board/inquiry'}
                                    onClick={() => setIsHovered(false)}
                                >
                                    상품문의
                                </Link>
                                <Link
                                    to={'/board/review'}
                                    onClick={() => setIsHovered(false)}
                                >
                                    상품리뷰
                                </Link>
                            </div>
                            <div className="info">
                                <p>쇼핑몰 정보</p>
                                <Link
                                    to={'/shopinfo'}
                                    onClick={() => setIsHovered(false)}
                                >
                                    회사소개
                                </Link>
                                <Link
                                    to={'/privacy'}
                                    onClick={() => setIsHovered(false)}
                                >
                                    개인정보 처리방침
                                </Link>
                                <Link
                                    to={'/agreement'}
                                    onClick={() => setIsHovered(false)}
                                >
                                    이용약관
                                </Link>
                                <Link
                                    to={'http://pf.kakao.com/_iJGzxj/chat'}
                                    onClick={() => setIsHovered(false)}
                                    target={'_blank'}
                                >
                                    <img src={kakaoInquriy} alt={logoAlt} />
                                </Link>
                            </div>
                        </div>
                    </div>
                )}

                <div className="header-icon">
                    {userInfo?.userName != '' ? (
                        <>
                            <a id={'user-name'}>{userInfo?.userName + ' 님'}</a>
                            <Link to={'#'} onClick={userLogout}>
                                로그아웃
                            </Link>
                            <Link to={'/user/mypage/account'}>
                                <i className="icon-user-circle-o" />
                            </Link>
                        </>
                    ) : (
                        <Link to={'/user/login'} id={'user-login'}>
                            로그인
                        </Link>
                    )}
                    <a onClick={openProductSearch}>
                        <i className="icon-search" />
                    </a>
                </div>
            </div>
            <div className="mobile-header">
                <div className="header-icon" onClick={openMobileSubMenu}>
                    <i className="icon-menu"></i>
                </div>
                <div className="header-logo">
                    <Link to={''}>
                        <img src={logo} alt={logoAlt} />
                    </Link>
                </div>

                <div className="header-icon" onClick={openProductSearch}>
                    <i className="icon-search" />
                </div>
                <div className="sub-menu">
                    <div className="close-button">
                        <i
                            className="icon-cancel"
                            onClick={closeMobileSubMenu}
                        ></i>
                    </div>
                    <div>
                        <div className="header-logo">
                            <Link to={''} onClick={closeMobileSubMenu}>
                                <img src={logo} alt={logoAlt} />
                            </Link>
                        </div>
                        <div className="header-user">
                            {userInfo?.userName != '' ? (
                                <>
                                    <a id={'user-name'}>
                                        {userInfo?.userName + ' 님'}
                                    </a>
                                    <Link to={'#'} onClick={userLogout}>
                                        로그아웃
                                    </Link>
                                    <Link
                                        to={'/user/mypage/account'}
                                        onClick={closeMobileSubMenu}
                                    >
                                        <i className="icon-user-circle-o" />
                                    </Link>
                                </>
                            ) : (
                                <Link
                                    to={'/user/login'}
                                    id={'user-login'}
                                    onClick={closeMobileSubMenu}
                                >
                                    로그인
                                </Link>
                            )}
                        </div>
                    </div>

                    <div>
                        {Object.keys(categoryList).map((primaryCategory) => (
                            <div key={primaryCategory}>
                                <p>
                                    <Link
                                        to={'/product/list/' + primaryCategory}
                                        id="primary"
                                        onClick={closeMobileSubMenu}
                                    >
                                        {primaryCategory}
                                    </Link>
                                    <i
                                        className="icon-down-open"
                                        onClick={handleMobileClick}
                                    ></i>
                                </p>
                                {categoryList[primaryCategory].map(
                                    (category, index) => (
                                        <Link
                                            key={index}
                                            to={
                                                '/product/list/' +
                                                primaryCategory +
                                                '/' +
                                                category.secondaryName
                                            }
                                            onClick={closeMobileSubMenu}
                                        >
                                            {category.secondaryName}
                                        </Link>
                                    )
                                )}
                            </div>
                        ))}
                    </div>
                    <div>
                        <p>커뮤니티</p>
                        <Link to={'/board/notice'} onClick={closeMobileSubMenu}>
                            공지사항
                        </Link>
                        <Link
                            to={'/board/inquiry'}
                            onClick={closeMobileSubMenu}
                        >
                            상품문의
                        </Link>
                        <Link to={'/board/review'} onClick={closeMobileSubMenu}>
                            상품리뷰
                        </Link>
                    </div>
                    <div>
                        <p>쇼핑몰 정보 </p>
                        <Link to={'/shopinfo'} onClick={closeMobileSubMenu}>
                            회사소개
                        </Link>
                        <Link to={'/privacy'} onClick={closeMobileSubMenu}>
                            개인정보 처리방침
                        </Link>
                        <Link to={'/agreement'} onClick={closeMobileSubMenu}>
                            이용약관
                        </Link>
                        <Link
                            to={'http://pf.kakao.com/_iJGzxj/chat'}
                            target={'_blank'}
                        >
                            <img
                                src={kakaoInquriyWide}
                                alt={logoAlt}
                                onClick={closeMobileSubMenu}
                            />
                        </Link>
                    </div>
                </div>
                <div className="sub-menu-bg" onClick={closeMobileSubMenu}></div>
            </div>
        </header>
    )
}

export const AdminHeader = (props: {
    categoryList: { [p: string]: Category[] }
    userInfo: UserInfo
}) => {
    const categoryList = props.categoryList
    const userInfo = props.userInfo

    const [isHovered, setIsHovered] = useState(false) // hover 상태를 저장하는 상태 변수

    return (
        <header>
            <div className="pc-header">
                <div className="header-logo">
                    <Link to={'/admin'} onClick={() => setIsHovered(false)}>
                        <img src={logo} alt={logoAlt} />
                    </Link>
                </div>

                <div
                    className="header-menu"
                    onMouseEnter={() => setIsHovered(true)}
                >
                    <Link
                        to={'/admin/product/list'}
                        onClick={() => setIsHovered(false)}
                    >
                        상품관리
                    </Link>
                    <Link
                        to={'/admin/order?orderType=주문'}
                        onClick={() => setIsHovered(false)}
                    >
                        주문관리
                    </Link>
                    <Link
                        to={'/admin/board/list/notice'}
                        onClick={() => setIsHovered(false)}
                    >
                        게시판관리
                    </Link>
                </div>
                {isHovered && (
                    <div
                        className="sub-menu"
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                    >
                        <div className="product">
                            <div>
                                <Link
                                    to={'/admin/product/list/비공개'}
                                    id="primary"
                                    onClick={() => setIsHovered(false)}
                                >
                                    비공개 상품
                                </Link>
                                <Link
                                    to={'/admin/product'}
                                    onClick={() => setIsHovered(false)}
                                >
                                    상품 등록
                                </Link>
                                <Link
                                    to={'/admin/product/category'}
                                    onClick={() => setIsHovered(false)}
                                >
                                    카테고리 수정
                                </Link>
                                <Link
                                    to={'/admin/make-3d'}
                                    onClick={() => setIsHovered(false)}
                                >3D 이미지 제작
                                </Link>
                                <Link
                                    to={'/admin/main-image'}
                                    onClick={() => setIsHovered(false)}
                                >
                                    메인 이미지 수정
                                </Link>
                            </div>

                            {Object.keys(categoryList).map(
                                (primaryCategory) => (
                                    <div key={primaryCategory}>
                                        <Link
                                            to={
                                                '/admin/product/list/' +
                                                primaryCategory
                                            }
                                            id="primary"
                                            onClick={() => setIsHovered(false)}
                                        >
                                            {primaryCategory}
                                        </Link>

                                        {categoryList[primaryCategory].map(
                                            (category, index) => (
                                                <Link
                                                    key={index}
                                                    to={
                                                        '/admin/product/list/' +
                                                        primaryCategory +
                                                        '/' +
                                                        category.secondaryName
                                                    }
                                                    onClick={() =>
                                                        setIsHovered(false)
                                                    }
                                                >
                                                    {category.secondaryName}
                                                </Link>
                                            )
                                        )}
                                    </div>
                                )
                            )}
                        </div>
                        <div className="shop">
                            <div className="board">
                                <p>주문 관리</p>
                                <Link
                                    to={'/admin/order?orderType=주문'}
                                    onClick={() => setIsHovered(false)}
                                >
                                    주문 내역
                                </Link>
                                <Link
                                    to={'/admin/order?orderType=교환'}
                                    onClick={() => setIsHovered(false)}
                                >
                                    교환 접수
                                </Link>
                                <Link
                                    to={'/admin/order?orderType=반품'}
                                    onClick={() => setIsHovered(false)}
                                >
                                    반품 접수
                                </Link>
                                <Link
                                    to={'/admin/order?orderType=취소'}
                                    onClick={() => setIsHovered(false)}
                                >
                                    취소 접수
                                </Link>
                            </div>
                            <div className="info">
                                <p>게시판 관리</p>
                                <Link
                                    to={'/admin/board/list/notice'}
                                    onClick={() => setIsHovered(false)}
                                >
                                    공지사항
                                </Link>
                                <Link
                                    to={'/admin/board/list/inquiry'}
                                    onClick={() => setIsHovered(false)}
                                >
                                    상품문의
                                </Link>
                                <Link
                                    to={'/admin/board/list/review'}
                                    onClick={() => setIsHovered(false)}
                                >
                                    상품리뷰
                                </Link>
                                <Link
                                    to={'/admin/board/list/unprocessed'}
                                    onClick={() => setIsHovered(false)}
                                >
                                    미답변
                                </Link>
                            </div>
                        </div>
                    </div>
                )}

                <div className="header-icon">
                    {userInfo?.userName != '' ? (
                        <>
                            <a>{userInfo?.userName + ' 님'}</a>
                            <Link to={'#'} onClick={userLogout}>
                                로그아웃
                            </Link>
                        </>
                    ) : (
                        <Link to={'/user/login'} id={'user-login'}>
                            로그인
                        </Link>
                    )}
                    <i className="icon-search" onClick={openProductSearch} />
                </div>
            </div>
            <div className="mobile-header">
                <div className="header-icon" onClick={openMobileSubMenu}>
                    <i className="icon-menu"></i>
                </div>
                <div className="header-logo">
                    <Link to={''}>
                        <img src={logo} alt={logoAlt} />
                    </Link>
                </div>

                <div className="header-icon" onClick={openProductSearch}>
                    <i className="icon-search" />
                </div>
                <div className="sub-menu">
                    <div className="close-button">
                        <i
                            className="icon-cancel"
                            onClick={closeMobileSubMenu}
                        ></i>
                    </div>
                    <div>
                        <div className="header-logo">
                            <Link to={''} onClick={closeMobileSubMenu}>
                                <img src={logo} alt={logoAlt} />
                            </Link>
                        </div>
                        <div className="header-user">
                            {userInfo?.userName != '' ? (
                                <>
                                    <a id={'user-name'}>
                                        {userInfo?.userName + ' 님'}
                                    </a>
                                    <Link to={'#'} onClick={userLogout}>
                                        로그아웃
                                    </Link>
                                    <a>
                                        <i
                                            className="icon-search"
                                            onClick={openProductSearch}
                                        />
                                    </a>
                                </>
                            ) : (
                                <Link
                                    to={'/user/login'}
                                    id={'user-login'}
                                    onClick={closeMobileSubMenu}
                                >
                                    로그인
                                </Link>
                            )}
                        </div>
                    </div>
                    <div>
                        <p>상품/홈페이지 관리</p>
                        <Link
                            to={'/admin/product/list/비공개'}
                            id="primary"
                            onClick={closeMobileSubMenu}
                        >
                            비공개 상품
                        </Link>
                        <Link
                            to={'/admin/product'}
                            onClick={closeMobileSubMenu}
                        >
                            상품 등록
                        </Link>
                        <Link
                            to={'/admin/product/category'}
                            onClick={closeMobileSubMenu}
                        >
                            카테고리 수정
                        </Link>
                        <Link
                            to={'/admin/make-3d'}
                            onClick={closeMobileSubMenu}
                        >
                            3D 이미지 제작  
                        </Link>
                        <Link
                            to={'/admin/main-image'}
                            onClick={closeMobileSubMenu}
                        >
                            메인 이미지 수정
                        </Link>
                    </div>
                    <div>
                        {Object.keys(categoryList).map((primaryCategory) => (
                            <div key={primaryCategory}>
                                <p>
                                    <Link
                                        to={
                                            '/admin/product/list/' +
                                            primaryCategory
                                        }
                                        id="primary"
                                        onClick={closeMobileSubMenu}
                                    >
                                        {primaryCategory}
                                    </Link>
                                    <i
                                        className="icon-down-open"
                                        onClick={handleMobileClick}
                                    ></i>
                                </p>
                                {categoryList[primaryCategory].map(
                                    (category, index) => (
                                        <Link
                                            key={index}
                                            to={
                                                '/admin/product/list/' +
                                                primaryCategory +
                                                '/' +
                                                category.secondaryName
                                            }
                                            onClick={closeMobileSubMenu}
                                        >
                                            {category.secondaryName}
                                        </Link>
                                    )
                                )}
                            </div>
                        ))}
                    </div>
                    <div>
                        <p>주문 관리</p>
                        <Link
                            to={'/admin/order?orderType=주문'}
                            onClick={closeMobileSubMenu}
                        >
                            주문 내역
                        </Link>
                        <Link
                            to={'/admin/order?orderType=교환'}
                            onClick={closeMobileSubMenu}
                        >
                            교환 접수
                        </Link>
                        <Link
                            to={'/admin/order?orderType=반품'}
                            onClick={closeMobileSubMenu}
                        >
                            반품 접수
                        </Link>
                        <Link
                            to={'/admin/order?orderType=취소'}
                            onClick={closeMobileSubMenu}
                        >
                            취소 접수
                        </Link>
                    </div>
                    <div>
                        <p>게시판 관리</p>
                        <Link
                            to={'/admin/board/list/notice'}
                            onClick={closeMobileSubMenu}
                        >
                            공지사항
                        </Link>
                        <Link
                            to={'/admin/board/list/inquiry'}
                            onClick={closeMobileSubMenu}
                        >
                            상품문의
                        </Link>
                        <Link
                            to={'/admin/board/list/review'}
                            onClick={closeMobileSubMenu}
                        >
                            상품리뷰
                        </Link>
                        <Link
                            to={'/admin/board/list/unprocessed'}
                            onClick={closeMobileSubMenu}
                        >
                            미답변
                        </Link>
                    </div>
                </div>
                <div className="sub-menu-bg" onClick={closeMobileSubMenu}></div>
            </div>
        </header>
    )
}

const handleMobileClick = (event: React.MouseEvent<HTMLElement>) => {
    const icon = event.currentTarget as HTMLElement
    const parentDiv = icon.closest('div') as HTMLElement
    const maxHeight = parentDiv.scrollHeight
    const currentHeight = parentDiv.clientHeight

    const duration = 200 // 애니메이션 지속 시간 (밀리초)
    const interval = 10 // 애니메이션 갱신 간격 (밀리초)
    const frameCount = duration / interval // 총 프레임 수
    const heightIncrement = (maxHeight - 44) / frameCount // 프레임 당 높이 증가량

    let currentIteration = 0
    let tempHeight = currentHeight

    const increaseHeight = () => {
        const intervalId = setInterval(() => {
            tempHeight += heightIncrement
            parentDiv.style.height = `${tempHeight}px`

            currentIteration++
            if (currentIteration >= frameCount) {
                clearInterval(intervalId)
                parentDiv.style.height = `${maxHeight}px`
            }
        }, interval)
    }

    const decreaseHeight = () => {
        const intervalId = setInterval(() => {
            tempHeight -= heightIncrement
            parentDiv.style.height = `${tempHeight}px`

            currentIteration++
            if (currentIteration >= frameCount) {
                clearInterval(intervalId)
            }
        }, interval)
    }

    if (maxHeight === currentHeight) {
        decreaseHeight()
        if (icon) {
            icon.style.transform = 'rotate(0deg)'
        }
    } else {
        increaseHeight()
        if (icon) {
            icon.style.transform = 'rotate(180deg)'
        }
    }
}
const openMobileSubMenu = () => {
    const mobileHeader = document.querySelector('.mobile-header')
    if (mobileHeader instanceof HTMLElement) {
        const subMenu = mobileHeader.querySelector('.sub-menu')
        if (subMenu instanceof HTMLElement) {
            subMenu.style.transform = 'translateX(0%)'
        }
    }

    const sideBar = document.querySelector('.mobile-side-bar')
    if (sideBar instanceof HTMLElement) {
        sideBar.style.transform = 'translateX(150%)'
    }

    // Body에 overflow: hidden; 속성 추가
    document.body.style.overflow = 'hidden'

    // .sub-menu-bg 요소 display 속성 변경
    ;(document.querySelector('.sub-menu-bg') as HTMLElement).style.display =
        'block'
}

const closeMobileSubMenu = () => {
    const mobileHeader = document.querySelector('.mobile-header')
    if (mobileHeader instanceof HTMLElement) {
        const subMenu = mobileHeader.querySelector('.sub-menu')
        if (subMenu instanceof HTMLElement) {
            subMenu.style.transform = 'translateX(-100%)'
        }
    }

    const sideBar = document.querySelector('.mobile-side-bar')
    if (sideBar instanceof HTMLElement) {
        sideBar.style.transform = 'translateX(0%)'
    }

    // Body에서 overflow: hidden; 속성 제거
    document.body.style.overflow = ''

    // .sub-menu-bg 요소 display 속성 변경
    ;(document.querySelector('.sub-menu-bg') as HTMLElement).style.display =
        'none'

    // 각 요소들의 높이 초기화
    const elements = document.querySelectorAll(
        'header .mobile-header .sub-menu > div > div'
    )
    elements.forEach((element) => {
        element.setAttribute('style', 'height: 44px')
    })
}
