import {getRequest, postRequest,} from '@Service/Api'
import {HttpAlert, HttpSuccess} from '@Interface/HttpCallBack'

import {getModalInstance} from '@Component/common/Widgets/Modal/ModalProvider'
import {Cart, CartDTO, TransformedCart} from '@Interface/Cart'

export const insertCartService = async (carts: CartDTO[], alert?: boolean) => {
    const modal = getModalInstance()

    try {
        const httpSuccess: HttpSuccess = {
            200: () => {
                if (!alert) {
                    modal!.confirm(
                        () => {
                            window.location.href = '/order/cart'
                        },
                        () => {
                        },
                        '알림',
                        '장바구니에 추가되었습니다.',
                        '장바구니로 이동하시겠습니까?'
                    )
                }
            },
        }
        const httpAlert: HttpAlert = {
            401: () => {
                modal!.confirm(
                    () => {
                        window.location.href = '/user/login'
                    },
                    () => {
                    },
                    '알림',
                    '로그인이 필요합니다.',
                    '로그인 페이지로 이동하시겠습니까?'
                )
            },
        }
        await postRequest('/cart', httpSuccess, httpAlert, carts, {})
        return true
    } catch (e) {
        console.log(e)
        return false
    }
}

export const updateCartService = async (carts: CartDTO[]) => {
    try {
        const httpSuccess: HttpSuccess = {}
        const httpAlert: HttpAlert = {}

        return await postRequest('/cart', httpSuccess, httpAlert, carts, {})
    } catch (e) {
        console.log(e)
    }
}

export const deleteCartService = async (carts: CartDTO[]) => {
    const modal = getModalInstance()
    try {
        const httpSuccess: HttpSuccess = {}
        const httpAlert: HttpAlert = {}

        return await postRequest('/cart/del', httpSuccess, httpAlert, carts, {})
    } catch (e) {
        console.log(e)
    }
}

export const getCartListService = async (): Promise<TransformedCart[]> => {
    try {
        const httpSuccess: HttpSuccess = {} // HTTP 성공 응답에 대한 객체입니다.
        const httpAlert: HttpAlert = {} // HTTP 경고 메시지에 대한 객체입니다.

        const cartList = await getRequest<Cart[]>(
            '/cart/list',
            httpSuccess,
            httpAlert,
            {}
        )

        if (!cartList) {
            return []
        }

        const transformedCartList: TransformedCart[] = []

        cartList.forEach((cartItem) => {
            if (
                !transformedCartList.some(
                    (item) =>
                        item.product.productId === cartItem.product.productId
                )
            ) {
                transformedCartList.push({
                    product: {
                        productId: cartItem.product.productId,
                        category: cartItem.product.category,
                        name: cartItem.product.name,
                        price: cartItem.product.price,
                        deliveryFee: cartItem.product.deliveryFee,
                        productCode: cartItem.product.productCode,
                        productDescription: null,
                        size: cartItem.product.size,
                    },
                    sizes: [],
                })
            }

            transformedCartList
                .find(
                    (item) =>
                        item.product.productId === cartItem.product.productId
                )
                ?.sizes.push({
                size: cartItem.size,
                count: cartItem.count,
            })
        })

        return transformedCartList // 변환된 카트 정보 배열을 반환합니다.
    } catch (e) {
        console.log(e)
        return [] // 에러 발생 시 빈 배열을 반환합니다.
    }
}
