import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'

function SetLocation() {
    const location = useLocation()

    useEffect(() => {
        // Check if local storage is available in the browser
        if (typeof window !== 'undefined' && window.localStorage) {
            const currentUrl = window.location.href // 전체 URL (경로 + 쿼리 파라미터 포함)
            if (!currentUrl.includes('join') && !currentUrl.includes('login')) {
                localStorage.setItem('referrer', currentUrl)
            }
        } else {
            console.error('Local storage is not available in this browser.')
        }
    }, [location])

    return null // or <></> if you prefer
}

export default SetLocation
