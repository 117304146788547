import { Privacy } from '@Component/common/View/Document/Privacy/Privacy'
import { useEffect } from 'react'
import './PrivacyPage.css'
function PrivacyPage() {
    useEffect(() => {
        document.title = 'BROSPO 개인정보처리방침'
    }, [])
    return (
        <div className={'privacy-page'}>
            <h2 className="page-title">개인정보처리방침</h2>
            <Privacy />
        </div>
    )
}

export default PrivacyPage
