import React, { useEffect, useState } from 'react'
import './Mypage.css'
import { UserInfo } from '@Interface/User'
import { useLocation } from 'react-router-dom'
import { MyPageTabBar } from '@Component/mypage/TabBar/MypageTabBar'
import { getModalInstance } from '@Component/common/Widgets/Modal/ModalProvider'
import { MypageAccount } from '@Component/mypage/account/MypageAccount'

import { MypageOrders } from '@Component/mypage/orders/MypageOrders'
import { UserInfoService } from '@Service/user/account/UserService'
import { MypageBoard } from '@Component/mypage/activity/MypageBoard'
import { checkUserInfo } from '@Util/UserUtils'

export const MyPage = () => {
    const location = useLocation()
    const path = location.pathname
    const modal = getModalInstance()
    const [userInfo, setUserInfo] = useState<UserInfo>()
    const [pageTitle, setPageTitle] = useState<string>()
    useEffect(() => {
        document.title = 'BROSPO 마이페이지'

        checkUserInfo(setUserInfo, true)
    }, [])

    return (
        <div className="mypage">
            <MyPageTabBar />
            <h2 className="page-title">{pageTitle}</h2>
            {path == '/user/mypage/account' && (
                <MypageAccount userInfo={userInfo!} />
            )}
            {path == '/user/mypage/orders' && <MypageOrders />}
            {path == '/user/mypage/activity' && <MypageBoard />}
        </div>
    )
}
