import axios from 'axios'
import {
    Product,
    ProductSearchDTO,
    ProductListResponse,
    ProductDetail,
} from '@Interface/Product'
import {
    deleteRequest,
    getRequest,
    postRequest,
    putRequest,
} from '@Service/Api'
import { HttpAlert, HttpSuccess } from '@Interface/HttpCallBack'
import { useState } from 'react'
import {
    Board,
    BoardDetail,
    BoardListDTO,
    BoardSearchDTO,
} from '@Interface/Board'
import { getModalInstance } from '@Component/common/Widgets/Modal/ModalProvider'

export const insertWishService = async (productId: string) => {
    const modal = getModalInstance()
    try {
        const httpSuccess: HttpSuccess = {

        }
        const httpAlert: HttpAlert = {
            401: () => {
                modal?.confirm(
                    () => {
                        window.location.href = '/user/login'
                    },
                    () => {},
                    '알림',
                    '추가를 위해 로그인이 필요합니다.',
                    '로그인 하시겠습니까?'
                )
            },
        }
        const formData = { productId: productId }
        return await postRequest('/wish', httpSuccess, httpAlert, formData, {})
    } catch (e) {
        console.log(e)
    }
}

export const getWishListService = async () => {
    try {
        const httpAlert: HttpAlert = {}
        const httpSuccess: HttpSuccess = {}

        return await getRequest<Product[]>('/wish', httpSuccess, httpAlert, {})
    } catch (e) {
        console.log(e)
    }
}

export const deleteWishService = async (productId: string) => {
    const modal = getModalInstance()
    try {
        const httpSuccess: HttpSuccess = {

        }

        const httpAlert: HttpAlert = {}

        return await deleteRequest('/wish', httpSuccess, httpAlert, {
            params: { productId: productId },
        })
    } catch (e) {
        console.log(e)
    }
}
