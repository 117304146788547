import { getModalInstance } from '@Component/common/Widgets/Modal/ModalProvider'
import { HttpAlert, HttpSuccess } from '@Interface/HttpCallBack'
import { getRequest, postRequest, putRequest } from '@Service/Api'
import {
    Order,
    OrderCountDTO,
    OrderDTO,
    OrderItem,
    OrderListDTO,
    OrderSearchDTO,
    responsePaymentCustom,
    UpdateOrderDTO,
} from '@Interface/Order'
import { PaymentResponse } from '@portone/browser-sdk/dist/v2/requestPayment'
import { Address } from '@portone/browser-sdk/dist/v2/entity'

export const getAdminOrderService = async (orderCode?: string) => {
    const modal = getModalInstance()
    try {
        const httpSuccess: HttpSuccess = {}
        const httpAlert: HttpAlert = {}

        return await getRequest<OrderDTO>(
            `/admin/order/${orderCode || ''}`,
            httpSuccess,
            httpAlert,
            {}
        )
    } catch (e) {
        console.log(e)
    }
}

export const getOrderListService = async (searchQuery: OrderSearchDTO) => {
    const modal = getModalInstance()
    try {
        const httpSuccess: HttpSuccess = {}
        const httpAlert: HttpAlert = {}
        const config = {
            params: searchQuery,
        }

        return await getRequest<OrderListDTO>(
            searchQuery.admin ? '/admin/order/list' : '/order/list',
            httpSuccess,
            httpAlert,
            config
        )
    } catch (e) {
        console.log(e)
    }
}

export const adminUpdateOrderService = async (order: UpdateOrderDTO) => {
    const modal = getModalInstance()

    try {
        const httpSuccess: HttpSuccess = {}
        const httpAlert: HttpAlert = {}
        await putRequest('/admin/order', httpSuccess, httpAlert, order, {})

        return true
    } catch (e) {
        console.log(e)
        return false
    }
}
