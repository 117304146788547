import {SaveBoard} from "@Interface/Board";
import {getImageList} from "@Util/imageUtils";
import {insertBoardService} from "@Service/user/board/BoardService";
import {getModalInstance} from "@Component/common/Widgets/Modal/ModalProvider";
import {Address} from "@Interface/Address";
import {saveAddressService} from "@Service/user/Address/AddressService";

export  const  saveAddress =async () => {

    const modal = getModalInstance()
    if (!(await checkAddressForm())) {
        return
    }

    const address: Address = getAddressFormValue()

    if(await saveAddressService(address) == ''){
      return true;
    }else{
        return false
    }

}



async function checkAddressForm() {
    const modal = getModalInstance()

    const isValidAddressNickName= await checkAddressNickName()
    if (!isValidAddressNickName) {
        modal!.alert(() => {}, '확인', '배송지 별칭을 입력해주세요.')
        return false
    }

    const isValidAddress = await checkAddress()
    if (!isValidAddress) {
        return false
    }


    const isValidRecipientName = await checkRecipientName()
    if (!isValidRecipientName) {
        modal!.alert(() => {}, '확인', '수령인 이름을 입력해주세요.')
        return false
    }

    const isValidRecipientContact = await checkRecipientContact()

    if (!isValidRecipientContact) {
        return false
    }



    const isValidDeliveryRequest = await  checkDeliveryRequest()
    if (!isValidDeliveryRequest) {
        modal!.alert(() => {}, '확인', '배송요청사항을 선택해주세요.')
        return false
    }

    return true
}
function checkAddress() {
    const modal = getModalInstance()
    const element = document.getElementById('address') as HTMLInputElement
    const elementDetail = document.getElementById('address-detail') as HTMLInputElement

    if (element.value == '' ) {
        modal!.alert(() => {}, '확인', '주소을 입력해주세요.')
        return false
    }
    if (elementDetail.value == '' ) {
        modal!.alert(() => {}, '확인', '상세 주소을 입력해주세요.')
        return false
    }
    return true
}

function checkRecipientName() {
    const element = document.getElementById('recipient-name') as HTMLInputElement

    if (element.value == '' ) {
        return false
    }
    return true
}

function checkRecipientContact() {

    const modal = getModalInstance()
    const element = document.getElementById('recipient-contact') as HTMLInputElement
    if (element.value == '') {
        modal!.alert(() => {}, '확인', '수령인 전화번호를 입력해주세요')
        return false
    }

    const regex = /^\d{3}-\d{3,4}-\d{4}$/
    if(!regex.test(element.value)){
        modal!.alert(() => {}, '확인', '수령인 전화번호 형식을 확인해주세요')
        return false
    }
    return true;

}

function checkAddressNickName() {
    const element = document.getElementById('address-nicname') as HTMLInputElement
    if (element.value == '') {
        return false
    }
    return true
}

function checkDeliveryRequest() {
    const element = document.getElementById('delivery-request') as HTMLInputElement
    if (element.value == '') {
        return false
    }
    return true
}

function getAddressFormValue(): Address {
    return {
        addressId:
            (document.getElementById('address-id') as HTMLInputElement).value ||
            '',
        addressNickName:  (document.getElementById('address-nicname') as HTMLInputElement)
            ?.value || '',
        address:
            `${(document.getElementById('address') as HTMLInputElement)
                .value}///${(document.getElementById('address-detail') as HTMLInputElement).value}`,
        mainAddress: (document.getElementById('main-address') as HTMLInputElement)
            ?.checked
            ? 'Y'
            : 'N',
        recipientName:
            (document.getElementById('recipient-name') as HTMLInputElement)
                ?.value || '',
        recipientContact:
            (document.getElementById('recipient-contact') as HTMLInputElement)
                .value || '',
        deliveryRequest:
            (document.getElementById('delivery-request') as HTMLInputElement).value ||
            '',
    }
}