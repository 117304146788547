import React, { useEffect } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import IndexPage from '@Page/user/cmm/IndexPage/IndexPage'

import PrivacyPage from '@Page/user/cmm/PrivacyPage/PrivacyPage'

import NoticeListPage from '@Page/user/board/NoticePage/NoticePage'

import ProductDetail from '@Page/user/product/ProductDetailPage/ProductDetail'
import NotFound from '@Component/common/View/NotFound/NotFound'
import Footer from '@Component/common/View/Footer/Footer'
import { AdminProductCategoryPage } from '@Page/admin/product/AdminProductCategoryPage/AdminProductCategoryPage'

import { AdminHeader, Header } from '@Component/common/View/Header/Header'
import { UserLoginPage } from '@Page/user/account/UserLoginPage/UserLoginPage'
import { ProductListPage } from '@Page/user/product/ProcutListPage/ProductListPage'
import SetLocation from '@Component/common/Function/SetLocation/SetLocation'
import { AdminUserInfoService } from '@Service/admin/account/AdminUserService'

import { UserInfoService } from '@Service/user/account/UserService'
import { AdminProductListPage } from '@Page/admin/product/AdminProcutListPage/AdminProductListPage'
import { AdminProductPage } from '@Page/admin/product/AdminProductPage/AdminProductPage'
import { UserJoinPage } from '@Page/user/account/UserJoinPage/UserJoinPage'

import { AdminMainImagePage } from '@Page/admin/cmm/AdminMainImagePage/AdminMainImagePage'

import AgreementPage from '@Page/user/cmm/AgreementPage/AgreementPage'
import { Loading } from '@Component/common/Function/Loading/Loading'

import AboutPage from '@Page/user/cmm/AboutPage/AboutPage'
import { AdminDashBoardPage } from '@Page/admin/cmm/AdminDashBoardPage/AdminDashBoardPage'
import { ProductSearch } from '@Component/product/Search/ProductSearch'
import SetFontSize from '@Component/common/Function/SetFontSize/SetFontSize'
import {
    getModalInstance,
    ModalProvider,
} from '@Component/common/Widgets/Modal/ModalProvider'
import { ReviewPage } from '@Page/user/board/ReviewPage/ReviewPage'
import InquiryPage from '@Page/user/board/InquiryPage/InquiryPage'
import { ScrollToTop } from '@Component/common/Function/ScrollToTop/ScrollToTop'
import { SideBar } from '@Component/common/View/SideBar/SideBar'
import { MyPage } from '@Page/user/mypage/Mypage'
import { setWishicon } from '@Util/WishUtils'
import { CategoryListService } from '@Service/user/cmm/CmmService'
import { OrderPage } from '@Page/user/order/orderPage/OrderPage'
import { AdminReviewPage } from '@Page/admin/board/ReviewPage/AdminReviewPage'
import { AdminInquiryPage } from '@Page/admin/board/InquiryPage/AdminInquiryPage'
import { AdminUnprocessedPage } from '@Page/admin/board/Unprocessed/AdminUnprocessedPage'
import { AdminNoticePage } from '@Page/admin/board/NoticePage/AdminNoticePage'
import { AdminOrderPage } from '@Page/admin/order/AdminOrderPage/AdminOrderPage'
import { Make3DPage } from '@Page/admin/cmm/AdminMake3DPage/Make3DPage,'

const App = () => {
    const categoryList = CategoryListService()
    const adminUserInfo = AdminUserInfoService()

    return (
        <BrowserRouter>
            <ModalProvider />
            <ProductSearch />
            <SetLocation />
            <SetFontSize />
            <ScrollToTop />

            <Routes>
                <Route
                    path="/admin/*"
                    element={
                        <AdminHeader
                            categoryList={categoryList}
                            userInfo={adminUserInfo}
                        />
                    }
                />
                <Route
                    path="/*"
                    element={<Header categoryList={categoryList} />}
                />
            </Routes>
            <main>
                <Routes>
                    {/* 사용자 페이지 */}
                    <Route path="/" element={<IndexPage />} />
                    <Route path="/shopinfo" element={<AboutPage />} />
                    <Route path="/privacy" element={<PrivacyPage />} />
                    <Route path="/agreement" element={<AgreementPage />} />

                    {/* 사용자 회원관리 */}
                    <Route path="/user/login" element={<UserLoginPage />} />
                    <Route path="/user/join" element={<UserJoinPage />} />
                    {/*<Route path="/user/find" element={<FindAccount/>}/>*/}

                    {/* 마이페이지 */}
                    <Route path="/user/mypage/*" element={<MyPage />} />

                    {/*/!* 상품 페이지 *!/*/}
                    <Route
                        path="/product/list"
                        element={
                            <ProductListPage categoryList={categoryList} />
                        }
                    />
                    <Route
                        path="/product/list/:primaryCategory"
                        element={
                            <ProductListPage categoryList={categoryList} />
                        }
                    />
                    <Route
                        path="/product/list/:primaryCategory/:secondaryCategory"
                        element={
                            <ProductListPage categoryList={categoryList} />
                        }
                    />
                    <Route
                        path="/product/detail/:productId"
                        element={<ProductDetail />}
                    />

                    {/*/!* 구매 관련 페이지 *!/*/}
                    <Route path="/order/:orderPath" element={<OrderPage />} />

                    {/* 각종 게시글 페이지 */}
                    <Route path="/board/notice" element={<NoticeListPage />} />

                    <Route path="/board/inquiry" element={<InquiryPage />} />
                    <Route path="/board/review" element={<ReviewPage />} />

                    {/*/!* 관리자 페이지 *!/*/}
                    <Route path="/admin" element={<AdminDashBoardPage />} />
                    <Route path="/admin/login" element={<UserLoginPage />} />

                    <Route
                        path="/admin/main-image"
                        element={<AdminMainImagePage />}
                    />

                    {/*/!* 관리자 상품 관리 *!/*/}
                    <Route
                        path="/admin/product/category"
                        element={
                            <AdminProductCategoryPage
                                categoryList={categoryList}
                            />
                        }
                    />

                    <Route path="/admin/make-3d" element={<Make3DPage />} />

                    <Route
                        path="/admin/product/list"
                        element={
                            <AdminProductListPage categoryList={categoryList} />
                        }
                    />
                    <Route
                        path="/admin/product/list/:primaryCategory"
                        element={
                            <AdminProductListPage categoryList={categoryList} />
                        }
                    />
                    <Route
                        path="/admin/product/list/:primaryCategory/:secondaryCategory"
                        element={
                            <AdminProductListPage categoryList={categoryList} />
                        }
                    />

                    <Route
                        path="/admin/product"
                        element={
                            <AdminProductPage categoryList={categoryList} />
                        }
                    />

                    <Route
                        path="/admin/product/detail/:productId"
                        element={
                            <AdminProductPage categoryList={categoryList} />
                        }
                    />

                    <Route
                        path="/admin/product/freeView/:productId"
                        element={<ProductDetail />}
                    />

                    {/*<Route path="/admin/product/:productNum" element={<AdminProductDetail/>}/>*/}

                    {/*/!* 관리자 게시글/문의글 관리 *!/*/}
                    <Route
                        path="/admin/board/list/notice"
                        element={<AdminNoticePage />}
                    />
                    <Route
                        path="/admin/board/list/inquiry"
                        element={<AdminInquiryPage />}
                    />
                    <Route
                        path="/admin/board/list/review"
                        element={<AdminReviewPage />}
                    />
                    <Route
                        path="/admin/board/list/unprocessed"
                        element={<AdminUnprocessedPage />}
                    />

                    {/*/!* 주문 관리 페이지 *!/*/}
                    <Route path="/admin/order" element={<AdminOrderPage />} />

                    {/* 이외 페이지 */}
                    <Route path="/*" element={<NotFound />} />
                </Routes>
            </main>
            <SideBar />
            <Footer />
            <Loading />
        </BrowserRouter>
    )
}

export default App
function getWishListService() {
    throw new Error('Function not implemented.')
}
