import React, { useEffect } from 'react'

import { NoticeList } from '@Component/board/List/NoticeList'
import './NoticePage.css'
function NoticePage() {
    useEffect(() => {
        document.title = 'BROSPO 공지사항'
    }, [])

    return (
        <div className={'notice-page'}>
            <h2 className="page-title">공지사항</h2>
            <NoticeList isAdmin={false} />
        </div>
    )
}

export default NoticePage
