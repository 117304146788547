import React, { useEffect, useState } from 'react'
import './OrderPage.css'
import { UserInfo } from '@Interface/User'
import { useLocation, useParams } from 'react-router-dom'
import { MyPageTabBar } from '@Component/mypage/TabBar/MypageTabBar'
import { getModalInstance } from '@Component/common/Widgets/Modal/ModalProvider'

import { CartList } from '@Component/cart/CartList.css/CartList'
import { CartPrice, OrderPrice } from '@Component/order/OrderPrice/OrderPrice'
import { OrderComplete } from '@Component/order/OrderComplete/OrderComplete'
import { OrderInfoForm } from '@Component/order/OrderInfoForm/OrderInfoForm'
import { PaymentRedirectPage } from '@Page/user/cmm/Redirect/RedirectPage'
import { UserInfoService } from '@Service/user/account/UserService'
import { checkUserInfo } from '@Util/UserUtils'

export const OrderPage = () => {
    const location = useLocation()
    const path = location.pathname
    const { orderPath } = useParams<{ orderPath: string }>()

    const [userInfo, setUserInfo] = useState<UserInfo>()

    useEffect(() => {
        if (orderPath == 'cart') {
            document.title = 'BROSPO 장바구니'
        } else if (orderPath == 'checkout') {
            document.title = 'BROSPO 주문작성'
        } else if (orderPath == 'complete') {
            document.title = 'BROSPO 결제완료'
        }

        checkUserInfo(setUserInfo, true)
    }, [])

    return (
        <div className="order">
            <div className={'order-header '}>
                <div
                    className={
                        orderPath == 'cart' ||
                        orderPath == 'checkout' ||
                        orderPath == 'complete'
                            ? 'active'
                            : ''
                    }
                >
                    장바구니
                </div>
                <div>
                    <i
                        className={
                            orderPath == 'cart' ||
                            orderPath == 'checkout' ||
                            orderPath == 'complete'
                                ? 'icon-angle-double-right active'
                                : 'icon-angle-double-right'
                        }
                    ></i>
                </div>
                <div
                    className={
                        orderPath == 'checkout' || orderPath == 'complete'
                            ? 'active'
                            : ''
                    }
                >
                    주문/결재
                </div>
                <div>
                    <i
                        className={
                            orderPath == 'checkout' || orderPath == 'complete'
                                ? 'icon-angle-double-right active'
                                : 'icon-angle-double-right'
                        }
                    ></i>
                </div>
                <div className={orderPath == 'complete' ? 'active' : ''}>
                    결재완료
                </div>
            </div>

            {userInfo && userInfo?.userName !== '' && (
                <>
                    {orderPath === 'cart' && (
                        <div className="order-cart-info">
                            <CartList />
                            <CartPrice />
                        </div>
                    )}
                    {orderPath === 'checkout' && (
                        <div className="order-info">
                            <OrderInfoForm userInfo={userInfo!} />
                        </div>
                    )}
                    {orderPath === 'complete' && <OrderComplete />}
                    {orderPath === 'redirect' && <PaymentRedirectPage />}
                </>
            )}
        </div>
    )
}
