import { Board } from './Board'
import { Product } from './Product'
import { UserInfo } from '@Interface/User'

export interface OrderItem {
    product: Product
    size: string
    count: string
}

export interface Order {
    user?: UserInfo
    board?: Board
    orderId?: string
    orderCode?: string
    orderName?: string
    paymentType?: string
    paymentAmount?: string
    cancelAmount?: string
    accountHolder?: string
    accountNumber?: string
    orderState?: string
    deliveryCode?: string
    address?: string
    recipientName?: string
    recipientContact?: string
    deliveryRequest?: string
    orderDate?: string
    completionDate?: string
    returnDate?: string
    completionReturnDate?: string
}

export interface UpdateOrderDTO {
    orderCode: string | null
    address?: string | null
    recipientName?: string | null
    recipientContact?: string | null
    deliveryRequest?: string | null
   orderState?: string | null
     reason?: string | null
    answer?: string | null
    alimYn?: string | null
    cancelPayment?: string | null
    deliveryCode?: string | null

}

export interface OrderSearchDTO {
    orderState: string
    page: string | number
    startDate: string
    endDate: string
    paymentType: string
    orderName: string
    orderCode?: string
    deliveryCode?: string
    userId?:string
    admin: boolean
}

export interface TransformedOrder {
    product: Product
    sizes: OrderDetails[]
}

export interface OrderDetails {
    size: string
    count: string
}

export interface OrderDTO {
    order: Order
    orderItemList: OrderItem[]
}

export interface OrderListDTO {
    orderList: Order[]
    orderThumbnailSrc: string[]
    count: number
}

export interface OrderExcelDTO {
    orderList: OrderDTO[]
}


export interface responsePaymentCustom {
    accountHolder: string
    accountNumber: string
    paymentId: string
    transactionType: string
}

export interface OrderCountDTO {
    completeOrderCount: number // 주문 완료 상품 개수
    inDeliveryCount: number // 배송중 상품 개수
    deliveredCount: number // 배송 완료 상품 개수
    exchangeOrRefundCount: number // 교환/환불 상품 개수
    preparationCount: number // 준비중 상품 개수
}


export interface DeliveryState {
    name: string,
    description:string,
    time: Date
  }


export const carrierNameMapping: Record<string, string> = {
    "kr.epost": "우체국",
    "kr.cjlogistics": "CJ 물류",
    "kr.lotte": "롯데",
    "kr.hanjin": "한진",
    "kr.logen": "로젠",
    "kr.kdexp": "경동",
    "kr.cvsnet": "GS 우편함"
  };



export const bankNames: string[] = [
    "KEB 하나",
    "KB국민",
    "신한",
    "우리",
    "농협",
    "씨티",
    "스탠다드차타드",
    "HSBC",
    "기업",
    "SC제일",
    "삼성",
    "KDB산업",
    "부산",
    "대구",
    "제주",
    "신협",
    "기타"
];
