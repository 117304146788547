import { Agreement } from '@Component/common/View/Document/Agreement/Agreement'
import './AgreementPage.css'
import { useEffect } from 'react'
const AgreementPage = () => {
    useEffect(() => {
        document.title = 'BROSPO 이용약관'
    }, [])
    return (
        <div className={'agreement-page'}>
            <h2 className="page-title">이용약관</h2>
            <Agreement />
        </div>
    )
}

export default AgreementPage
