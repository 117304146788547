import { BoardPageBar } from '@Component/common/Widgets/PageBar/PageBar'
import React, { useEffect, useState } from 'react'
import './BoardList.css'

import { ReviewEditor } from '@Component/board/Edit/ReviewEditor'
import { Board, BoardSearchDTO } from '@Interface/Board'
import { formatDate, getPlainText, privacyName } from '@Util/StringUtils'

import { fetchBoardList } from '@Util/BoardUtils'
import { AdminReviewView, ReviewView } from '../View/ReviewView'
import { getModalInstance } from '@Component/common/Widgets/Modal/ModalProvider'
import { onErrorImg } from '@Util/imageUtils'

export const ReviewList = (props: {
    productId: string | null
    userSearch?:boolean
    isAdmin?: boolean
}) => {
    const modal = getModalInstance()
    const [page, setPage] = useState<number>(1)
    const [changePage, setChangePage] = useState<number>(1)
    const [totalPage, setTotalPage] = useState<number>(1)

    const [boardList, setBoardList] = useState<Board[]>([])

    const viewDetail = (board: Board) => {
        if (props.isAdmin) {
            modal?.popup(<AdminReviewView boardId={board.boardId!} />)
        } else {
            modal?.popup(<ReviewView boardId={board.boardId!} />)
        }
    }

    useEffect(() => {
        var searchQuery: BoardSearchDTO = {
            productId: props.productId,
            category: '1',
            page: page,
            answer: window.location.href.includes('unprocessed') ? 'Y' : 'N',
            isAdmin: props.isAdmin,
            userSearch:props.userSearch?'Y':'N'
        }
        fetchBoardList(searchQuery, setBoardList, setTotalPage)
        if (changePage != page) {
            const reviewElement = document.querySelector('[id$="review"]')
            setChangePage(page)
            if (reviewElement) {
                reviewElement.scrollIntoView({
                    block: 'start',
                    behavior: 'smooth',
                })
            }
        }
    }, [page])

    return (
        <div>
            <div>
                <table className={'list-table'}>
                    <thead>
                        <tr>
                            <th className={'open-right open-left'}></th>
                            <th className={'open-left'}>제목</th>
                            <th>작성자</th>
                            <th className={'open-right hide'}>작성 날짜</th>
                        </tr>
                    </thead>
                    <tbody>
                        {boardList.length !== 0 ? (
                            boardList.map((board, index) => (
                                <tr
                                    key={index}
                                    onClick={() => viewDetail(board)}
                                >
                                    <td className="open-left open-right center">
                                        <div className="review-thumbnail">
                                            <img
                                                src={`${process.env
                                                    .BOARD_IMAGE_BASE_URL!}${
                                                    board.boardId
                                                }/0.jpg`}
                                                draggable="false"
                                                alt=""
                                                onError={(event) => {
                                                    // First fallback to product image
                                                    event.currentTarget.onerror =
                                                        null // Prevent infinite loop
                                                    event.currentTarget.src = `${process
                                                        .env
                                                        .PRODUCT_IMAGE_BASE_URL!}${
                                                        board.product!.productId
                                                    }/THUMBNAIL/0.jpg`

                                                    // Second fallback to error image
                                                    event.currentTarget.onerror =
                                                        () => {
                                                            onErrorImg(event)
                                                        }
                                                }}
                                            />
                                        </div>
                                    </td>
                                    <td
                                        className={'title open-left'}
                                        style={{ textAlign: 'left' }}
                                    >
                                        {board.title}
                                        <p>
                                            {[...Array(5)].map((_, index) => (
                                                <i
                                                    key={index}
                                                    className={
                                                        board.reviewRating! >
                                                        index
                                                            ? 'icon-star'
                                                            : 'icon-star-empty'
                                                    }
                                                ></i>
                                            ))}
                                        </p>

                                        <p
                                            className="description-review"
                                            dangerouslySetInnerHTML={{
                                                __html: getPlainText(
                                                    board.content
                                                ),
                                            }}
                                        ></p>
                                    </td>
                                    <td>{privacyName(board.user.userName)}</td>
                                    <td className="open-right hide">
                                        {formatDate(board.createDate)}
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td
                                    className="open-left open-right"
                                    colSpan={4}
                                    height={150}
                                >
                                    리뷰가 존재하지 않습니다.
                                </td>
                            </tr>
                        )}
                    </tbody>
                    <tfoot>
                        <tr>
                            <th colSpan={4}>
                                {props.productId && (
                                    <>
                                        <input
                                            onClick={() => {
                                                window.location.href =
                                                    '/board/review'
                                            }}
                                            type={'button'}
                                            value={'모두보기'}
                                        />
                                        <input
                                            onClick={() => {
                                                if (
                                                    localStorage.getItem(
                                                        'userLogin'
                                                    ) == 'Y'
                                                ) {
                                                    modal?.popup(
                                                        <ReviewEditor
                                                            productId={
                                                                props.productId!
                                                            }
                                                        />,
                                                        '96vw',
                                                        '75vh'
                                                    )
                                                } else {
                                                    modal?.confirm(
                                                        () => {
                                                            window.location.href =
                                                                '/user/login'
                                                        },
                                                        () => {},
                                                        '알림',
                                                        '작성을 위해 로그인이 필요합니다.',
                                                        '로그인 하시겠습니까?'
                                                    )
                                                }
                                            }}
                                            type={'button'}
                                            value={'작성하기'}
                                        />
                                    </>
                                )}
                            </th>
                        </tr>
                    </tfoot>
                </table>
            </div>
            <BoardPageBar
                page={page}
                totalPage={totalPage}
                setPage={setPage}
                setBoardList={setBoardList}
            />
        </div>
    )
}
