import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import './DatePicker.css' // 위에서 설정한 CSS 파일 임포트
import { ko } from 'date-fns/locale' // 한국어 로케일 가져오기

const DateInput = (props: {
    selectedDate: Date
    setSelectedDate: React.Dispatch<React.SetStateAction<Date>>
}) => {
    const handleChange = (date: Date | null) => {
        props.setSelectedDate(date || new Date())
    }

    return (
        <DatePicker
            dateFormat="yyyy.MM.dd"
            shouldCloseOnSelect
            minDate={new Date('1900-01-01')}
            maxDate={new Date()}
            selected={props.selectedDate}
            onChange={handleChange}
            locale={ko}
            popperPlacement="bottom-start" // Adjust popper placement
        />
    )
}

export default DateInput
