import * as PortOne from '@portone/browser-sdk/v2'
import * as process from 'process'
import { Entity } from '@portone/browser-sdk'
import { UserInfo } from '@Interface/User'
import { getModalInstance } from '@Component/common/Widgets/Modal/ModalProvider'
import { OneOfType } from '@portone/browser-sdk/dist/utils'
import { CashReceiptType } from '@portone/browser-sdk/dist/v2/entity'
import { orderWebHookService } from '@Service/user/order/OrderService'

export const onClickPayment = async ({
    user,
    totalAmount,
    orderCode,
    orderName,
}: {
    user: UserInfo
    totalAmount: number
    orderCode: string
    orderName: string
}) => {
    const modal = getModalInstance()

    try {
        const response = await PortOne.requestPayment({
            // Store ID 설정
            storeId: process.env.PORTONE_STORE_ID!,
            // 채널 키 설정
            channelKey: process.env.KG_INICIS_KEY!,
            paymentId: orderCode,
            // 주문목록
            orderName: orderName,
            // 주문가격
            totalAmount:  totalAmount,

            currency: 'CURRENCY_KRW',
            payMethod: 'CARD',

            // 유저정보
            customer: {
                email: user.email!,
                phoneNumber: user.phoneNumber!,
                fullName: user.userName!,
            },

            redirectUrl: `${process.env.REDIRECT_URL}`,
        })

        if (response && response.code != null) {
            modal?.alert(() => {}, '알림', '결제가 취소되었습니다.')
            return;
        }

        await orderWebHookService(response!)
    } catch (e) {
        if (e instanceof Error && e.message.includes('이미 결제된 id입니다.')) {
            modal?.alert(
                () => {
                    window.location.href = '/user/mypage/orders'
                },
                '알림',
                '이미 결제된 주문입니다.'
            )
        }
    }
}
