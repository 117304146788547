import React, { useEffect } from 'react'
import './InquiryPage.css'
import { InquiryList } from '@Component/board/List/InquiryList'

function InquiryPage() {
    useEffect(() => {
        document.title = 'BROSPO 상품문의'
    }, [])
    return (
        <div className={'inquiry-page'}>
            <h2 className="page-title">상품 문의</h2>
            <InquiryList productId={null} />
        </div>
    )
}

export default InquiryPage
