import React from 'react'
import './AdminProductCategoryPage.css'

import { Category } from '@Interface/Category'
import {
    PrimaryCategoryEditService,
    PrimaryCategoryInsertService,
    PrimaryCategoryRemoveService,
    SecondCategoryEditService,
    SecondCategoryInsertService, SecondCategoryOrderEditService,
    SecondCategoryRemoveService,
} from '@Service/admin/category/CategoryService'

export const AdminProductCategoryPage = (props: {
    categoryList: { [p: string]: Category[] }
}) => {
    const categoryList = props.categoryList

    return (
        <div className={'category-edit'}>
            <h2 className={'page-title'}>카테고리 수정</h2>
            <div className={'category-edit-form'}>
                {Object.keys(categoryList).map((primaryCategory, index) => (
                    <div className={'category'} key={index}>
                        <div className={'primary-category'}>
                            <p id={'primary' + primaryCategory}>
                                {primaryCategory}
                            </p>
                            <PrimaryCategoryEditService
                                category={primaryCategory}
                            />
                            <PrimaryCategoryRemoveService
                                category={primaryCategory}
                            />
                        </div>
                        {categoryList[primaryCategory].map(
                            (category, index) => (
                                <div
                                    className={'secondary-category'}
                                    id={category.secondaryName + 'group'}
                                    key={index}
                                >
                                    <p
                                        id={
                                            category.categoryId
                                                ? 'second' +
                                                  category.categoryId.toString()
                                                : ''
                                        }
                                    >
                                        {category.secondaryName}
                                    </p>
                                    <SecondCategoryOrderEditService
                                        category={category}
                                    />
                                    <SecondCategoryEditService
                                        category={category}
                                    />
                                    <SecondCategoryRemoveService
                                        category={category}
                                    />
                                </div>
                            )
                        )}
                        <SecondCategoryInsertService
                            category={primaryCategory}
                        />
                    </div>
                ))}
                <PrimaryCategoryInsertService />
            </div>
        </div>
    )
}
