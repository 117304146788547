import React, { useState, useEffect } from 'react'

import { Board } from '@Interface/Board'

import {
    deleteBoardService,
    getBoardService,
    saveAnswerService,
} from '@Service/user/board/BoardService'
import { ProductListItem } from '@Component/product/ProductList/ProductList'
import { insertBoard } from '@Util/BoardUtils'
import { formatDate, privacyName, stripHTMLTags } from '@Util/StringUtils'
import { SizeSelector } from '@Component/product/ProductInfo/SizeSelector'
import { ImageViewList } from '../../common/Widgets/Image/ImageView'
import './BoardView.css'
import { getModalInstance } from '@Component/common/Widgets/Modal/ModalProvider'
import { InquiryEditor } from '../Edit/InquiryEditor'
import { setWishicon } from '@Util/WishUtils'
import { Editor } from '@Component/common/Widgets/Editor/Editor'
import { Answer, AnswerDTO } from '@Interface/Answer'
import { UserInfoView } from '@Component/mypage/account/MypageAccount'

export const NoticeView = (props: { boardId: string }) => {
    const modal = getModalInstance()

    const [fileMap, setFileMap] = useState<Map<number, File>>(new Map())
    const [tempFileMap, setTempFileMap] = useState<Map<number, File>>(new Map())
    const [reviewRating, setReviewRating] = useState(0)
    const [board, setBoard] = useState<Board | undefined>(undefined)
    const [imageUrl, setImageUrl] = useState<string[]>([])
    const fetchBoard = async () => {
        if (props.boardId) {
            const boardDetail = await getBoardService(props.boardId)
            if (boardDetail) {
                setBoard(boardDetail.board)
                setImageUrl(boardDetail.imageUrl)
                setWishicon()
            }
        }
    }

    useEffect(() => {
        fetchBoard()
    }, [props.boardId])

    return (
        <div className="board-view">
            <ProductListItem product={board?.product} />

            <h2>
                공지 사항
            </h2>

            <label>
                공지 제목
                <div className="board-info">
                    <span>작성자 : 브로스포</span>
                    <span>작성일 : {formatDate(board?.createDate!)}</span>
                </div>
            </label>
            <div className="textarea">{board?.title}</div>

            <label>공지 내용</label>
            <div
                dangerouslySetInnerHTML={{
                    __html: stripHTMLTags(board?.content || ''),
                }}
                className="textarea content"
            ></div>
        </div>
    )
}
