// src/api.ts
import { HttpAlert, HttpSuccess } from '@Interface/HttpCallBack'
import { DeliveryState } from '@Interface/Order'
import { getRequest } from '@Service/Api'
import axios from 'axios'

// HTTP 요청을 위한 함수
export const getTracking = async (
    carrierId: string,
    trackingNumber: string
) => {
    const httpSuccess: HttpSuccess = {}
    const httpAlert: HttpAlert = {}
    try {
        return await getRequest<DeliveryState[]>(
            `/tracking?carrierId=${carrierId}&trackingNumber=${trackingNumber}`,
            httpSuccess,
            httpAlert,
            {}
        )
    } catch (e) {
        console.log(e)
        return false
    }
}
