import { AdminOrderList } from '@Component/order/OrderList/OrderList'
import './AdminOrderPage.css'
import React from 'react'
import {OrderTabBar} from "@Component/order/TabBar/OrderTabBar";

export const AdminOrderPage = () => {
    return (
        <div className="admin-order-page">
            <OrderTabBar/>
            <AdminOrderList />
        </div>
    )
}
