export interface Category {
    categoryId: number | null
    primaryName: string
    secondaryName: string
    menuOrderPm?:number
    menuOrderSd?:number
}

export interface CategoryDTO {
    beforePrimaryName?: string
    afterPrimaryName?: string
    menuOrder ?: number
}

export const findCategoryById = (
    categoryList: { [p: string]: Category[] },
    primary: string,
    secondary
        :
        string
) => {
    const categories = categoryList[primary]

    if (categories) {
        return (
            categories.find(
                (category) => category.secondaryName === secondary
            ) || null
        )
    }
    return null
}
