import { checkCartItem } from '@Component/cart/CartList.css/CartList'
import './OrderPrice.css'
import { CartDTO } from '@Interface/Cart'
import { deleteCartService } from '@Service/user/cart/CartService'
import { getModalInstance } from '@Component/common/Widgets/Modal/ModalProvider'
import { updateOrderPrice } from '@Util/OrderUtils'
import {
    bankNames,
    OrderItem,
    responsePaymentCustom,
    TransformedOrder,
} from '@Interface/Order'
import {
    insertOrderService,
    orderWebHookService,
} from '@Service/user/order/OrderService'
import React, { useEffect, useRef, useState } from 'react'
import { onClickPayment } from '@Util/PaymentUtils'
import { UserInfo } from '@Interface/User'
import { Address } from '@Interface/Address'

export const CartPrice = () => {
    const modal = getModalInstance()

    const selectCartRemove = () => {
        const cartCheckBox = document.querySelectorAll(`[id^="select-cart-"]`)
        if (cartCheckBox.length == 0) {
            modal?.alert(() => {}, '알림', '장바구니에 상품이 없습니다.')
            return
        }

        const isChecked = Array.from(cartCheckBox).some(
            (checkbox) => (checkbox as HTMLInputElement).checked
        )

        if (!isChecked) {
            modal?.alert(
                () => {},
                '알림',
                '하나 이상의 항목을 선택해야 합니다.'
            )

            return
        }

        modal?.confirm(
            () => {
                cartCheckBox.forEach((checkBox) => {
                    const productId = checkBox.id.replaceAll('select-cart-', '')

                    if ((checkBox as HTMLInputElement).checked) {
                        const deleteCartArray: CartDTO[] = []

                        const countElList = document.querySelectorAll(
                            `[id^="size-count-${productId}-"]`
                        )

                        countElList.forEach((countEl) => {
                            const size = countEl.id.replace(
                                `size-count-${productId}-`,
                                ''
                            )
                            deleteCartArray.push({
                                productId: productId!,
                                size: size,
                                count: '',
                            })
                        })

                        deleteCartService(deleteCartArray)
                        ;(
                            document.getElementById(
                                `cart-item-${productId}`
                            ) as HTMLElement
                        ).remove()
                        checkCartItem()
                    }
                })
                updateOrderPrice()
            },
            () => {},
            '확인',
            '선택 상품을 삭제하시겠습니까?'
        )
    }

    return (
        <div className={'order-price'}>
            <div className={'order-price-info'}>
                <div className="order-button">
                    <input
                        className="bg-white-button"
                        type="button"
                        value="전체선택"
                        onClick={() => {
                            const cartCheckBox =
                                document.querySelectorAll(
                                    `[id^="select-cart-"]`
                                )
                            const isChecked = Array.from(cartCheckBox).every(
                                (checkbox) =>
                                    (checkbox as HTMLInputElement).checked
                            )

                            if (!isChecked) {
                                cartCheckBox.forEach((checkBox) => {
                                    ;(checkBox as HTMLInputElement).checked =
                                        true
                                })
                            } else {
                                cartCheckBox.forEach((checkBox) => {
                                    ;(checkBox as HTMLInputElement).checked =
                                        false
                                })
                            }

                            updateOrderPrice()
                        }}
                    />
                    <input
                        className="bg-white-button"
                        type="button"
                        value="선택상품제거"
                        onClick={selectCartRemove}
                    />
                    <input
                        className="bg-black-button"
                        type="button"
                        value="주문하기"
                        onClick={cartCheckOut}
                    />
                </div>
                <div>
                    총 상품가격 <span id="order-cart-price">0 원</span>
                </div>
                <div>
                    배송비 <span id="order-cart-delivery-fee">+ 0 원</span>
                </div>
                <div className="total-price">
                    총 결제금액
                    <span id="order-cart-total-price">0 원</span>
                </div>
            </div>
        </div>
    )
}

export const OrderPrice = (props: {
    orderItemList: TransformedOrder[]
    userInfo: UserInfo | undefined
    orderCode: string
    orderName: string
    address: Address | undefined
}) => {
    const modal = getModalInstance()
    const [totalPrice, setTotalPrice] = useState<number>(0)

    const [deliveryFee, setDeliveryFee] = useState<number>(0)

    const [paymentType, setPaymentType] = useState<string>('')
    const [bank, setBank] = useState<string>('')
    const accountHolder = useRef('')
    const accountNumber = useRef('')
    useEffect(() => {
        let tempTotalPrice = 0
        let tempDeliveryFee = 100000
        props.orderItemList.map((orderItem) => {
            let tempPrice = 0
            orderItem.sizes.map((size) => {
                tempPrice +=
                    parseInt(size.count) * parseInt(orderItem.product.price!)
            })
            tempTotalPrice += tempPrice
            tempDeliveryFee = Math.min(
                tempDeliveryFee,
                parseInt(orderItem.product.deliveryFee!)
            )
        })

        setTotalPrice(tempTotalPrice)
        setDeliveryFee(tempDeliveryFee)
    }, [props])
    return (
        <div className={'order-price'}>
            <div className={'order-price-info'}>
                <div className="order-button"></div>
                <div>
                    총 상품가격{' '}
                    <span id="order-cart-price">
                        {totalPrice.toLocaleString()} 원
                    </span>
                </div>
                <div>
                    배송비{' '}
                    <span id="order-cart-delivery-fee">
                        + {deliveryFee.toLocaleString()} 원
                    </span>
                </div>
                <div className="total-price">
                    총 결제금액
                    <span id="order-cart-total-price">
                        {(deliveryFee + totalPrice).toLocaleString()} 원
                    </span>
                </div>

                <select
                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                        setPaymentType(event.target.value)
                    }}
                >
                    <option value={''}>결제수단을 선택해주세요.</option>
                    <option value={'ACCOUNT'}>계좌이체</option>
                    <option value={'CARD'}>신용카드/간편결제</option>
                </select>

                {paymentType == 'ACCOUNT' && (
                    <>
                        <select
                            onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                setBank(event.target.value)
                            }}>
                            <option>은행 선택</option>
                            {bankNames.map((name)=>(
                                <option value={name}>{name}</option>
                            ))}
                        </select>
                        <input
                            type={'text'}
                            placeholder={'예금주'}
                            onInput={(
                                event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                                accountHolder.current = event.target.value
                            }}
                        />
                        <input
                            type={'text'}
                            placeholder={'계좌 번호'}
                            onInput={(
                                event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                                accountNumber.current = event.target.value
                            }}
                        />
                    </>
                )}

                <input
                    className={'bg-black-button'}
                    type={'button'}
                    value={'결제하기'}
                    onClick={() => {
                        if (!props.address!) {
                            modal?.alert(
                                () => {},
                                '알림',
                                '배송지를 선택해주세요.'
                            )
                            return
                        }

                        if (paymentType == '') {
                            modal?.alert(
                                () => {},
                                '알림',
                                '결제수단을 선택해주세요.'
                            )
                            return
                        }

                        if (bank == '' && paymentType != 'CARD') {
                            modal?.alert(
                                () => {},
                                '알림',
                                '송금은행을 선택해주세요'
                            )
                            return
                        }

                        if (paymentType == 'CARD') {
                            onClickPayment({
                                user: props.userInfo!,
                                totalAmount: totalPrice + deliveryFee,
                                orderCode: props.orderCode,
                                orderName: props.orderName,
                            })
                        } else {
                            if (accountHolder.current == '') {
                                modal?.alert(
                                    () => {},
                                    '알림',
                                    '예금주를 입력해주세요'
                                )
                                return
                            }
                            if (accountNumber.current == '') {
                                modal?.alert(
                                    () => {},
                                    '알림',
                                    '계좌번호를 입력해주세요'
                                )
                                return
                            }
                            modal?.confirm(
                                () => {
                                    const parma: responsePaymentCustom = {
                                        accountHolder: accountHolder.current,
                                        accountNumber:bank + ' '+ accountNumber.current,
                                        paymentId: props.orderCode,
                                        transactionType: 'ACCOUNT',
                                    }
                                    orderWebHookService(parma)
                                },
                                () => {},
                                '알림',
                                '주문하시겠습니까?',
                                `예금주와 계좌번호를 다시한번 확인해주세요.<br/><br/>예금주 : ${accountHolder.current}<br/>계좌번호 : ${bank + ' '+ accountNumber.current}`
                            )
                        }
                    }}
                />
            </div>
        </div>
    )
}

export const cartCheckOut = async () => {
    const modal = getModalInstance()
    const cartCheckBox = document.querySelectorAll(`[id^="select-cart-"]`)

    const orderList: OrderItem[] = []

    cartCheckBox.forEach((checkBox) => {
        if ((checkBox as HTMLInputElement).checked) {
            const productId = checkBox.id.replace('select-cart-', '')
            const sizeCountList = document.querySelectorAll(
                `[id^="size-count-${productId}-"]`
            )

            sizeCountList.forEach((sizeCount) => {
                const size = sizeCount.id.replace(
                    `size-count-${productId}-`,
                    ''
                )
                const count = (
                    document.getElementById(
                        `size-count-${productId}-${size}`
                    ) as HTMLInputElement
                ).value
                const orderItem: OrderItem = {
                    product: {
                        productId: productId!,
                        category: null,
                        name: null,
                        price: null,
                        deliveryFee: null,
                        productCode: null,
                        productDescription: null,
                        size: null,
                    },
                    size: size,
                    count: count,
                }

                orderList.push(orderItem)
            })
        }
    })

    if (orderList.length == 0) {
        modal?.alert(() => {}, '알림', '상품을 선택해주세요')
        return
    }

    await insertOrderService(orderList)
}
