import { useEffect, useState } from 'react'
import './ViewProduct.css'
import { Product } from '@Interface/Product'
import { getWishListService } from '@Service/user/wish/WishService'
import { ProductListItem } from '../ProductList/ProductList'
import { setWishicon } from '@Util/WishUtils'
import { getViewProduct } from '@Util/ProductUtils'
import { ProductService } from '@Service/user/product/ProductService'

export const ViewProdcut = () => {
    const [productList, setProductList] = useState<Product[]>([])
    const fetchData = async () => {
        const tempList: Product[] = []
        const productIdList = getViewProduct()

        for (const productId of productIdList) {
            const product = await ProductService(productId)
            if (product) {
                tempList.push(product.product)
            }
        }

        setProductList(tempList)
        setWishicon()
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <div className="view-product">
            <h2>최근 본 상품</h2>
            {productList?.map((product) => (
                <ProductListItem key={product.productId} product={product} />
            ))}
            {productList.length == 0 && (
                <div className="empty-view-product">
                    최근 본 상품이 없습니다.
                </div>
            )}
        </div>
    )
}
