import React, { useEffect, useRef, useState } from 'react';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import './3dCnvas.css';

const CanvasView = (props: { imageUrl: string, model: string }) => {
    const mountRef = useRef<HTMLDivElement>(null);
    const [model, setModel] = useState<THREE.Group | null>(null);
    const [camera, setCamera] = useState<THREE.PerspectiveCamera | null>(null);
    const [controls, setControls] = useState<OrbitControls | null>(null);
    const [renderer, setRenderer] = useState<THREE.WebGLRenderer | null>(null);
    const [defaultPosition, setDefaultPosition] = useState<THREE.Vector3>(new THREE.Vector3());

    // 텍스처를 로드하는 함수
    const loadTexture = (url: string): THREE.Texture => {
        const textureLoader = new THREE.TextureLoader();
        const texture = textureLoader.load(url);
        texture.wrapS = THREE.RepeatWrapping;
        texture.wrapT = THREE.RepeatWrapping;
        texture.colorSpace = THREE.SRGBColorSpace;
        return texture;
    };

    useEffect(() => {
        if (!props.imageUrl || !props.model || !mountRef.current) return;

        // 이전 인스턴스 정리
        while (mountRef.current.firstChild) {
            mountRef.current.removeChild(mountRef.current.firstChild);
        }

        // 장면(Scene), 카메라(Camera), 렌더러(Renderer) 초기화
        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(70, 1, 0.1, 10000);
        setCamera(camera);

        const renderer = new THREE.WebGLRenderer({ antialias: true });
        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.setSize(mountRef.current.clientHeight, mountRef.current.clientHeight);
        renderer.setClearColor(0xd6d6d6);
        renderer.shadowMap.enabled = true;
        renderer.shadowMap.type = THREE.PCFSoftShadowMap;
        mountRef.current.appendChild(renderer.domElement);
        setRenderer(renderer);

        // 조명과 모델 URL 설정
        let modelUrl = '';
        let position = new THREE.Vector3();
        const directionalLight1 = new THREE.DirectionalLight(0xffffff, 2);
        directionalLight1.position.set(10, 3, 100).normalize();
        scene.add(directionalLight1);

        const directionalLight2 = new THREE.DirectionalLight(0xffffff, 2);
        directionalLight2.position.set(10, 3, -100).normalize();
        scene.add(directionalLight2);

        const ambientLight = new THREE.AmbientLight(0xffffff, 1);
        scene.add(ambientLight);

        if (props.model.includes('BasketBall')) {
            position = new THREE.Vector3(50, 5.487315331413983, 74.36926315307615);
            modelUrl = 'https://raw.githubusercontent.com/SIROBAKO/cdnFiles/main/models/model.gltf';
        } else if (props.model.includes('T-Shirt')) {
            position = new THREE.Vector3(-0.2, 0.4, 0.6);
            modelUrl = 'https://raw.githubusercontent.com/SIROBAKO/cdnFiles/main/models/model2.gltf';
        } else if (props.model.includes('Shirt')) {
            position = new THREE.Vector3(-0.2, 0.1, 0.7);
            modelUrl = 'https://raw.githubusercontent.com/SIROBAKO/cdnFiles/main/models/model3.gltf';
        } else if (props.model.includes('HoodT')) {
            position = new THREE.Vector3(0.8, 0.1, 0.3);
            modelUrl = 'https://raw.githubusercontent.com/SIROBAKO/cdnFiles/main/models/model4.gltf';
        }
        setDefaultPosition(position);

        // GLTF 모델 로드
        const loader = new GLTFLoader();
        loader.load(
            modelUrl,
            (gltf) => {
                const model = gltf.scene;
                scene.add(model);
                setModel(model);

                // 모델 크기와 위치 조정
                const box = new THREE.Box3().setFromObject(model);
                const size = new THREE.Vector3();
                box.getSize(size);
                const center = new THREE.Vector3();
                box.getCenter(center);
                model.position.sub(center);

                camera.position.set(position.x, position.y, position.z);
                camera.updateProjectionMatrix();

                // 텍스처 로드
                const texture = loadTexture(props.imageUrl);
                model.traverse((child) => {
                    if (child instanceof THREE.Mesh) {
                        const material = child.material as THREE.MeshStandardMaterial;
                        if (material.map) {
                            material.map.dispose(); // 기존 텍스처 해제
                        }
                        material.map = texture;
                        material.roughness = 0.6; // 표면 매끄럽게 조정
                        material.metalness = 0.1; // 금속성 조정
                        material.needsUpdate = true; // 재질 업데이트
                    }
                });
            },
            undefined,
            (error) => {
                console.error('GLTF 모델 로드 중 오류 발생:', error);
                console.log(modelUrl)
                console.log(props.model)
            }
        );

        // OrbitControls 설정
        const controls = new OrbitControls(camera, renderer.domElement);
        controls.enableDamping = true;
        controls.dampingFactor = 0.25;
        controls.enableZoom = false;
        controls.enablePan = false;
        controls.rotateSpeed = 1.0;
        controls.enableRotate = true;
        setControls(controls);

        // 애니메이션 루프
        const animate = () => {
            if (controls) controls.update();
            renderer.render(scene, camera);
            requestAnimationFrame(animate);
        };
        animate();

        // 창 크기 조절 시 처리
        const handleResize = () => {
            if (camera && renderer) {
                const width = mountRef.current!.clientHeight;
                const height = mountRef.current!.clientHeight;
                renderer.setSize(width, height);
                camera.updateProjectionMatrix();
            }
        };
        window.addEventListener('resize', handleResize);

        return () => {
            // 모델 삭제
            if (model) {
                scene.remove(model);
                model.traverse((child) => {
                    if (child instanceof THREE.Mesh) {
                        child.geometry.dispose();
                        if (Array.isArray(child.material)) {
                            child.material.forEach((material) => {
                                if (material.map) material.map.dispose();
                                material.dispose();
                            });
                        } else {
                            if (child.material.map) child.material.map.dispose();
                            child.material.dispose();
                        }
                    }
                });
                setModel(null); // 모델 참조 제거
            }
            // 렌더러 해제
            if (renderer) {
                renderer.dispose();
                setRenderer(null); // 렌더러 참조 제거
            }
            // 컨트롤 해제
            if (controls) {
                controls.dispose();
                setControls(null); // 컨트롤 참조 제거
            }
            setCamera(null); // 카메라 참조 제거
            window.removeEventListener('resize', handleResize);
            if (mountRef.current) {
                while (mountRef.current.firstChild) {
                    mountRef.current.removeChild(mountRef.current.firstChild);
                }
            }
        };
    }, [props.imageUrl, props.model]);

    const handleModelRotation = () => {
        if (model) {
            camera?.position.set(defaultPosition.x, defaultPosition.y, defaultPosition.z);
            camera?.updateProjectionMatrix();
            model.rotation.y += Math.PI / 2; // 각도는 조절 가능
        }
    };

    return (
        <>
            <div ref={mountRef} className="view-canvas">
                <input
                    type="button"
                    value="↺"
                    className="bg-white-button"
                    onClick={handleModelRotation}
                />
            </div>
            <span style={{ fontSize: 'var(--font-size-0-9)', color: 'blue' }}>
                3D 이미지와 실제상품은 차이가 있을 수 있습니다.
            </span>
        </>
    );
};

export default CanvasView;
