import { Link, useLocation } from 'react-router-dom'
import './MypageTabBar.css'

export const MyPageTabBar = () => {
    const location = useLocation()
    const path = location.pathname
    return (
        <div className={'tab'}>
            <Link
                className={
                    path.includes('/user/mypage/account') ? 'active' : ''
                }
                to="/user/mypage/account"
            >
                계정관리
            </Link>
            <Link
                className={path.includes('/user/mypage/orders') ? 'active' : ''}
                to="/user/mypage/orders"
            >
                주문확인
            </Link>
            <Link
                className={
                    path.includes('/user/mypage/activity') ? 'active' : ''
                }
                to="/user/mypage/activity"
            >
                활동 및 문의
            </Link>
        </div>
    )
}
