export const updateOrderPrice = () => {
    const location = window.location.href
    if (!location.includes('order')) {
        return
    }
    const cartCheckBox = document.querySelectorAll(`[id^="select-cart-"]`)

    let totalPrice = 0
    let deliveryFee = 99999999

    const isChecked = Array.from(cartCheckBox).some(
        (checkbox) => (checkbox as HTMLInputElement).checked
    )

    if (!isChecked) {
        ;(
            document.getElementById('order-cart-price') as HTMLElement
        ).innerText = `0 원`
        ;(
            document.getElementById('order-cart-delivery-fee') as HTMLElement
        ).innerText = `+ 0 원`
        ;(
            document.getElementById('order-cart-total-price') as HTMLElement
        ).innerText = `0 원`
        return
    }
    cartCheckBox.forEach((checkBox) => {
        const productId = checkBox.id.replaceAll('select-cart-', '')

        if ((checkBox as HTMLInputElement).checked) {
            const tempPrice = parseInt(
                (
                    document.getElementById(
                        `cart-item-price-${productId}`
                    ) as HTMLElement
                ).innerText
                    .replaceAll(',', '')
                    .replace('총', '')
            )

            const tempDeliveryFee = parseInt(
                (
                    document.getElementById(
                        `product-delivery-fee-${productId}`
                    ) as HTMLInputElement
                ).value
            )
            totalPrice += tempPrice
            deliveryFee = Math.min(tempDeliveryFee, deliveryFee)
        }
    })
    ;(
        document.getElementById('order-cart-price') as HTMLElement
    ).innerText = `${totalPrice.toLocaleString()} 원`
    ;(
        document.getElementById('order-cart-delivery-fee') as HTMLElement
    ).innerText = `+ ${deliveryFee.toLocaleString()} 원`
    ;(
        document.getElementById('order-cart-total-price') as HTMLElement
    ).innerText = `${(deliveryFee + totalPrice).toLocaleString()} 원`
}
