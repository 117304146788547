import React, { useEffect, useRef, useState } from 'react'
import './ProductDetail.css'
import { useParams } from 'react-router-dom'
import { Product } from '@Interface/Product'
import {
    ProductService,
} from '@Service/user/product/ProductService'

import {
    AdminProductService,
} from '@Service/admin/product/AdminProductService'



import { ProductInfo } from '@Component/product/ProductInfo/ProductInfo'
import * as process from 'process'

import { InquiryList } from '@Component/board/List/InquiryList'

import { throttle } from 'lodash' // lodash의 throttle 함수 사용
import { ProductPeriod } from '@Component/common/View/Document/Period/ProductPeriod'
import { ReviewList } from '@Component/board/List/ReviewList'
import { getModalInstance } from '@Component/common/Widgets/Modal/ModalProvider'
import { setWishicon } from '@Util/WishUtils'
import { setViewProduct } from '@Util/ProductUtils'
import CanvasView from "@Component/common/Widgets/3D/3dCnvas";

type Section =
    | 'product-main'
    | 'product-review'
    | 'product-inquiry'
    | 'product-period'

function ProductDetail() {
    const { productId } = useParams<{ productId: string }>()
    const [product, setProduct] = useState<Product | undefined>()
    const [imageUrl, setImageUrl] = useState<string[]>([])
    const SectionList = [
        'product-main',
        'product-review',
        'product-inquiry',
        'product-period',
    ]
    const isClick = useRef<boolean>()

    const handleClick = (
        section: Section,
        event: React.MouseEvent<HTMLElement>
    ) => {
        isClick.current = true
        const element = document.getElementById(section)
        const menuHeight = document
            .querySelectorAll('.product-menu')
            .item(0).clientHeight
        if (element) {
            const topOffset =
                element.getBoundingClientRect().top +
                window.scrollY -
                menuHeight
            window.scrollTo({
                top: topOffset, // 100px 위로 스크롤
                behavior: 'smooth', // 부드럽게 스크롤
            })

            // 'select-menu' 클래스를 가진 모든 요소에서 클래스 제거
            document
                .querySelectorAll('.select-menu')
                .forEach((el) => el.classList.remove('select-menu'))

            // 클릭된 요소에 'select-menu' 클래스 추가
            event.currentTarget.classList.add('select-menu')
            const scrollPosition = window.scrollY + window.innerHeight / 2
            const offsetTop = document.getElementById(section)!.offsetTop
            const lockTime = Math.abs(offsetTop - scrollPosition) * 2
            setTimeout(() => {
                isClick.current = false
            }, lockTime)
        }
    }

    const handleScroll = throttle(() => {
        const menuHeight = document
            .querySelectorAll('.product-menu')
            .item(0).clientHeight
        const scrollPosition = window.scrollY + window.innerHeight / 2

        let productInfoVisible = false
        const rect = document
            .querySelectorAll('.product-info')
            .item(0)
            .getBoundingClientRect()
        const offsetTop = rect.top + window.scrollY - menuHeight
        const offsetBottom = rect.bottom + window.scrollY - menuHeight

        // Check if any part of the element is visible
        if (offsetBottom > window.scrollY) {
            productInfoVisible = true
        }

        const headerElement = document.querySelector('header')
        if (headerElement) {
            if (!productInfoVisible) {
                headerElement.style.transform = 'translateY(-100%)'
                headerElement.style.transition = 'transform 0.3s ease-in-out'
            } else {
                headerElement.style.transform = 'translateY(0)'
                headerElement.style.transition = 'transform 0.3s ease-in-out'
            }
        }

        for (const index in SectionList) {
            const element = document.getElementById(SectionList[index])
            if (element) {
                const offsetTop =
                    element.getBoundingClientRect().top + window.scrollY
                const offsetBottom =
                    element.getBoundingClientRect().bottom + window.scrollY
                if (
                    scrollPosition > offsetTop &&
                    scrollPosition < offsetBottom &&
                    !isClick.current
                ) {
                    document
                        .querySelectorAll('.select-menu')
                        .forEach((el) => el.classList.remove('select-menu'))
                    document.getElementById(
                        SectionList[index] + '-menu'
                    )!.className = 'select-menu'
                    break
                }
            }
        }
    }, 100) // Executes every 200ms

    const fetchProduct = async () => {
        const modal = getModalInstance()
        if (productId) {
            if (productId == '1') {
                const result = await AdminProductService(productId)

                if (result) {
                    setProduct(result.product)
                    setImageUrl(result.imageUrl)
                    document.title = '상품 : ' + result.product.name
                }
            } else {
                const result = await ProductService(productId)
                if (result) {
                    setProduct(result.product)
                    setImageUrl(result.imageUrl)
                    document.title = '상품 : ' + result.product.name
                } else {
                    modal?.alert(
                        () => {
                            window.location.href = '/'
                        },
                        '알림',
                        '삭제되거나 없는 상품입니다.'
                    )
                }
            }
        }
    }

    useEffect(() => {
        fetchProduct()
        window.addEventListener('scroll', handleScroll)

        setViewProduct(productId!)
        return () => {
            window.removeEventListener('scroll', handleScroll)

            const headerElement = document.querySelector('header')!
            headerElement.style.transform = 'translateY(0)'
            headerElement.style.transition = 'transform 0.3s ease-in-out'
        }
    }, [productId])

    useEffect(() => {
        if (product) {
            setWishicon()
        }
    }, [product])

    const ProductMenu = () => {
        return (
            <div className={'product-menu'}>
                <div
                    id={'product-main-menu'}
                    className={'select-menu'}
                    onClick={(event) => handleClick('product-main', event)}
                >
                    상품설명
                </div>
                <div
                    id={'product-review-menu'}
                    onClick={(event) => handleClick('product-review', event)}
                >
                    상품리뷰
                </div>
                <div
                    id={'product-inquiry-menu'}
                    onClick={(event) => handleClick('product-inquiry', event)}
                >
                    상품문의
                </div>
                <div
                    id={'product-period-menu'}
                    onClick={(event) => handleClick('product-period', event)}
                >
                    배송안내
                </div>
            </div>
        )
    }


    const ProductMain = () => {
        // 개선된 splitStringAndExtractSrc 함수 사용
        const parts = splitStringAndExtractSrc(product?.productDescription || '');

        return (
            <div className='product-main' id='product-main'>
                {parts.map((part, index) => {
                    if (part.type === 'text') {
                        return (
                            <div
                                key={index}
                                dangerouslySetInnerHTML={{
                                    __html: part.content?.replaceAll('/product/',process.env.PRODUCT_IMAGE_BASE_URL!)||'',
                                }}
                            />
                        );
                    } else if (part.type === 'image') {
                        return (
                            <CanvasView
                                key={index}
                                imageUrl={part.src||''}
                                model={part.alt||''}
                            />
                        );
                    }
                    return null;
                })}
            </div>
        );
    }

    const ProductFooter = () => {
        return (
            <div className={'product-footer'}>
                <div id="product-review">
                    <h4>상품 리뷰</h4>
                    <h5>상품 사용 후기입니다.</h5>
                    <ReviewList productId={productId!}/>
                </div>
                <div id="product-inquiry">
                    <h4>상품 문의</h4>
                    <h5>상품 Q&A입니다.</h5>
                    <InquiryList productId={productId!}  />
                </div>
                <div id="product-period">
                    <ProductPeriod />
                </div>
            </div>
        )
    }

    return (
        <div className={'product-detail'}>
            <ProductInfo product={product} imageUrl={imageUrl} />
            <ProductMenu />
            <ProductMain />
            <ProductFooter />
        </div>
    )
}

export default ProductDetail


function splitStringAndExtractSrc(inputString:string) {
    const imgTagRegex = /<img[^>]*style="[^"]*width:\s*25%;[^"]*"[^>]*>/g;
    const srcRegex = /src="([^"]*)"/;
    const altRegex = /alt="([^"]*)"/;

    let match;
    const parts = [];
    let lastIndex = 0;

    // 모든 <img> 태그를 찾기
    while ((match = imgTagRegex.exec(inputString)) !== null) {
        const imgTag = match[0];
        const startIndex = match.index;
        const endIndex = startIndex + imgTag.length;

        // <img> 태그에서 src 값 추출
        const srcMatch = imgTag.match(srcRegex);
        const srcValue = srcMatch ? srcMatch[1].replace('/product/', process.env.PRODUCT_IMAGE_BASE_URL!) : '';

        // <img> 태그에서 alt 값 추출
        const altMatch = imgTag.match(altRegex);
        const altValue = altMatch ? altMatch[1] : '';

        // 이전 문자열 추가
        if (startIndex > lastIndex) {
            parts.push({
                type: 'text',
                content: inputString.substring(lastIndex, startIndex),
            });
        }

        // 이미지 태그 추가
        parts.push({
            type: 'image',
            src: srcValue,
            alt: altValue,
        });

        lastIndex = endIndex;
    }

    // 나머지 문자열 추가
    if (lastIndex < inputString.length) {
        parts.push({
            type: 'text',
            content: inputString.substring(lastIndex),
        });
    }

    return parts;
}
