import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import './OrderComplete.css'
import React, { useEffect, useState } from 'react'
import {Order, OrderDTO, OrderItem, TransformedOrder} from '@Interface/Order'
import { getOrderService } from '@Service/user/order/OrderService'
import { decodeHTML } from '@Util/StringUtils'
import { getModalInstance } from '@Component/common/Widgets/Modal/ModalProvider'
import {OrderListItem} from "@Component/product/ProductList/ProductList";

export const OrderComplete = () => {
    const modal = getModalInstance()
    const navigate = useNavigate()

    const [searchParams] = useSearchParams()
    const orderCode = searchParams.get('orderCode')

    const [order, setOrder] = useState<Order>()
    const [orderItemList, setOrderItemList] = useState<TransformedOrder[]>()
    const fetchData = async () => {
        if (!orderCode) {
            return
        }
        const orderDTO = await getOrderService(orderCode)
        setOrder(orderDTO?.order)

        const transformedCartList: TransformedOrder[] = []

        orderDTO?.orderItemList.forEach((orderItem) => {
            if (
                !transformedCartList.some(
                    (item) =>
                        item.product.productId === orderItem.product.productId
                )
            ) {
                transformedCartList.push({
                    product: {
                        productId: orderItem.product.productId,
                        category: orderItem.product.category,
                        name: orderItem.product.name,
                        price: orderItem.product.price,
                        deliveryFee: orderItem.product.deliveryFee,
                        productCode: orderItem.product.productCode,
                        productDescription: null,
                        size: null,
                    },
                    sizes: [],
                })
            }

            transformedCartList
                .find(
                    (item) =>
                        item.product.productId === orderItem.product.productId
                )
                ?.sizes.push({
                size: orderItem.size,
                count: orderItem.count,
            })
        })

        setOrderItemList(transformedCartList)
    }

    useEffect(() => {
        fetchData()
    }, [])
    useEffect(() => {
        const status = ['입금대기', '결제완료']
        if (order && !status.includes(order?.orderState || '')) {
            modal?.alert(
                () => {
                    navigate('/')
                },
                '경고',
                '잘못된 접근입니다.'
            )
        }
    }, [order])

    return (
        <div className="order-complete">
            <h2>감사합니다 주문이 완료되었습니다.</h2>
            <p>
                주문번호 : <span>{orderCode}</span>
            </p>
            {order?.paymentType == '계좌이체' && (
                <>
                    <div>
                        <label>입금계좌안내</label>
                        <p>신한 110-454-160504 손영욱</p>
                        <hr/>
                        <label>송금계좌</label>
                        <p>
                            {order?.accountNumber} {order?.accountHolder}
                        </p>
                    </div>
                </>
            )}
            <div className="order-item-info">
                <h3>
                    <p>주문목록</p>
                </h3>
                {orderItemList?.map((orderItem, index) => (
                    <div className="order-product-item" key={index}>
                        <OrderListItem product={orderItem.product!}/>
                        <div className="order-product-count">
                            <div>수량 :</div>
                            {orderItem.sizes?.map((size, index) => (
                                <div key={index}>
                                    {size.size.toUpperCase()} / {size.count}
                                    {index != orderItem.sizes.length - 1 &&
                                        ' ,'}
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
            <div>
                <label>
                    결제금액
                    <span>
                        {parseInt(order?.paymentAmount || '').toLocaleString()}{' '}
                        원
                    </span>
                </label>
            </div>
            <div>
                <label>배송정보</label>
                <p>
                    받는분 : {order?.recipientName} / {order?.recipientContact}
                </p>

                <p>
                    배송지 :{' '}
                    {decodeHTML(order?.address || '').replace('///', ' ')}
                </p>
                <p>배송요청사항 : {order?.deliveryRequest}</p>
            </div>
            <div>
                <Link to={'/user/mypage/orders'} className="bg-white-button">
                    상세보기
                </Link>
                <Link to={'/product/list'} className="bg-white-button">
                    쇼핑하기
                </Link>
            </div>
        </div>
    )
}
