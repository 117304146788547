import { Category, findCategoryById } from '@Interface/Category'
import {
    InsertProduct,
    Product,
    ProductCheck,
    ProductDetail,
    ProductListResponse,
    ProductSearchDTO,
} from '@Interface/Product'
import {
    ProductListService,
} from '@Service/user/product/ProductService'
import { useState } from 'react'
import {
    AdminProductListService,
    AdminProductService,
    CheckDuplicateService,
    InsertProductService,
} from '@Service/admin/product/AdminProductService'
import { getModalInstance } from '@Component/common/Widgets/Modal/ModalProvider'
import { getFileMap, getImageList } from './imageUtils'
import { HttpAlert, HttpSuccess } from '@Interface/HttpCallBack'

let typingTimer: ReturnType<typeof setTimeout> | null
const doneTypingInterval = 350 // 타이핑이 멈춘 후 요청을 보내기까지의 대기 시간 (밀리초)

export const checkCategory = (categoryList: {
    [p: string]: Category[]
}): Promise<number> => {
    const primary = document.getElementById(
        'select-primary-category'
    ) as HTMLInputElement

    const secondary = document.getElementById(
        'select-secondary-category'
    ) as HTMLInputElement

    if (primary.value != '' && secondary.value != '') {
        const temp = findCategoryById(
            categoryList,
            primary.value,
            secondary.value
        )
        if (temp?.categoryId == null) {
            return Promise.resolve(1)
        } else {
            return Promise.resolve(temp.categoryId)
        }
    } else {
        primary.focus()
        return Promise.resolve(0)
    }
}

export const checkProductCode = async (
    productId: string | ''
): Promise<boolean> => {
    const element = document.getElementById('product-code') as HTMLInputElement

    const message = document.getElementById(
        'product-code-message'
    ) as HTMLInputElement

    if (element.value.includes(' ')) {
        message.innerText = '공백이 포함되어 있습니다.'
        message.style.color = 'red'
        element.focus()
        return false
    }

    if (element.value === '') {
        message.innerHTML = '상품 코드를 입력해주세요'
        message.style.color = 'red'
        element.focus()
        return false
    }

    // todo 서버 통신후 중복확인 할 것
    const httpSuccess: HttpSuccess = {
        200: () => {
            message.innerHTML = '사용 가능한 코드입니다.'
            message.style.color = 'Blue'
        },
    }
    const httpAlert: HttpAlert = {
        409: () => {
            message.innerHTML = '중복된 코드입니다.'
            message.style.color = 'Red'
        },
        500: () => {
            message.innerHTML = '서버 오류, 관리자에게 문의하세요.'
            message.style.color = 'Red'
        },
    }

    message.innerHTML = ''

    const productCheck: ProductCheck = {
        productId: productId,
        productCode: element.value,
    }

    return await CheckDuplicateService(
        'CODE',
        productCheck,
        httpSuccess,
        httpAlert
    )
}

export const checkProductName = (): boolean => {
    const element = document.getElementById('product-name') as HTMLInputElement

    const message = document.getElementById(
        'product-name-message'
    ) as HTMLInputElement

    if (element.value === '') {
        message.innerHTML = '상품명을 입력해주세요'
        message.style.color = 'red'
        element.focus()
        return false
    }

    message.innerHTML = ''
    return true
}

export const checkProductPrice = () => {
    const element = document.getElementById('product-price') as HTMLInputElement

    const message = document.getElementById(
        'product-price-message'
    ) as HTMLInputElement

    if (element.value === '') {
        message.innerHTML = '상품 가격을 입력해주세요'
        message.style.color = 'red'
        element.focus()
        return false
    }

    element.value = String(parseInt(element.value.replaceAll(',', '')))
    element.value = (parseInt(element.value) || 0).toLocaleString()

    message.innerHTML = ''
    return true
}

export const checkDeliveryFee = () => {
    const element = document.getElementById(
        'product-delivery-fee'
    ) as HTMLInputElement

    const message = document.getElementById(
        'product-delivery-fee-message'
    ) as HTMLInputElement

    if (element.value === '') {
        message.innerHTML = '배송비를 입력해주세요'
        message.style.color = 'red'
        element.focus()
        return false
    }

    element.value = String(parseInt(element.value.replaceAll(',', '')))
    element.value = (parseInt(element.value) || 0).toLocaleString()

    message.innerHTML = ''
    return true
}

export const checkProductForm = async (
    categoryList: {
        [p: string]: Category[]
    },
    productId: string
): Promise<number> => {
    const modal = getModalInstance()

    const isValidCode = await checkProductCode(productId)
    if (!isValidCode) {
        modal!.alert(() => {}, '알림', '상품 코드값을 확인하세요.')
        return 0
    }

    const isValidName = await checkProductName()
    if (!isValidName) {
        modal!.alert(() => {}, '알림', '상품 이름을 확인하세요.')
        return 0
    }

    const isValidPrice = await checkProductPrice()
    if (!isValidPrice) {
        modal!.alert(() => {}, '알림', '상품 가격을 확인하세요.')

        return 0
    }

    const isValidFee = await checkDeliveryFee()
    if (!isValidFee) {
        modal!.alert(() => {}, '알림', '배송비를 확인하세요.')
        return 0
    }

    const isValidCategory = await checkCategory(categoryList)
    if (isValidCategory == 0) {
        modal!.alert(() => {}, '알림', '카테고리를 선택해주세요')
        return 0
    }

    if (localStorage.getItem('size') == '') {
        modal!.alert(() => {}, '알림', '사이즈를 선택해주세요')
        return 0
    }

    return isValidCategory
}

export const getProductFormValue = (_categoryId: number) => {
    return {
        productId: null,
        category:
            {
                categoryId: _categoryId,
                primaryName: '',
                secondaryName: '',
            } || '',
        name:
            (document.getElementById('product-name') as HTMLInputElement)
                .value || '',
        price:
            (
                document.getElementById('product-price') as HTMLInputElement
            ).value.replaceAll(',', '') || '',
        deliveryFee:
            (
                document.getElementById(
                    'product-delivery-fee'
                ) as HTMLInputElement
            ).value.replaceAll(',', '') || '',
        productCode:
            (document.getElementById('product-code') as HTMLInputElement)
                .value || '',
        productDescription: $('#summernote').summernote('code') || '',
        size: localStorage.getItem('size') || '',
    }
}

export const getProductList = async (
    searchParams: URLSearchParams,
    primaryCategory: string | undefined,
    secondaryCategory: string | undefined,
    categoryList: { [p: string]: Category[] },
    setProductList: React.Dispatch<React.SetStateAction<Product[] | null>>,
    setItemCount: React.Dispatch<React.SetStateAction<number>>,
    isAdmin: boolean
) => {
    const page =
        searchParams.get('page') !== null
            ? parseInt(searchParams.get('page')!)
            : 1
    const sortMode =
        searchParams.get('sortMode') !== null
            ? parseInt(searchParams.get('sortMode')!)
            : 1
    const title = searchParams.get('title')

    var searchQuery: ProductSearchDTO = {
        categoryId: null,
        page: page,
        sortMode: sortMode,
        primaryCategory: null,
        productName: title,
    }

    if (!primaryCategory && !secondaryCategory) {
    } else if (
        (primaryCategory && !secondaryCategory) ||
        (primaryCategory && secondaryCategory == 'all')
    ) {
        searchQuery.primaryCategory = primaryCategory
    } else if (primaryCategory && secondaryCategory) {
        const category = findCategoryById(
            categoryList,
            primaryCategory,
            secondaryCategory
        )

        if (category != null) {
            searchQuery.categoryId = category.categoryId!
        } else {
            setProductList(null)
            return
        }
    }
    try {
        const productListResponse = isAdmin
            ? await AdminProductListService(searchQuery)
            : await ProductListService(searchQuery)
        if (productListResponse !== null && productListResponse !== undefined) {
            setProductList(productListResponse.productList)
            setItemCount(productListResponse.productCount)
        } else {
            setProductList(null)
            setItemCount(0) // 또는 적절한 기본값으로 설정
        }

        if (
            window.location.href.includes('product') &&
            !secondaryCategory &&
            !primaryCategory
        ) {
            document.title = 'BROSPO 전체상품'
        } else {
            if (
                window.location.href.includes('product') &&
                secondaryCategory &&
                secondaryCategory != 'all'
            ) {
                document.title = 'BROSPO ' + secondaryCategory
            } else {
                if (window.location.href.includes('product')) {
                    document.title = 'BROSPO ' + primaryCategory
                }
            }
        }
    } catch (error) {
        console.error('Error fetching product list:', error)
        setProductList(null)
    }
}

export const insertProduct = async (
    categoryList: {
        [p: string]: Category[]
    },
    fileMap: Map<number, File>
) => {
    const checkForm = await checkProductForm(categoryList, '')

    if ((await checkForm) == 0) {
        return
    }

    const product: InsertProduct = getProductFormValue(checkForm)

    const formData = getImageList(fileMap)

    formData.append('insertProduct', JSON.stringify(product))

    await InsertProductService(formData)
}

export const getAdminProduct = async (
    productId: string | undefined,
    setProduct: React.Dispatch<
        React.SetStateAction<Product | null | undefined>
    >,
    setFileMap: React.Dispatch<React.SetStateAction<Map<number, File>>>,
    setTempFileMap: React.Dispatch<React.SetStateAction<Map<number, File>>>
) => {
    if (productId) {
        const result = await AdminProductService(productId)
        if (result) {
            setProduct(result.product)
            setTempFileMap(await getFileMap(result.imageUrl))
            setFileMap(await getFileMap(result.imageUrl))
            document.title = '상품 : ' + result.product.name
        }
    }
}

export const setViewProduct = (productId: string) => {
    const viewProductList = document.cookie
        .split('; ')
        .find((row) => row.startsWith('viewProductList='))
        ?.split('=')[1]
        ?.split(',')

    if (viewProductList) {
        const newViewProductList = [productId]
        viewProductList.forEach((viewProduct) => {
            if (newViewProductList.length < 10 && viewProduct !== productId) {
                newViewProductList.push(viewProduct)
            }
        })
        const expiresDate = new Date()
        expiresDate.setDate(expiresDate.getDate() + 365)
        document.cookie = `viewProductList=${newViewProductList.join(
            ','
        )};expires=${expiresDate.toUTCString()};path=/`
    } else {
        const expiresDate = new Date()
        expiresDate.setDate(expiresDate.getDate() + 365)
        document.cookie = `viewProductList=${productId};expires=${expiresDate.toUTCString()};path=/`
    }
}

export const getViewProduct = () => {
    const cookieValue = document.cookie
        .split('; ')
        .find((row) => row.startsWith('viewProductList='))
        ?.split('=')[1]

    if (cookieValue) {
        return cookieValue.split(',')
    } else {
        return []
    }
}
