import { Link, useLocation } from 'react-router-dom'
import './MypageBoard.css'
import { InquiryList } from '@Component/board/List/InquiryList'
import { ReviewList } from '@Component/board/List/ReviewList'

export const MypageBoard = () => {
    return (
        <div className={'mypage-board'}>
            <h3>내가쓴 리뷰</h3>
            <ReviewList productId={null} userSearch={true}/>
            <h3>내가쓴 문의</h3>
            <InquiryList productId={null} userSearch={true}/>
        </div>
    )
}
