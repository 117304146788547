import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Category } from '@Interface/Category'

export const PrimaryCategoryBar = (props: {
    categoryList: { [p: string]: Category[] }
    isAdmin: boolean
}) => {
    const [adminUrl, setAdminUrl] = useState<string>('')
    const { primaryCategory, secondaryCategory } = useParams<{
        primaryCategory: string
        secondaryCategory: string
    }>()

    useEffect(() => {
        if (props.isAdmin) {
            setAdminUrl('/admin')
        }
    }, [props.isAdmin])
    if (!secondaryCategory) {
        return (
            <React.Fragment>
                <h3>{primaryCategory || '전체상품'}</h3>
                <div className="product-category-contain">
                    <Link
                        to={adminUrl + '/product/list/'}
                        id={primaryCategory ? '' : 'current'}
                    >
                        전체상품
                    </Link>
                    {props.isAdmin && (
                        <Link
                            to={adminUrl + '/product/list/비공개'}
                            id={primaryCategory == '비공개' ? 'current' : ''}
                        >
                            비공개
                        </Link>
                    )}
                    {Object.values(props.categoryList).map(
                        (categories, index) => (
                            <Link
                                to={
                                    adminUrl +
                                    '/product/list/' +
                                    categories.at(0)!.primaryName +
                                    '/all'
                                }
                                key={index}
                                id={
                                    primaryCategory ==
                                    categories.at(0)!.primaryName
                                        ? 'current'
                                        : ''
                                }
                            >
                                {categories.at(0)!.primaryName}
                            </Link>
                        )
                    )}
                </div>
            </React.Fragment>
        )
    } else {
        return null
    }
}
