import { useEffect, useRef, useState } from 'react'
import './SizeSelector.css'
import {useParams} from "react-router-dom";


export const defaultSizeList = [
    '5xs',
    '4xs',
    '3xs',
    '2xs',
    'xs',
    's',
    'm',
    'l',
    'xl',
    '2xl',
    '3xl',
    '4xl',
    '5xl',
    '6xl',
    '7xl',
    'free',
    'custom',
]

export const SizeSelector = (props: {
    sizeList: string
    selectSize: string[]
}) => {
    const { productId } = useParams<{ productId: string }>()

    const [sizeList, setSizeList] = useState<string[]>(defaultSizeList)
    const checkSelectSize = useRef<boolean>(true)


    useEffect(() => {
        const selectSize: string[] = props.selectSize
        if (selectSize && checkSelectSize.current && productId != '1') {
            localStorage.setItem('size', '')
            for (const size of selectSize) {
                const sizeElement = document.getElementById(size)
                if (sizeElement) {
                    sizeElement.click()
                }
            }
            checkSelectSize.current = false
        }
    }, [props.selectSize])

    useEffect(() => {
        if (props.sizeList) {
            setSizeList(props.sizeList.split(','))
        }
    }, [props.sizeList])

    function sizeCheck(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        const element = event.currentTarget

        if (element.style.backgroundColor === 'var(--active-color-2)' && productId != '1') {
            element.style.backgroundColor = 'white'
            element.style.color = 'var(--color)'
            const size = localStorage.getItem('size')!
            localStorage.setItem('size', size.replace(',' + element.id, ''))
        } else if(element.style.backgroundColor !== 'var(--active-color-2)' && productId != '1'){
            element.style.backgroundColor = 'var(--active-color-2)'
            element.style.color = 'var(--color-2)'
            const size = localStorage.getItem('size')!
            localStorage.setItem('size', size + ',' + element.id)
        }
    }

    return (
        <>
            <div className={'size-selector'}>
                {sizeList.map((size, index) => (
                    <div id={size} onClick={sizeCheck} key={index}>
                        {size.toUpperCase()}
                    </div>
                ))}
            </div>
        </>
    )
}
