import { getModalInstance } from '@Component/common/Widgets/Modal/ModalProvider'
import { getImageList } from './imageUtils'
import { Board, BoardSearchDTO, SaveBoard } from '@Interface/Board'
import {
    adminInsertBoardService,
    adminUpdateBoardService,
    getBoardListService,
    insertBoardService,
    updateBoardService,
} from '@Service/user/board/BoardService'

export const insertBoard = async (fileMap: Map<number, File>, isAdmin?:boolean) => {
    const form = document.getElementById('board-form')

    if (form) {
        if (!(await checkBoardForm())) {
            return
        }
    }

    const board: SaveBoard = getBoardFormValue()

    const formData = getImageList(fileMap)

    formData.append('saveBoard', JSON.stringify(board))

    isAdmin?  await adminInsertBoardService(formData):await insertBoardService(formData)
}

export const updateBoard = async (fileMap: Map<number, File> , isAdmin?:boolean) => {
    const form = document.getElementById('board-form')

    if (form) {
        if (!(await checkBoardForm())) {
            return
        }
    }
    const board: SaveBoard = getBoardFormValue()

    const formData = getImageList(fileMap)

    formData.append('saveBoard', JSON.stringify(board))

    isAdmin? await adminUpdateBoardService(formData) :await updateBoardService(formData)
}

async function checkBoardForm() {
    const modal = getModalInstance()
    const category = (
        document.getElementById('board-category') as HTMLInputElement
    ).value
    const isValidTitle = await checkBoardTitle()
    if (!isValidTitle) {
        if (category == '0') {
            modal!.alert(() => {}, '알림', '공지제목을 입력해주세요.')
        }
        if (category == '1') {
            modal!.alert(() => {}, '알림', '한줄평을 입력해주세요.')
        }
        if (category == '2') {
            modal!.alert(() => {}, '알림', '문의제목을 입력해주세요.')
        }
        return false
    }

    const isValidContent = await checkBoardContent()
    if (!isValidContent) {
        if (category == '0') {
            modal!.alert(() => {}, '알림', '내용을 입력해주세요.')
        }
        if (category == '1') {
            modal!.alert(() => {}, '알림', '리뷰 내용을 입력해주세요.')
        }
        if (category == '2') {
            modal!.alert(() => {}, '알림', '문의 내용을 입력해주세요.')
        }
        return false
    }

    if (category == '1') {
        const isValidRating = await checkReviewRating()
        if (!isValidRating) {
            modal!.alert(() => {}, '알림', '평점을 선택해 주세요.')
            return false
        }
    }

    return true
}
function checkBoardTitle() {
    const element = document.getElementById('board-title') as HTMLInputElement
    if (element.value == '') {
        return false
    }
    return true
}

function checkBoardContent() {
    const content = $('#summernote').summernote('code')
    if ($(content).text() == '') {
        return false
    }
    return true
}

function getBoardFormValue(): SaveBoard {
    return {
        boardId:
            (document.getElementById('board-id') as HTMLInputElement).value ||
            '',
        title:
            (document.getElementById('board-title') as HTMLInputElement)
                .value || '',
        content: $('#summernote').summernote('code') || '',
        reviewRating:
            (document.getElementById('review-rating') as HTMLInputElement)
                ?.value || '',
        category:
            (document.getElementById('board-category') as HTMLInputElement)
                .value || '',
        hidden: (document.getElementById('hidden-board') as HTMLInputElement)
            ?.checked
            ? 'true'
            : 'false',
        productId:
            (document.getElementById('product-id') as HTMLInputElement).value ||
            '',
    }
}
function checkReviewRating() {
    const element = document.getElementById('review-rating') as HTMLInputElement

    if (element.value == '0') {
        return false
    }
    return true
}

export const fetchBoardList = async (
    searchQuery: BoardSearchDTO,
    setBoardList: React.Dispatch<React.SetStateAction<Board[]>>,
    setTotalPage: React.Dispatch<React.SetStateAction<number>>,
    setTotalCount?: React.Dispatch<React.SetStateAction<number>> | null,
    setNoticeCount?: React.Dispatch<React.SetStateAction<number>> | null
) => {
    const boardListDTO = await getBoardListService(searchQuery)

    setBoardList(boardListDTO?.boardList || [])
    if (boardListDTO) {
        const totalCount = boardListDTO.totalCount || 0
        setTotalPage(Math.ceil(totalCount / 10) || 1)
    } else {
        setTotalPage(1)
    }

    if (setTotalCount) {
        setTotalCount(boardListDTO?.totalCount || 0)
    }
    if (setNoticeCount) {
        setNoticeCount(boardListDTO?.noticeCount || 0)
    }
}
