import axios from 'axios'
import {
    Product,
    ProductSearchDTO,
    ProductListResponse,
    ProductDetail,
} from '@Interface/Product'
import {
    deleteRequest,
    getRequest,
    postRequest,
    putRequest,
} from '@Service/Api'
import { HttpAlert, HttpSuccess } from '@Interface/HttpCallBack'
import { useState } from 'react'
import {
    Board,
    BoardDetail,
    BoardListDTO,
    BoardSearchDTO,
} from '@Interface/Board'
import { getModalInstance } from '@Component/common/Widgets/Modal/ModalProvider'
import { AnswerDTO } from '@Interface/Answer'

export const insertBoardService = async (formData: FormData) => {
    const modal = getModalInstance()

    try {
        const httpSuccess: HttpSuccess = {
            200: () => {
                modal!.alert(
                    () => {
                        window.location.reload()
                    },
                    '알림',
                    '게시글이 등록되었습니다.'
                )
            },
        }
        const httpAlert: HttpAlert = {}

        return await postRequest('/board', httpSuccess, httpAlert, formData, {})
    } catch (e) {
        console.log(e)
    }
}

export const adminInsertBoardService = async (formData: FormData) => {
    const modal = getModalInstance()

    try {
        const httpSuccess: HttpSuccess = {
            200: () => {
                modal!.alert(
                    () => {
                        window.location.reload()
                    },
                    '알림',
                    '게시글이 등록되었습니다.'
                )
            },
        }
        const httpAlert: HttpAlert = {}

        return await postRequest('/admin/board', httpSuccess, httpAlert, formData, {})
    } catch (e) {
        console.log(e)
    }
}


export const updateBoardService = async (formData: FormData) => {
    const modal = getModalInstance()
    try {
        const httpSuccess: HttpSuccess = {
            200: () => {
                modal!.alert(
                    () => {
                        window.location.reload()
                    },
                    '알림',
                    '게시글이 수정되었습니다.'
                )
            },
        }
        const httpAlert: HttpAlert = {
            403: () => {
                modal!.alert(() => {}, '경고', '수정 권한이 없습니다.')
            },
        }

        return await putRequest('/board', httpSuccess, httpAlert, formData, {})
    } catch (e) {
        console.log(e)
    }
}

export const adminUpdateBoardService = async (formData: FormData) => {
    const modal = getModalInstance()
    try {
        const httpSuccess: HttpSuccess = {
            200: () => {
                modal!.alert(
                    () => {
                        window.location.reload()
                    },
                    '알림',
                    '게시글이 수정되었습니다.'
                )
            },
        }
        const httpAlert: HttpAlert = {}

        return await putRequest('/admin/board', httpSuccess, httpAlert, formData, {})
    } catch (e) {
        console.log(e)
    }
}

export const deleteBoardService = async (boardId: string) => {
    const modal = getModalInstance()
    try {
        const httpSuccess: HttpSuccess = {
            200: () => {
                modal!.alert(
                    () => {
                        window.location.reload()
                    },
                    '알림',
                    '게시글이 삭제되었습니다.'
                )
            },
        }
        const httpAlert: HttpAlert = {
            403: () => {
                modal!.alert(() => {}, '경고', '삭제 권한이 없습니다.')
            },
        }

        return await deleteRequest(
            '/board/' + boardId,
            httpSuccess,
            httpAlert,
            {}
        )
    } catch (e) {
        console.log(e)
    }
}

export const getBoardListService = async (searchQuery: BoardSearchDTO) => {
    const modal = getModalInstance()
    try {
        const httpSuccess: HttpSuccess = {}
        const httpAlert: HttpAlert = {}
        const config = {
            params: searchQuery,
        }

        return await getRequest<BoardListDTO>(
            searchQuery.isAdmin ? '/admin/board/list' : '/board/list',
            httpSuccess,
            httpAlert,
            config
        )
    } catch (e) {
        console.log(e)
    }
}

export const getBoardService = async (boardId: string, isAdmin?: boolean) => {
    const modal = getModalInstance()
    try {
        const httpSuccess: HttpSuccess = {}
        const httpAlert: HttpAlert = {
            410: () => {
                modal!.alert(() => {}, '알림', '삭제된 게시물입니다.')
            },
        }

        return await getRequest<BoardDetail>(
            (isAdmin ? '/admin/board/' : '/board/') + boardId,
            httpSuccess,
            httpAlert,
            {}
        )
    } catch (e) {
        console.log(e)
    }
}

export const saveAnswerService = async (answer: AnswerDTO) => {
    const modal = getModalInstance()

    try {
        const httpSuccess: HttpSuccess = {
            200: () => {
                modal!.alert(
                    () => {
                        window.location.reload()
                    },
                    '알림',
                    '답변이 저장되었습니다.'
                )
            },
        }
        const httpAlert: HttpAlert = {}

        return await postRequest(
            '/admin/answer',
            httpSuccess,
            httpAlert,
            answer,
            {}
        )
    } catch (e) {
        console.log(e)
    }
}
