import { Link, useParams } from 'react-router-dom'
import * as process from 'process'
import { onErrorImg } from '@Util/imageUtils'
import errorImage from '@Image/view/black.png'
import React, { useEffect, useState } from 'react'
import { Product } from '@Interface/Product'
import './ProductList.css'
import { shareLink } from '@Util/ViewUtils'
import { addWish, setWishicon } from '@Util/WishUtils'

export const ProductList = (props: {
    productList: Product[] | null
    isAdmin: boolean
}): JSX.Element => {
    const productList = props.productList
    const [adminUrl, setAdminUrl] = useState<string>('')

    useEffect(() => {
        if (props.isAdmin) {
            setAdminUrl('/admin')
        }
    }, [props.isAdmin])
    useEffect(() => {
        if (productList) {
            setWishicon()
        }
    }, [productList])

    if (!productList) {
        return <></>
    }

    return (
        <div className="product-contain">
            {productList.map((product, index) => (
                <ProductGridItem
                    product={product}
                    adminUrl={adminUrl}
                    key={index}
                />
            ))}
        </div>
    )
}

export const ProductGridItem = ({
    product,
    adminUrl,
}: {
    product: Product
    adminUrl: string
}) => {
    return (
        <div className="product-item" key={product.productId}>
            <Link
                className="product-image"
                to={adminUrl + '/product/detail/' + product.productId}
            >
                <img
                    src={`${process.env.PRODUCT_IMAGE_BASE_URL}${product.productId}/THUMBNAIL/0.jpg`}
                    alt={`${product.name} thumbnail`}
                    onError={(e) => onErrorImg(e)}
                />
            </Link>
            <div className="product-info">
                <p id={'product-title-' + product.productId}>
                    <Link
                        to={adminUrl + '/product/detail/' + product.productId}
                    >
                        {product.name}
                    </Link>
                </p>
                <p id={'product-price-' + product.productId}>
                    {(product.price ?? 0).toLocaleString('ko-KR')} 원
                </p>
                <div className="product-list-icon">
                    <i
                        className={`product-wish-check-${product.productId} icon-heart-empty`}
                        onClick={(e) => {
                            addWish(e, product.productId!)
                        }}
                    ></i>
                    <i className="icon-upload" onClick={() =>shareLink(product?.productId!)}></i>
                </div>
            </div>
        </div>
    )
}

export const ProductListItem = (props: {
    product: Product | null | undefined
}) => {
    const product = props.product

    if (!product) {
        return null
    }
    return (
        <div className="product-list-item">
            <a
                className="product-image"
                href={'/product/detail/' + product.productId}
            >
                <img
                    src={`${process.env.PRODUCT_IMAGE_BASE_URL}${product.productId}/THUMBNAIL/0.jpg`}
                    alt={`${product.name} thumbnail`}
                    onError={(e) => onErrorImg(e)}
                />
            </a>
            <div className="product-info">
                <a href={'/product/detail/' + product.productId}>
                    <p className="title">{product.name}</p>
                    <p id={'product-price-' + product.productId}>
                        {(product.price ?? 0).toLocaleString('ko-KR')} 원
                    </p>
                </a>
                <div className="product-list-icon">
                    <i
                        className={`product-wish-check-${product.productId} icon-heart-empty `}
                        onClick={(e) => {
                            addWish(e, product.productId!)
                        }}
                    ></i>
                    <i className="icon-upload" onClick={() =>shareLink(product?.productId!)}></i>
                </div>
            </div>
        </div>
    )
}

export const OrderListItem = (props: {
    product: Product | null | undefined
}) => {
    const product = props.product

    if (!product) {
        return null
    }
    return (
        <div className="product-list-item">
            <div className="product-info">
                    <p className="title">{product.name}</p>
                    <div className="price-area">
                        <p>
                            가격 :{' '}
                            {(product.price ?? 0).toLocaleString('ko-KR')} 원
                        </p>
                        <p>
                            배송비 :{' '}
                            {(product.deliveryFee ?? 0).toLocaleString('ko-KR')}
                            원
                        </p>
                    </div>
            </div>
        </div>
    )
}
