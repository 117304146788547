import { Link, useLocation } from 'react-router-dom'
import './SideBar.css'
import kakaoLogo from '@Image/logo/kakao-logo.png'
import errorImage from '@Image/view/black.png'
import { onErrorImg } from '@Util/imageUtils'
import { scrollToBottom, scrollToTop } from '@Util/ViewUtils'
import { getModalInstance } from '@Component/common/Widgets/Modal/ModalProvider'
import { WishView } from '@Component/product/Wish/WishView'
import { ViewProdcut } from '@Component/product/ViewProduct/ViewProduct'
import { CartListModal } from '@Component/cart/CartList.css/CartList'
import { UserInfoService } from '@Service/user/account/UserService'
import { useEffect, useState } from 'react'
import { UserInfo } from '@Interface/User'
import {OrderModal} from "@Component/order/OrderModal/OrderModal";
export const SideBar = () => {
    const location = useLocation()

    const [userInfo, setUserInfo] = useState<UserInfo>()
    const fetchData = async () => {
        const userInfo = await UserInfoService()
        setUserInfo(userInfo)
    }

    useEffect(() => {
        fetchData()
    }, [])

    const modal = getModalInstance()
    const handlerMobileSizeBar = (e: React.MouseEvent<HTMLElement>) => {
        const target = e.target as HTMLElement
        const delay = 50
        if (target.classList.contains('icon-plus')) {
            const closeIcons = Array.from(
                document.querySelectorAll('.mobile-side-bar i.close')
            )

            closeIcons.reverse().forEach((icon, index) => {
                setTimeout(() => {
                    icon.classList.remove('close')
                    icon.classList.add('open')
                }, index * delay) // 0.05초 간격으로 실행
            })

            target.style.transform = 'rotate(180deg)'
            target.style.color = 'var(--color-4)'
            target.style.backgroundColor = 'rgba(255, 255, 255, 0.8)'
            target.style.border = '1px solid var(--border-color-2)'
            target.classList.remove('icon-plus')
            target.classList.add('icon-minus')
        } else {
            const closeIcons = Array.from(
                document.querySelectorAll('.mobile-side-bar i.open')
            )

            closeIcons.forEach((icon, index) => {
                setTimeout(() => {
                    icon.classList.remove('open')
                    icon.classList.add('close')
                }, index * delay) // 0.05초 간격으로 실행
            })

            target.style.transform = 'rotate(0deg)'
            target.style.color = 'white'
            target.style.backgroundColor = 'rgba(0,0,0,0.3)'
            target.style.border = 'none'
            target.classList.remove('icon-minus')
            target.classList.add('icon-plus')
        }
    }
    if (
        location.pathname.includes('admin') ||
        location.pathname.includes('order') ||
        (location.pathname.includes('user') &&
            !location.pathname.includes('mypage'))
    ) {
        return null
    } else {
        return (
            <div className="side-bar">
                <div className="pc-side-bar">
                    <i
                        className="icon-truck-1 "
                        onClick={() => {
                            if (userInfo?.userName == '') {
                                modal?.confirm(
                                    () => {
                                        window.location.href = '/user/login'
                                    },
                                    () => {},
                                    '알림',
                                    '확인을 위해 로그인이 필요합니다.',
                                    '로그인 하시겠습니까?'
                                )
                                return
                            }

                            modal?.popup(<OrderModal />, '96vw', 'auto')
                        }}
                    ></i>

                    <i
                        className="icon-basket "
                        onClick={() => {
                            if (userInfo?.userName == '') {
                                modal?.confirm(
                                    () => {
                                        window.location.href = '/user/login'
                                    },
                                    () => {},
                                    '알림',
                                    '확인을 위해 로그인이 필요합니다.',
                                    '로그인 하시겠습니까?'
                                )
                                return
                            }

                            modal?.popup(<CartListModal />, '96vw', 'auto')
                        }}
                    ></i>
                    <i
                        className="icon-heart-empty"
                        onClick={() => {
                            if (userInfo?.userName == '') {
                                modal?.confirm(
                                    () => {
                                        window.location.href = '/user/login'
                                    },
                                    () => {},
                                    '알림',
                                    '확인을 위해 로그인이 필요합니다.',
                                    '로그인 하시겠습니까?'
                                )
                                return
                            }

                            modal?.popup(<WishView />, '96vw', 'auto')
                        }}
                    ></i>
                    <i
                        className="icon-clock"
                        onClick={() => {
                            modal?.popup(<ViewProdcut />, '96vw', 'auto')
                        }}
                    ></i>
                    <Link
                        to={'http://pf.kakao.com/_iJGzxj/chat'}
                        target={'_blank'}
                    >
                        <img src={kakaoLogo} onError={(e) => onErrorImg(e)} />
                    </Link>
                    <i className="icon-up-open" onClick={scrollToTop}></i>
                    <i
                        className="icon-down-open close-border "
                        onClick={scrollToBottom}
                    ></i>
                </div>
                <div className="mobile-side-bar">
                    <i
                        className="icon-truck-1 close"
                        onClick={() => {
                            if (userInfo?.userName == '') {
                                modal?.confirm(
                                    () => {
                                        window.location.href = '/user/login'
                                    },
                                    () => {},
                                    '알림',
                                    '확인을 위해 로그인이 필요합니다.',
                                    '로그인 하시겠습니까?'
                                )
                                return
                            }

                            modal?.popup(<WishView />, '96vw', 'auto')
                        }}
                    ></i>
                    <i
                        className="icon-basket close"
                        onClick={() => {
                            if (userInfo?.userName == '') {
                                modal?.confirm(
                                    () => {
                                        window.location.href = '/user/login'
                                    },
                                    () => {},
                                    '알림',
                                    '확인을 위해 로그인이 필요합니다.',
                                    '로그인 하시겠습니까?'
                                )
                                return
                            }

                            modal?.popup(<CartListModal />, '96vw', 'auto')
                        }}
                    ></i>
                    <i
                        className="icon-heart-empty  close"
                        onClick={() => {
                            if (userInfo?.userName == '') {
                                modal?.confirm(
                                    () => {
                                        window.location.href = '/user/login'
                                    },
                                    () => {},
                                    '알림',
                                    '확인을 위해 로그인이 필요합니다.',
                                    '로그인 하시겠습니까?'
                                )
                                return
                            }
                            modal?.popup(<WishView />, '96vw', 'auto')
                        }}
                    ></i>
                    <i
                        className="icon-clock close"
                        onClick={() => {
                            modal?.popup(<ViewProdcut />, '96vw', 'auto')
                        }}
                    ></i>
                    <i
                        className="icon-plus"
                        onClick={(e) => {
                            handlerMobileSizeBar(e)
                        }}
                    ></i>

                    <Link
                        to={'http://pf.kakao.com/_iJGzxj/chat'}
                        target={'_blank'}
                    >
                        <img src={kakaoLogo} onError={(e) => onErrorImg(e)} />
                    </Link>
                    <i className="icon-up-open" onClick={scrollToTop}></i>
                    <i
                        className="icon-down-open  "
                        onClick={scrollToBottom}
                    ></i>
                </div>
            </div>
        )
    }
}
