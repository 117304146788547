import React, { useEffect, useState } from 'react'
import { Category } from '@Interface/Category'
import { useParams, useSearchParams } from 'react-router-dom'
import { Product } from '@Interface/Product'

import { ProductPageBar } from '@Component/common/Widgets/PageBar/PageBar'
import NotFound from '@Component/common/View/NotFound/NotFound'
import { ProductList } from '@Component/product/ProductList/ProductList'
import { PrimaryCategoryBar } from '@Component/product/CategoryBar/PrimaryCategoryBar'
import { SecondaryCategoryBar } from '@Component/product/CategoryBar/SecondaryCategoryBar'
import { getProductList } from '@Util/ProductUtils'
import { ProductSortSelector } from '@Component/product/ProductList/ProductSortSelector'

export const AdminProductListPage = (props: {
    categoryList: { [p: string]: Category[] }
}): JSX.Element => {
    const categoryList = props.categoryList

    const [productList, setProductList] = useState<Product[] | null>(null)
    const [itemCount, setItemCount] = useState<number>(0)
    const { primaryCategory, secondaryCategory } = useParams<{
        primaryCategory: string
        secondaryCategory: string
    }>()
    const [searchParams] = useSearchParams()
    const page = searchParams.get('page')
    const productPage = parseInt(page || '1', 10)

    useEffect(() => {
        window.scrollTo(0, 0)
        getProductList(
            searchParams,
            primaryCategory,
            secondaryCategory,
            categoryList,
            setProductList,
            setItemCount,
            true
        )
    }, [primaryCategory, secondaryCategory, searchParams])

    const totalPages = Math.ceil(itemCount / 20)

    return (
        <div className="product-list">
            <div className="product-category">
                <PrimaryCategoryBar
                    categoryList={categoryList}
                    isAdmin={true}
                />
                <SecondaryCategoryBar
                    categoryList={categoryList}
                    isAdmin={true}
                />
            </div>
            <div className="product-list-info">
                <div className="product-item-count">
                    <p>{itemCount}</p>
                    <span>item</span>
                </div>
                <ProductSortSelector />
            </div>

            {productList?.length != 0 ? (
                <ProductList productList={productList} isAdmin={true} />
            ) : (
                <NotFound />
            )}

            <ProductPageBar productPage={productPage} totalPage={totalPages} />
        </div>
    )
}
