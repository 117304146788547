import React from 'react'
import './About.css'

export const About = () => {
    return (
        <div className={'about-info'}>
            <table className="info-table">
                <colgroup>
                    <col width="25%" />
                    <col width="*" />
                </colgroup>
                <tbody>
                    <tr>
                        <th>상점명</th>
                        <td>BROSPO</td>
                    </tr>
                    <tr>
                        <th>대표</th>
                        <td>브로스포</td>
                    </tr>
                    <tr>
                        <th>대표전화</th>
                        <td>010-2256-4628</td>
                    </tr>
                    <tr>
                        <th>주소</th>
                        <td>서울시 구로구 고척로52마길 7-34(개봉동)</td>
                    </tr>
                    <tr>
                        <th>사업자등록번호</th>
                        <td>410-48-00704</td>
                    </tr>
                    <tr>
                        <th>시스템 관리자</th>
                        <td>김태형</td>
                    </tr>
                    <tr>
                        <th>시스템 문의</th>
                        <td>edwsqa987@gmail.com</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}
