import {CartDTO, TransformedCart} from '@Interface/Cart'
import {
    deleteCartService,
    getCartListService,
    insertCartService,
    updateCartService,
} from '@Service/user/cart/CartService'
import {ChangeEvent, useEffect, useState} from 'react'
import './CartList.css'
import {ProductListItem} from '@Component/product/ProductList/ProductList'

import {addWish, setWishicon} from '@Util/WishUtils'
import {shareLink} from '@Util/ViewUtils'
import {getModalInstance} from '@Component/common/Widgets/Modal/ModalProvider'

import {filterInputNumber} from '@Util/StringUtils'
import {updateOrderPrice} from '@Util/OrderUtils'
import {SizeSelector} from "@Component/product/ProductInfo/SizeSelector";
import {Product} from "@Interface/Product";

export const CartListModal = () => {
    const [cartList, setCartList] = useState<TransformedCart[]>()

    const fetchData = async () => {
        const data = await getCartListService()
        setCartList(data)
        if (data.length == 0) {
            ;(
                document.getElementById('empty-cart-list') as HTMLElement
            ).style.display = 'flex'
        }
        await setWishicon()
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <div className="cart-modal">
            <h2>
                장바구니 목록{' '}
                <a className="bg-black-button" href="/order/cart">
                    구매하기
                </a>
            </h2>
            <div className="cart-list">
                {cartList?.map((cart, index) => {
                    let allCount = 0
                    cart.sizes?.map((size) => {
                        allCount += parseInt(size.count)
                    })
                    const tempPrice =
                        parseInt(cart.product.price || '0') * allCount
                    return (
                        <div
                            className="cart-item"
                            key={index}
                            id={`cart-item-${cart.product.productId}`}
                        >
                            <ProductListItem product={cart.product}/>
                            <div className="cart-size-list">
                                {cart.sizes?.map((size, index) => (
                                    <div
                                        key={index}
                                        className={`cart-size-item-${cart.product.productId}-${size.size}`}
                                    >
                                        <div className="size-name">
                                            {size.size.toUpperCase()}
                                        </div>
                                        <div className="size-count">
                                            <button
                                                id={`sub-item-count-${cart.product.productId}-${size.size}`}
                                                onClick={() => {
                                                    subItemCount(
                                                        cart.product.productId!,
                                                        size.size
                                                    )
                                                }}
                                            >
                                                <i className="icon-minus"></i>
                                            </button>
                                            <input
                                                id={`size-count-${cart.product.productId}-${size.size}`}
                                                type="number"
                                                defaultValue={size.count}
                                                onInput={filterInputNumber}
                                                onChange={(e) => {
                                                    changeItemCount(
                                                        e,
                                                        cart.product.productId!,
                                                        size.size
                                                    )
                                                }}
                                            />
                                            <button
                                                id={`add-item-count-${cart.product.productId}-${size.size}`}
                                                onClick={() => {
                                                    addItemCount(
                                                        cart.product.productId!,
                                                        size.size
                                                    )
                                                }}
                                            >
                                                <i className="icon-plus"></i>
                                            </button>
                                        </div>
                                    </div>
                                ))}
                                <div className={'cart-price'}>
                                    <div className="cart-list-icon">
                                        <i
                                            className={`product-wish-check-${cart.product.productId} icon-heart-empty `}
                                            onClick={(e) => {
                                                addWish(
                                                    e,
                                                    cart.product.productId!
                                                )
                                            }}
                                        ></i>
                                        <i
                                            className="icon-upload"
                                            onClick={() =>
                                                shareLink(
                                                    cart.product?.productId!
                                                )
                                            }
                                        ></i>
                                    </div>
                                    <div
                                        id={`cart-item-price-${cart.product.productId}`}
                                    >
                                        {`총 ${tempPrice.toLocaleString()} 원`}
                                    </div>
                                </div>
                            </div>
                            <div
                                className="cart-remove-button"
                                onClick={() => {
                                    deleteCartItem(cart.product.productId!)
                                }}
                            >
                                ✕
                            </div>
                        </div>
                    )
                })}
            </div>
            <div id="empty-cart-list">장바구니가 비어있습니다.</div>
        </div>
    )
}

export const CartList = () => {
    const modal = getModalInstance();
    const [cartList, setCartList] = useState<TransformedCart[]>()

    const fetchData = async () => {
        const data = await getCartListService()
        setCartList(data)
        if (data.length == 0) {
            ;(
                document.getElementById('empty-cart-list') as HTMLElement
            ).style.display = 'flex'
        }
        await setWishicon()
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <>
            <div
                className="cart-list"
                style={{display: cartList?.length == 0 ? 'none' : ''}}
            >
                {cartList?.map((cart, index) => {
                    let allCount = 0
                    cart.sizes?.map((size) => {
                        allCount += parseInt(size.count)
                    })
                    const tempPrice =
                        parseInt(cart.product.price || '0') * allCount
                    return (
                        <div
                            className="cart-item"
                            key={index}
                            id={`cart-item-${cart.product.productId}`}
                        >
                            <div>
                                <input
                                    type="checkbox"
                                    id={`select-cart-${cart.product.productId}`}
                                    className="checkbox"
                                    onChange={updateOrderPrice}
                                />
                                <label
                                    htmlFor={`select-cart-${cart.product.productId}`}
                                    className="checkbox-label"
                                >
                                    선택
                                </label>
                                <input className={'bg-white-button'} type={"button"} value={'옵션추가'} onClick={() => {
                                    modal?.popup(<AddOption product={cart.product}/> ,'80vw')

                                }}/></div>

                            <ProductListItem product={cart.product}/>
                            <input
                                type="hidden"
                                id={`product-delivery-fee-${cart.product.productId}`}
                                value={cart.product.deliveryFee?.toString()}
                            />
                            <div className="cart-size-list">
                                {cart.sizes?.map((size, index) => (
                                    <div
                                        key={index}
                                        className={`cart-size-item-${cart.product.productId}-${size.size}`}
                                    >
                                        <div className="size-name">
                                            {size.size.toUpperCase()}
                                        </div>
                                        <div className="size-count">
                                            <button
                                                id={`sub-item-count-${cart.product.productId}-${size.size}`}
                                                onClick={() => {
                                                    subItemCount(
                                                        cart.product.productId!,
                                                        size.size
                                                    )
                                                }}
                                            >
                                                <i className="icon-minus"></i>
                                            </button>
                                            <input
                                                id={`size-count-${cart.product.productId}-${size.size}`}
                                                type="number"
                                                defaultValue={size.count}
                                                onInput={filterInputNumber}
                                                onChange={(e) => {
                                                    changeItemCount(
                                                        e,
                                                        cart.product.productId!,
                                                        size.size
                                                    )
                                                }}
                                            />
                                            <button
                                                id={`add-item-count-${cart.product.productId}-${size.size}`}
                                                onClick={() => {
                                                    addItemCount(
                                                        cart.product.productId!,
                                                        size.size
                                                    )
                                                }}
                                            >
                                                <i className="icon-plus"></i>
                                            </button>
                                        </div>
                                    </div>
                                ))}
                                <div className={'cart-price'}>
                                    <div className="cart-list-icon">
                                        <i
                                            className={`product-wish-check-${cart.product.productId} icon-heart-empty `}
                                            onClick={(e) => {
                                                addWish(
                                                    e,
                                                    cart.product.productId!
                                                )
                                            }}
                                        ></i>
                                        <i
                                            className="icon-upload"
                                            onClick={() =>
                                                shareLink(
                                                    cart.product?.productId!
                                                )
                                            }
                                        ></i>
                                    </div>
                                    <div
                                        id={`cart-item-price-${cart.product.productId}`}
                                    >
                                        {`총 ${tempPrice.toLocaleString()} 원`}
                                    </div>
                                </div>
                            </div>
                            <div
                                className="cart-remove-button"
                                onClick={() => {
                                    deleteCartItem(cart.product.productId!)
                                }}
                            >
                                ✕
                            </div>
                        </div>
                    )
                })}
            </div>
            <div id="empty-cart-list">장바구니가 비어있습니다.</div>
        </>
    )
}

const AddOption = (props: { product: Product }) => {

    useEffect(() => {

        document.addEventListener('click', async () => {
            if (localStorage.getItem('size')) {
                const insertCartArray: CartDTO[] = []

                insertCartArray.push({
                    productId: props.product.productId!,
                    size: localStorage.getItem('size')?.replace(',', '') || '',
                    count: 1,
                })
                if (await insertCartService(insertCartArray, true)) {
                    window.location.reload()
                }
            }
        })
    }, []);

    if (!props.product.size) {
        return null
    }
    return (<div>
        <h4 style={{marginBottom:'20px', textAlign:'left'}}>옵션을 선택해주세요.</h4>
        <ProductListItem product={props.product}/><br/>
        <SizeSelector sizeList={props.product.size.replace(',', '') || ''} selectSize={[]}/>
    </div>)
}


const subItemCount = (productId: string, size: string) => {
    const inputEl = document.getElementById(
        `size-count-${productId}-${size}`
    ) as HTMLInputElement
    let tempCount = parseInt(inputEl.value || '0')

    if (tempCount > 1) {
        inputEl.value = (tempCount - 1).toString()

        const updateCartArray: CartDTO[] = []

        updateCartArray.push({
            productId: productId!,
            size: size,
            count: tempCount - 1,
        })

        updateCartService(updateCartArray)
        updateCartPrice(productId)
        updateOrderPrice()
    } else {
        const modal = getModalInstance()

        modal?.confirm(
            () => {
                const deleteCartArray: CartDTO[] = []

                deleteCartArray.push({
                    productId: productId!,
                    size: size,
                    count: '',
                })

                const countElList = document.querySelectorAll(
                    `[id^="size-count-${productId}-"]`
                )

                if (countElList.length == 1) {
                    ;(
                        document.getElementById(
                            `cart-item-${productId}`
                        ) as HTMLElement
                    ).remove()
                } else {
                    ;(
                        document.querySelector(
                            `.cart-size-item-${productId}-${size}`
                        ) as HTMLElement
                    ).remove()
                    updateCartPrice(productId)
                }
                deleteCartService(deleteCartArray)
                checkCartItem()

                updateOrderPrice()
            },
            () => {
            },
            '확인',
            '해당 옵션을 삭제하시겠습니까?'
        )
    }
}

const addItemCount = (productId: string, size: string) => {
    const inputEl = document.getElementById(
        `size-count-${productId}-${size}`
    ) as HTMLInputElement
    let tempCount = parseInt(inputEl.value || '0')

    inputEl.value = (tempCount + 1).toString()

    const updateCartArray: CartDTO[] = []

    updateCartArray.push({
        productId: productId!,
        size: size,
        count: tempCount + 1,
    })

    updateCartService(updateCartArray)

    updateCartPrice(productId)

    updateOrderPrice()
}

const changeItemCount = (
    e: ChangeEvent<HTMLInputElement>,
    productId: string,
    size: string
) => {
    const inputEl = document.getElementById(
        `size-count-${productId}-${size}`
    ) as HTMLInputElement

    const filteredValue = inputEl.value.replace(/[^0-9]/g, '')

    const tempCount = filteredValue === '' ? 1 : parseInt(filteredValue)

    // 입력 필드에 숫자만 남기도록 설정
    inputEl.value = tempCount.toString()

    const updateCartArray: CartDTO[] = [
        {
            productId: productId,
            size: size,
            count: tempCount,
        },
    ]

    updateCartService(updateCartArray)
    updateCartPrice(productId)
    updateOrderPrice()
}

const updateCartPrice = (productId: string) => {
    const itemPriceEl = document.getElementById(
        `product-price-${productId}`
    ) as HTMLElement

    const itemPrice = parseInt(itemPriceEl.innerText.replaceAll(',', ''))
    const countElList = document.querySelectorAll(
        `[id^="size-count-${productId}-"]`
    )

    let totalCount = 0

    countElList.forEach((countEl) => {
        const count = parseInt((countEl as HTMLInputElement).value, 10)
        if (!isNaN(count)) {
            totalCount += count
        }
    })
    ;(
        document.getElementById(`cart-item-price-${productId}`) as HTMLElement
    ).innerText = `총 ${(totalCount * itemPrice).toLocaleString()} 원`
}

export const deleteCartItem = (productId: string) => {
    const modal = getModalInstance()

    modal?.confirm(
        () => {
            const deleteCartArray: CartDTO[] = []

            const countElList = document.querySelectorAll(
                `[id^="size-count-${productId}-"]`
            )

            countElList.forEach((countEl) => {
                const size = countEl.id.replace(`size-count-${productId}-`, '')
                deleteCartArray.push({
                    productId: productId!,
                    size: size,
                    count: '',
                })
            })

            deleteCartService(deleteCartArray)
            ;(
                document.getElementById(`cart-item-${productId}`) as HTMLElement
            ).remove()
            checkCartItem()

            updateOrderPrice()
        },
        () => {
        },
        '확인',
        '해당 상품을 삭제하시겠습니까?'
    )
}

export const checkCartItem = () => {
    const cartListEl = document.querySelector('.cart-list')

    if (cartListEl?.childNodes.length == 0) {
        ;(
            document.getElementById('empty-cart-list') as HTMLElement
        ).style.display = 'flex'
        ;(cartListEl as HTMLElement).style.display = 'none'
    }
}
