import React from "react";
import  "./Footer.css"
import {Link} from "react-router-dom";
function Footer() {
    const bizCommPop:string = "https://www.ftc.go.kr/bizCommPop.do?wrkr_no=4104800704"
    return (<footer>
        <br/><br/><br/><br/>
        상호 : 두호 &nbsp;&nbsp; 대표 : 브로스포 &nbsp;&nbsp;
        사업자 번호 : 410-48-00704 &nbsp;&nbsp;
        <Link to={bizCommPop} target="_blank">사업자번호 확인</Link>
        <br/><br/>주소 : 서울시 구로구 고척로52마길 7-34(개봉동)
        &nbsp;&nbsp;&nbsp;&nbsp; 대표 전화번호 : 010-2256-4628
        <br/><br/>통신판매업 신고 : 2021-서울구로-2979 &nbsp;&nbsp;&nbsp;&nbsp;
        이메일 : adad2848@hanmail.net <br/><br/><br/>COPYRIGHT © 유니폼 커스텀
        제작 브로스포 Design by Hako

    </footer>);
}

export default Footer;
