import { UserInfo } from '@Interface/User'

import { useEffect, useRef, useState } from 'react'
import { getRequest } from '@Service/Api'
import { HttpAlert, HttpSuccess } from '@Interface/HttpCallBack'
import { getModalInstance } from '@Component/common/Widgets/Modal/ModalProvider'

export const AdminUserInfoService = () => {
    const [userInfo, setUserInfo] = useState<UserInfo>()

    const httpAlert: HttpAlert = {
        401: () => {
            const modal = getModalInstance()
            modal?.alert(
                () => {
                    window.location.href = '/user/login'
                },
                '알람',
                '로그인이 필요합니다.',
                ''
            )
        },
        403: () => {
            const modal = getModalInstance()
            modal?.alert(
                () => {
                    window.location.href = '/'
                },
                '경고',
                '허용되지 않은 접근 입니다.',
                ''
            )
        },
    }
    const httpSuccess: HttpSuccess = {}

    const fetchData = async () => {
        try {
            setUserInfo(
                await getRequest<UserInfo>(
                    '/admin/user',
                    httpSuccess,
                    httpAlert,
                    {}
                )
            )
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        if (
            window.location.href.includes('admin') &&
            !window.location.href.includes('login')
        ) {
            fetchData()
        }
    }, [])

    return (
        userInfo || {
            userName: '',
            email: '',
            phoneNumber: '',
        }
    )
}
