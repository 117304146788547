import { BoardPageBar } from '@Component/common/Widgets/PageBar/PageBar'
import React, { useEffect, useState } from 'react'
import './BoardList.css'

import { InquiryEditor } from '@Component/board/Edit/InquiryEditor'
import { getModalInstance } from '@Component/common/Widgets/Modal/ModalProvider'
import { Board, BoardSearchDTO } from '@Interface/Board'
import { fetchBoardList } from '@Util/BoardUtils'
import { AdminInquiryView, InquiryView } from '../View/InquiryView'
import { formatDate, privacyName } from '@Util/StringUtils'
import { NoticeEditor } from '../Edit/NoticeEditor'
import { NoticeView } from '../View/NoticeView'

export const NoticeList = (props: { isAdmin?: boolean }) => {
    const modal = getModalInstance()
    const [page, setPage] = useState<number>(1)
    const [changePage, setChangePage] = useState<number>(1)
    const [totalPage, setTotalPage] = useState<number>(1)
    const [totalCount, setTotalCount] = useState<number>(0)

    const [boardList, setBoardList] = useState<Board[]>([])

    useEffect(() => {
        var searchQuery: BoardSearchDTO = {
            productId: '',
            category: '0',
            page: page,
            answer: window.location.href.includes('unprocessed') ? 'Y' : 'N',
            isAdmin: props.isAdmin,
            userSearch : 'N'
        }
        fetchBoardList(searchQuery, setBoardList, setTotalPage, setTotalCount)
        if (changePage != page) {
            const reviewElement = document.querySelector('[id$="inquiry"]')
            setChangePage(page)
            if (reviewElement) {
                reviewElement.scrollIntoView({
                    block: 'start',
                    behavior: 'smooth',
                })
            }
        }
    }, [page])

    const viewDetail = (board: Board) => {
        if (board.state == 2 && !props.isAdmin) {
            modal?.alert(() => {}, '알림', '비공개글 입니다.')
            return
        }

        if (props.isAdmin) {
            modal?.popup(<NoticeEditor board={board} />)
        } else {
            modal?.popup(<NoticeView boardId={board.boardId!} />)
        }
    }

    return (
        <div>
            <div>
                <table className={'list-table'}>
                    <thead>
                        <tr>
                            <th className="open-left">번호</th>
                            <th>제목</th>
                            <th className="hide">작성자</th>
                            <th className="open-right ">작성일</th>
                        </tr>
                    </thead>
                    <tbody>
                        {boardList.length !== 0 ? (
                            boardList.map((board, index) => (
                                <tr
                                    key={index}
                                    onClick={() => viewDetail(board)}
                                >
                                    <td className="open-left">
                                        {totalCount - (page - 1) * 10 - index}
                                    </td>
                                    <td
                                        className={'title'}
                                        style={{ textAlign: 'left' }}
                                    >
                                        {board.title}
                                    </td>
                                    <td>
                                        {props.isAdmin
                                            ? board.user.userName
                                            : privacyName(board.user.userName)}
                                    </td>
                                    <td className="open-right hide">
                                        {formatDate(board.createDate)}
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td
                                    className="open-left open-right"
                                    colSpan={4}
                                    height={150}
                                >
                                    공지가 존재하지 않습니다.
                                </td>
                            </tr>
                        )}
                    </tbody>
                    <tfoot>
                        <tr>
                            <th colSpan={4}>
                                {props.isAdmin && (
                                    <input
                                        onClick={() => {
                                            if (
                                                localStorage.getItem(
                                                    'userLogin'
                                                ) == 'Y'
                                            ) {
                                                modal?.popup(<NoticeEditor />)
                                            } else {
                                                modal?.alert(
                                                    () => {
                                                        window.location.href =
                                                            '/user/login'
                                                    },
                                                    '알림',
                                                    '작성을 위해 로그인이 필요합니다.'
                                                )
                                            }
                                        }}
                                        type={'button'}
                                        value={'작성하기'}
                                    />
                                )}
                            </th>
                        </tr>
                    </tfoot>
                </table>
            </div>
            <BoardPageBar
                page={page}
                totalPage={totalPage}
                setPage={setPage}
                setBoardList={setBoardList}
            />
        </div>
    )
}
