import React, { useEffect, useState } from 'react'
import MainImage from '@Component/common/View/MainImage/MainImage'
import { getFileMap, getImageList } from '@Util/imageUtils'

import ImageDrop from '@Component/common/Widgets/Image/ImageDrop'
import './AdminMainImagePage.css'
import { UpdateProductService } from '@Service/admin/product/AdminProductService'
import { MainImageService, updateMainImage } from '@Service/user/cmm/CmmService'
export const AdminMainImagePage = () => {
    const [fileMap, setFileMap] = useState<Map<number, File>>(new Map())
    const [tempFileMap, setTempFileMap] = useState<Map<number, File>>(new Map())

    const fetchFileMap = async () => {
        const imageList = await MainImageService()
        if (imageList) {
            setTempFileMap(await getFileMap(imageList))
        }
    }

    const saveMainImage = async () => {
        const formData = getImageList(fileMap)

        await updateMainImage(formData)
    }
    useEffect(() => {
        fetchFileMap()
    }, [])

    return (
        <div className={'update-main-image'}>
            <h2 className={'page-title'}>
                메인 이미지 수정{' '}
                <input type={'button'} value={'저장'} onClick={saveMainImage} />
            </h2>

            <ImageDrop
                tempFileMap={tempFileMap}
                fileMap={fileMap}
                setFileMap={setFileMap}
            />
            <MainImage fileMap={fileMap} />
        </div>
    )
}
