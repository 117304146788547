import React, { useEffect, useRef, useState } from 'react'
import './ImageDrop.css'
import { convertToJpg, isJpeg } from '@Util/imageUtils'
import { getModalInstance } from '../Modal/ModalProvider'

interface DropAreaProps {
    fileMap: Map<number, File>
    setFileMap: React.Dispatch<React.SetStateAction<Map<number, File>>>
}

const DropArea = (props: {
    tempFileMap: Map<number, File> | null
    fileMap: Map<number, File>
    setFileMap: React.Dispatch<React.SetStateAction<Map<number, File>>>
}) => {
    const modal = getModalInstance()
    const [imageNum, setImageNum] = useState(0)
    const [images, setImages] = useState<{ num: number; file: File }[]>([])
    const galleryRef = useRef<HTMLDivElement>(null)
    const hoverViewRef = useRef<HTMLDivElement>(null)
    const fileMap = props.fileMap
    const setFileMap = props.setFileMap

    const addFiles = async (files: FileList) => {
        const newFileMap = new Map(fileMap)
        const newImages = [...images]
        let newImageNum = imageNum

        for (let i = 0; i < files.length; i++) {
            const file = files[i]
            if (isJpeg(file)) {
                newFileMap.set(newImageNum, file)
            } else {
                newFileMap.set(newImageNum, await convertToJpg(file))
            }

            if (!/^image\//.test(file.type)) {
                modal?.alert(
                    () => {},
                    '알림',
                    '이미지만 업로드 가능합니다.',
                    ''
                )
                continue
            }

            newImages.push({ num: newImageNum, file })
            newImageNum++
        }

        setFileMap(newFileMap)
        setImageNum(newImageNum)
        setImages(newImages)
    }

    const delFile = (num: number) => {
        setFileMap((prevFileMap) => {
            const newFileMap = new Map(prevFileMap)
            newFileMap.delete(num)

            const updatedFileMap = new Map()
            let newNum = 0

            for (const [key, value] of newFileMap) {
                updatedFileMap.set(newNum, value)
                newNum++
            }
            setImageNum(fileMap.size)
            return updatedFileMap
        })

        setImages((prevImages) =>
            prevImages.filter((image) => image.num !== num)
        )
    }

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault()
        addFiles(event.dataTransfer.files)
    }

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault()
    }

    const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault()
    }

    const checkFileMap = () => {
        if (fileMap.size != images.length) {
            const newFileMap = new Map<number, File>()
            images.map((image) => {
                newFileMap.set(
                    image.num,
                    fileMap.get(image.num) || new File([], 'temp.txt')
                )
            })
            setFileMap(newFileMap)
        }
    }

    useEffect(() => {
        checkFileMap()
    }, [images])
    useEffect(() => {
        if (props.tempFileMap) {
            const filesArray: File[] = Array.from(props.tempFileMap.values())
            const dataTransfer = new DataTransfer()
            filesArray.forEach((file) => {
                dataTransfer.items.add(file)
            })
            const filesList = dataTransfer.files

            addFiles(filesList)
        }
    }, [props.tempFileMap])

    return (
        <div
            id="drop-area"
            onDragEnter={handleDragOver}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
        >
            <p>여기 파일을 놔두세요</p>
            <input
                type="file"
                id="fileElem"
                multiple
                accept="image/*"
                style={{ display: 'none' }}
                onChange={(e) => addFiles(e.target.files!)}
            />
            <label className="select-image" htmlFor="fileElem">
                파일 선택하기
            </label>
            <div id="gallery" ref={galleryRef}>
                {images.map(({ num, file }) => (
                    <div key={num} className="gallery-item" data-num={num}>
                        <img
                            src={URL.createObjectURL(file)}
                            alt={`image-${num}`}
                            onLoad={(e) =>
                                URL.revokeObjectURL(
                                    (e.target as HTMLImageElement).src
                                )
                            }
                        />
                        <i
                            className="remove-image icon-trash"
                            onClick={() => delFile(num)}
                        ></i>
                    </div>
                ))}
            </div>
            <div id="hover-view" ref={hoverViewRef}></div>
        </div>
    )
}

export default DropArea
