import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'

/**
 * 엑셀 파일을 생성하여 다운로드합니다.
 * @param {Array<Array<any>>} data - 엑셀에 포함될 데이터.
 * @param {string} sheetName - 시트 이름.
 * @param {string} fileName - 다운로드될 파일의 이름.
 */
export const generateExcel = async (
    data: Array<Array<any>>,
    sheetName: string,
    fileName: string
) => {
    console.log(data)
    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet(sheetName)

    // 데이터 추가
    worksheet.addRows(data)

    // 1행을 헤더로 설정하고 스타일 적용
    worksheet.getRow(1).eachCell({ includeEmpty: true }, (cell) => {
        cell.font = { bold: true }
        cell.alignment = { horizontal: 'center', vertical: 'middle' }
        cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'F0F0F0' }, // 더 밝은 회색
        }
    })

    // 열 너비 자동 조정
    worksheet.columns.forEach((column) => {
        const maxLength =
            column.values?.reduce<number>(
                (max, value) => Math.max(max, String(value).length),
                0
            ) || 0
        column.width = maxLength < 10 ? 10 * 1.3 : (maxLength + 2) * 1.5
    })

    // 행 높이 자동 조정 (기본 높이 20 설정)
    worksheet.eachRow({ includeEmpty: true }, (row) => {
        row.height = 20

        // 모든 셀 가운데 정렬 및 조건부 스타일 적용
        row.eachCell({ includeEmpty: true }, (cell) => {
            // `cell.value`가 문자열인지 확인

            if (typeof cell.value === 'string' && cell.value.includes('h_')) {
                cell.font = { bold: true }
                cell.alignment = { horizontal: 'center', vertical: 'middle' }
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'F0F0F0' }, // 더 밝은 회색
                }
                cell.value = cell.value.replace('h_', '')
            } if (typeof cell.value === 'string' && cell.value.includes('_hr_')) {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '000000' }, // 더 밝은 회색
                }
                cell.value = cell.value.replace('h_', '')
            } else {
                // 기본 스타일 설정
                cell.alignment = { horizontal: 'center', vertical: 'middle' }
            }
        })
    })

    // 엑셀 파일 저장
    const buffer = await workbook.xlsx.writeBuffer()
    const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    saveAs(blob, fileName)
}
