import React, { useEffect, useState } from 'react'
import { Category } from '@Interface/Category'
import {
    useParams,
    useSearchParams,
    useNavigate,
    useLocation,
} from 'react-router-dom'
import { Product } from '@Interface/Product'

import './ProductListPage.css'
import { ProductPageBar } from '@Component/common/Widgets/PageBar/PageBar'
import NotFound from '@Component/common/View/NotFound/NotFound'
import { ProductList } from '@Component/product/ProductList/ProductList'
import { PrimaryCategoryBar } from '@Component/product/CategoryBar/PrimaryCategoryBar'
import { SecondaryCategoryBar } from '@Component/product/CategoryBar/SecondaryCategoryBar'
import { getProductList } from '@Util/ProductUtils'

import { throttle } from 'lodash'
import { ProductSortSelector } from '@Component/product/ProductList/ProductSortSelector'
import { setWishicon } from '@Util/WishUtils'

export const ProductListPage = (props: {
    categoryList: { [p: string]: Category[] }
}): JSX.Element => {
    const location = useLocation()
    const [productList, setProductList] = useState<Product[] | null>(null)
    const [itemCount, setItemCount] = useState<number>(0)
    const { primaryCategory, secondaryCategory } = useParams<{
        primaryCategory: string
        secondaryCategory: string
    }>()
    const [searchParams] = useSearchParams()
    const page = searchParams.get('page')
    const productPage = parseInt(page || '1', 10)
    const [sortMode, setSortMode] = useState('1')
    const categoryList = props.categoryList
    if (!sortMode) {
        setSortMode('1')
    }

    useEffect(() => {
        getProductList(
            searchParams,
            primaryCategory,
            secondaryCategory,
            categoryList,
            setProductList,
            setItemCount,
            false
        )
    }, [
        primaryCategory,
        secondaryCategory,
        searchParams,
        // props.categoryList,
    ])

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search)
        const sortModeParam = searchParams.get('sortMode')
        if (sortModeParam) {
            setSortMode(sortModeParam)
        } else {
            // 다른 카테고리로 이동할 때는 정렬 모드를 1로 초기화
            setSortMode('1')
        }
    }, [location])

    const totalPages = Math.ceil(itemCount / 20)

    return (
        <div className="product-list">
            <div className="product-category">
                <PrimaryCategoryBar
                    categoryList={categoryList}
                    isAdmin={false}
                />
                <SecondaryCategoryBar
                    categoryList={categoryList}
                    isAdmin={false}
                />
            </div>
            <div className="product-list-info">
                <div className="product-item-count">
                    <p>{itemCount || 0}</p>
                    <span>item</span>
                </div>
                <ProductSortSelector />
            </div>

            {productList ? (
                <ProductList productList={productList} isAdmin={false} />
            ) : (
                <NotFound />
            )}

            <ProductPageBar productPage={productPage} totalPage={totalPages} />
        </div>
    )
}
